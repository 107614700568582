<!--
 * @Author: 陈远锋
 * @Date: 2022-03-11 16:31:36
 * @LastEditTime: 2022-04-08 13:12:31
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\coupon_expand.vue
 * @Description: Do not edit
-->
<template>
    <div class="expand-row">
        <Table border stripe :columns="columns2" :data="row">
          <template slot-scope="{ row }" slot="put_company_name">
            {{ row.put_company.company_name }}
          </template>
          <template slot-scope="{ row }" slot="enter_company_name">
            {{ row.enter_company.company_name }}
          </template>
          <template slot-scope="{ row }" slot="title">
            {{ row.coupon_categories.title }}
          </template>
          <template slot-scope="{ row }" slot="alias">
            {{ row.coupon_categories.alias }}
          </template>
        </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
            columns2: [
                { align: "center", title: '时间', key: 'created_at' },
                { title: "调出公司", align: "center", slot: "put_company_name" },
                { title: "调入公司", align: "center", slot: "enter_company_name" },
                { align: "center", title: '名称', slot: 'title' },
                { align: "center", title: '别名', slot: 'alias' },
                { align: "center", title: '数量', key: 'num' },
                { align: "center", title: '状态', key: 'status_desc' },
            ]
        }
    },
	methods: {
	},
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
}
</style>
