var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{style:({ margin: '20px 10px' })},[_c('router-link',{staticStyle:{"float":"right"},attrs:{"to":"/pages/companies_create"}},[_vm._v(" 添加公司 ")]),_c('BreadcrumbItem',[_vm._v("公司管理")]),_c('BreadcrumbItem',[_vm._v("公司列表")])],1),_c('loading',{attrs:{"loading":_vm.loading,"error":_vm.error}}),(_vm.companies)?_c('div',{staticClass:"content"},[_c('Table',{attrs:{"border":"","stripe":"","columns":_vm.columns1,"data":_vm.companies},scopedSlots:_vm._u([{key:"code",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.code),callback:function ($$v) {_vm.$set(_vm.editCompany, "code", $$v)},expression:"editCompany.code"}}):_c('span',[_vm._v(_vm._s(row.code))])]}},{key:"company_name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.company_name),callback:function ($$v) {_vm.$set(_vm.editCompany, "company_name", $$v)},expression:"editCompany.company_name"}}):_c('span',[_vm._v(_vm._s(row.company_name))])]}},{key:"contact",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.contact),callback:function ($$v) {_vm.$set(_vm.editCompany, "contact", $$v)},expression:"editCompany.contact"}}):_c('span',[_vm._v(_vm._s(row.contact))])]}},{key:"phone",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.phone),callback:function ($$v) {_vm.$set(_vm.editCompany, "phone", $$v)},expression:"editCompany.phone"}}):_c('span',[_vm._v(_vm._s(row.phone))])]}},{key:"type",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('RadioGroup',{model:{value:(_vm.editCompany.type),callback:function ($$v) {_vm.$set(_vm.editCompany, "type", $$v)},expression:"editCompany.type"}},[_c('Radio',{attrs:{"label":0}},[_vm._v("代理商")]),_c('Radio',{attrs:{"label":1}},[_vm._v("供应商")])],1):_c('span',[_vm._v(_vm._s(row.type_desc))])]}},{key:"facility",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.userInfo.isSuperAdmin)?_c('div',[(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.facility),callback:function ($$v) {_vm.$set(_vm.editCompany, "facility", $$v)},expression:"editCompany.facility"}}):_c('span',[_vm._v(_vm._s(row.facility))])],1):_c('div',[_c('span',[_vm._v(_vm._s(row.facility))])])]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('div',[_c('Button',{staticStyle:{"margin-right":"6px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSave(row, index)}}},[_vm._v("保存")]),_c('Button',{on:{"click":function($event){_vm.editIndex = -1}}},[_vm._v("取消")])],1):_c('div',[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.handleEdit(row, index)}}},[_vm._v("修改")]),_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"error","size":"small"},on:{"click":function($event){return _vm.showDeleteId(row)}}},[_vm._v("删除")]),_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.getCouponCategories(row)}}},[_vm._v("获取券分类")])],1)]}}],null,false,1529563991)})],1):_vm._e(),_c('Modal',{attrs:{"title":"删除确认"},on:{"on-ok":function($event){return _vm.handleDelete(_vm.row)}},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('p',[_vm._v("您确定要删除这个数据吗？")]),_c('p',[_vm._v("公司: "+_vm._s(_vm.deleteObj.company_name))]),_c('p',[_vm._v("编码: "+_vm._s(_vm.deleteObj.code))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }