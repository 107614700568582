<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-15 12:47:32
 * @FilePath: \qyOut\src\pages\goods_type\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <div style="float:right;">111</div>
            <BreadcrumbItem>商品类型</BreadcrumbItem>
            <BreadcrumbItem>编辑商品类型</BreadcrumbItem>

        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <div v-if="goods_type" class="content">
            <Form ref="goods_type" :model="goods_type" :rules="ruleValidate" label-position="right" :label-width="100">
                <FormItem label="名称" prop="name">
                    <Input v-model="goods_type.name" placeholder="请输入名字"></Input>
                </FormItem>
                <FormItem label="状态" prop="status_id">
                    <Input v-model.number="goods_type.status_id" placeholder="请输入状态ID"></Input>
                </FormItem>
                <FormItem label="类型" prop="type">
                    <Input v-model.number="goods_type.type" placeholder="请输入type类型"></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleSubmit('goods_type')">
                        Submit
                    </Button>
                </FormItem>
            </Form>
        </div>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data() {
        return {
            goods_type: {},
            loading: false,
            error: null,

            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        message: "名字是字符串",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        message: "code必须是字符串",
                        trigger: "blur",
                    },
                ],
                status_id: [
                    {
                        required: true,
                        type: "integer",
                        message: "状态ID不格式是正确",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        type: "integer",
                        message: "code不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },

    methods: {
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.axios
                        .post("goodsTypes/create", this.goods_type)
                        .then((res) => {
                            console.log("修改返回res", res);
                            this.goods_type = res.data.goods_types;
                            this.loading = false;
                            setTimeout(() => {
                                this.$router.push("/pages/goods_type_list");
                            }, 2000);
                        });
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>

