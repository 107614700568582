<!--
 * @Author: 王莹莹
 * @Date: 2022-12-19 08:47:12
 * @LastEditTime: 2022-12-19 17:00:21
 * @FilePath: \qyOut\src\pages\openid\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link :to="{ name : 'openid_create', params: { user_id: user_id } }" style="float:right;">
                新建openid
            </router-link>
            <BreadcrumbItem>用户管理</BreadcrumbItem>
            <BreadcrumbItem>openid列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Table border stripe v-if="openIds" :columns="columns1" :data="openIds">
			<template slot-scope="{ row }" slot="action">
				<Button type="error" style="margin-right: 5px" @click="showDeleteOpenid(row)">删除</Button>
			</template>
        </Table>
		<Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
		<Modal v-model="deleteOpenid" title="删除确认" @on-ok="handleDelete()">
			<p>您确定要删除这个Openid吗？</p>
			<p>帐号: {{ deleteObj.account }}</p>
			<p>手机号: {{ deleteObj.phone }}</p>
			<p>Openid: {{ deleteObj.openid }}</p>
		</Modal>
    </div>
</template>

<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            openIds: [],
            error: null,
			deleteObj: {
				id: 0
			},
			pages: {
				total: 0,
				path: '',
				links: []
			},
            user_id: '',
			deleteOpenid: false,
            columns1: [
                { title: "账号", key: "account", align: "center" },
                { title: "手机号", key: "phone", align: "center" },
                { title: "Openid", key: "openid", align: "center" },
				{ title: "操作", slot: "action", align: "center", },
            ]
        }
    },
    created () {
        console.log("mounted");
		this.user_id = this.$route.params.user_id;
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        fetchData () {
            this.error = null;
            this.loading = true;
            this.axios.get("userOpenId/list/" + this.user_id).then((res) => {
                console.log("res", res);
                this.openIds = res.data.user_open_id;
				this.pages = res.data.pages;
				console.log('pages=>', this.pages);
				this.loading = false;
            });
        },
		pageOnChange: function (page) {
			this.pages.links.forEach(link => {
				if (link.label == page) {
					this.fetchData(link.url);
					return;
				}
			})
			let url = this.pages.path.indexOf('?') > 0
				? this.pages.path + "&page=" + page
				: this.pages.path + "?page=" + page;
			console.log('url=>', url);
			this.fetchData(url);
		},
		/**
		 * 显示删除 openid
		 * @param {*} data
		 */
		showDeleteOpenid (data) {
			console.log('将要删除的对象=>', data);
			this.deleteOpenid = true;
			this.deleteObj = data;
			console.log('将要删除的对象=>', this.deleteObj);
		},
		/**
		 * 删除操作
		 */
		handleDelete () {
			this.loading = true;
			this.axios.get('userOpenId/del/' + this.deleteObj.id).then((res) => {
				console.log("删除返回res", res);
				this.loading = false;
				if (res.data.status === 200 ) {
					this.$Message.success("删除成功");
					let index = -1;//这里不能设置0，因为数组索引是从0开始的
					for (let i = 0; i < this.openIds.length; i++) {
						if (this.openIds[i].id === this.deleteObj.id) {
							index = i;
							break
						}
					}
					if (index >= 0) {
						this.openIds.splice(index, 1);
					}
				} else {
					this.$Message.error(res.data.msg);
				}
			});
		},
    },
}
</script>

<style scoped>
</style>
