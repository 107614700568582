<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-05-07 14:12:59
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\supplierGoods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <Button style="float:right;" @click="createSwitch=true">
                添加供应商商品
            </Button>
            <BreadcrumbItem>供应商商品管理</BreadcrumbItem>
            <BreadcrumbItem>供应商商品列表</BreadcrumbItem>
        </Breadcrumb>
        <Form ref="createObject" :model="createObject" :rules="ruleValidate" label-position="right" :label-width="200" v-if="createSwitch">
            <FormItem label="供应商" prop="supplier_id">
                <RadioGroup v-model="createObject.supplier_id">
                    <Radio v-for="(supplier, index) of suppliers" :label="supplier.id" :key="index" style="width: 120px;">
                        {{supplier.supplier}}</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="光凝编码" prop="goods_no">
                <Input v-model="createObject.goods_no" placeholder="请输入编码"></Input>
            </FormItem>
            <FormItem label="供应商编码" prop="supplier_goods_no">
                <Input v-model="createObject.supplier_goods_no" placeholder="请输入编码"></Input>
            </FormItem>
            <FormItem label="名称" prop="supplier_goods_name">
                <Input v-model="createObject.supplier_goods_name" placeholder="请输入名称"></Input>
            </FormItem>
            <FormItem label="原价" prop="original_price">
                <Input v-model="createObject.original_price" placeholder="请输入价格"></Input>
            </FormItem>
            <FormItem label="价格" prop="channel_price">
                <Input v-model="createObject.channel_price" placeholder="请输入价格"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="formHandleSubmit('createObject')">提交</Button>
                <Button style="margin-left: 8px" @click="createSwitch=false">取消</Button>
            </FormItem>
        </Form>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="uid">
                <Select v-model="selectForm.supplier_id" style="margin-right: 6px; width: 140px">
                    <Option v-for="user in suppliers" :value="user.id" :key="user.id">{{ user.supplier }}</Option>
                </Select>
            </FormItem>
            <FormItem prop="光凝编码">
                <Input v-model.number="selectForm.goods_no" placeholder="光凝产品编码"></Input>
            </FormItem>
            <FormItem prop="产品名称">
                <Input v-model.number="selectForm.supplier_goods_name" placeholder="产品名称"></Input>
            </FormItem>
            <FormItem prop="供应商产品编码">
                <Input v-model.number="selectForm.supplier_goods_no" placeholder="供应商产品编码"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="handleSubmit">查询</Button>
            </FormItem>
        </Form>
        <Table v-if="supplierGoods" border stripe :columns="columns1" :data="supplierGoods">
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
                <Button type="error" size="small" style="margin-right: 5px" @click="showDeleteModal(row)">删除</Button>
            </template>
        </Table>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
        <Modal v-model="deleteModal" title="删除确认" @on-ok="handleDelete()">
            <p>您确定要删除这个来源吗？</p>
            <p>来源: {{ deleteObj.supplier_goods_name }}</p>
            <p>代码: {{ deleteObj.supplier_goods_no }}</p>
        </Modal>
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            pages: {},
            deleteObj: {},
            deleteModal: false,
            createSwitch: false,
            createObject: {},
            selectForm: {},
            loading: false,
            supplierGoods: [],
            error: null,
            suppliers: [],
            columns1: [
                { title: "时间", key: "updated_at", align: "center", width: 200 },
                /* { align: "center", title: "ID", key: "id", width: 100 }, */
                { align: "center", title: "光凝编码", key: "goods_no", width: 100 },
                { align: "center", title: "级别", key: "level" },
                {
                    align: "center",
                    title: "供应商",
                    key: "supplier_name",
                },
                {
                    align: "center",
                    title: "供应商编码",
                    key: "supplier_goods_no",
                },
                {
                    align: "center", title: "产品名称", key: "supplier_goods_name",
                },
                {
                    align: "center",
                    title: "状态",
                    key: "status_desc",
                },
                {
                    align: "center",
                    title: "原价",
                    key: "original_price",
                },
                { align: "center", title: "折扣价", key: "channel_price", },
                { title: "操作", slot: "action", width: 150, align: "center" },
            ],
            ruleValidate: {
                supplier_id: [
                    { required: true, message: "请选择供应商", },
                ],
                goods_no: [
                    { required: true, message: "请填写光凝编码" },
                ],
                supplier_goods_no: [
                    { required: true, message: "请填写供应商编码" },
                ],
                supplier_goods_name: [
                    { required: true, message: "请填写产品名字" },
                ],
                original_price: [
                    { required: true, message: "请填写产品原价" },
                ],
                channel_price: [
                    { required: true, message: "请填写产品售价" },
                ],
            },
        };
    },
    created () {
        this.axios.get('supplier/list').then(res => {
            this.suppliers = res.data.suppliers;

        })
        console.log("mounted");
        this.fetchData();

    },
    methods: {
        pageOnChange: function (page) {
            console.log('page=>', page);
            //let url = 'couponsOrders/list?page=' + page;
            this.pages.links.forEach(link => {
                if (link.label == page) {
                    this.fetchData(link.url, this.selectForm);
                    return;
                }
            });

        },
        showDeleteModal (data) {
            console.log('将要删除的对象=>', data);
            this.deleteModal = true;
            this.deleteObj = data;
        },
        handleDelete () {
            this.axios.get('supplierGoods/del/' + this.deleteObj.id).then((res) => {
                console.log("删除返回res", res);
                this.loading = false;
                if (res.data.status === 204) {
                    this.$Message.success("删除成功");
                    console.log('this.supplierGoods=>', this.supplierGoods);
                    let index = -1;//这里不能设置0，因为数组索引是从0开始的
                    for (let i = 0; i < this.supplierGoods.length; i++) {
                        if (this.supplierGoods[i].id == this.deleteObj.id) {
                            index = i;
                            break
                        }
                    }
                    if (index >= 0) {
                        this.supplierGoods.splice(index, 1);
                    }
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.axios.post("supplierGoods/create", this.createObject).then(res => {
                        if (res.data.status === 200) {
                            this.$Message.success("创建成功");
                            setTimeout(() => { this.$router.go(0) }, 2000);
                        } else {
                            this.$Message.error(res.data.msg);
                        }
                    })
                } else {
                    this.$Message.error("请填写正确的数据!");
                }
            });
        },
        handleSubmit () {
            this.fetchData("supplierGoods/list", this.selectForm);
        },
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/supplier_goods_edit/" + row.id);
        },
        fetchData (url = "supplierGoods/list", params) {
            this.error = this.post = null;
            this.loading = true;

            let data = {};
            for (let key in params) {
                console.log("param[key]", key, params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }

            data.params = params;
            this.axios.get(url, data).then((res) => {
                console.log("读取回来的数据", res.data);
                this.pages = res.data.pages;
                /* res.data.supplier_goods.forEach(goods => {
                    goods.supplier_name = goods.supplier.supplier;
                }); */
                this.supplierGoods = res.data.supplier_goods;
                this.loading = false;
            });
        },
    },
};
</script>
