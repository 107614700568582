<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-03-09 11:35:50
 * @FilePath: \qyOut\src\pages\supplier\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="supplier" :model="supplier" :rules="ruleValidate" label-position="right" :label-width="200" v-if="supplier">
        <FormItem label="名字" prop="supplier">
            <Input v-model="supplier.supplier" placeholder="请输入商品编号"></Input>
        </FormItem>
        <FormItem label="编码" prop="code">
            <Input v-model="supplier.code" placeholder="请输入编码"></Input>
        </FormItem>
        <FormItem label="手机号" prop="phone">
            <Input v-model="supplier.phone" placeholder="请输入手机号"></Input>
        </FormItem>
        <FormItem label="联系人" prop="name">
            <Input v-model="supplier.name" placeholder="name"></Input>
        </FormItem>
        <FormItem label="level" prop="level">
            <Input v-model.number="supplier.level" placeholder="level"></Input>
        </FormItem>
        <FormItem label="状态ID" prop="status_id">
            <RadioGroup v-model="supplier.status_id">
                <Radio :label="0">关闭</Radio>
                <Radio :label="1">正常</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="formHandleSubmit('supplier')">提交</Button>
            <Button style="margin-left: 8px">取消</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        supplier: Object,
    },
    data() {
        return {
            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: "name不能为空",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                ],
                supplier: [
                    {
                        required: true,
                        message: "supplier不能为空",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "phone不能为空",
                        trigger: "blur",
                    },
                ],
                level: [
                    {
                        required: true,
                        type: "integer",
                        message: "level不能为空",
                        trigger: "blur",
                    },
                ],
                status_id: [
                    {
                        required: true,
                        type: "integer",
                        message: "status_id 不能为空",
                    },
                ],
                city: [
                    {
                        required: true,
                        message: "Please select the city",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.supplier);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
