<!--
 * @Author: 王莹莹
 * @Date: 2022-12-19 08:47:12
 * @LastEditTime: 2022-12-19 16:59:39
 * @FilePath: \qyOut\src\pages\openid\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>用户管理</BreadcrumbItem>
            <BreadcrumbItem>添加openid</BreadcrumbItem>
        </Breadcrumb>
		<loading :loading="loading" :error="error"></loading>
		<Form ref="openid" :model="openid" :rules="ruleValidate" label-position="right" :label-width="200" v-if="openid">
			<FormItem label="账号" prop="account">
				<Input v-model="user.account" placeholder="请输入账号" disabled></Input>
			</FormItem>
			<FormItem label="手机号码" prop="phone">
				<Input v-model="user.phone" placeholder="Enter something..." disabled></Input>
			</FormItem>
			<FormItem label="openid" prop="openid" v-if="isSuperAdmin">
				<Input v-model="openid.openid" placeholder="请输入openid"></Input>
			</FormItem>
			<FormItem>
				<Button type="primary" @click="handleSubmit('openid')">提交</Button>
				<Button style="margin-left: 8px">取消</Button>
			</FormItem>
		</Form>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data () {
		return {
			isSuperAdmin: Boolean,	// 登陆的用户信息
			openid: {},
			user: {},
			loading: false,
			error: null,
			ruleValidate: {
				openid: [
					{ required: true, message: "openid不能为空", trigger: "blur" },
				],
			},
		};
    },
	created () {
        this.user_id = this.$route.params.user_id;
        axios.get("users/" + this.user_id).then(res => {
			this.user = res.data.user
		})
		this.user.roles.forEach(role => {
			if (role.name === '超级管理员') {
                this.isSuperAdmin = true;
                console.log("this.isSuperAdmin=>", this.isSuperAdmin);
            }
        })
    },
    methods: {
		handleSubmit: function (name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					// 验证通过
					console.log("验证通过");
					this.loading = true;
					this.error = null;
					this.openid.user_id = this.user.id;
					this.axios
						.post("userOpenId/create", this.openid)
						.then((res) => {
							console.log("新增返回res", res);
							this.openid = res.data.openid;
							this.loading = false;
							this.$Message.success("添加成功");
							setTimeout(() => {
								this.$router.push("/pages/openid/"+this.user.id);
							}, 2000);
						});
				} else {
					this.$Message.error("请输入正确的数据!");
				}
			});
        }
    },
}
</script>

<style scoped>
</style>
