/*
 * @Author: 陈远锋
 * @Date: 2022-03-04 10:20:16
 * @LastEditTime: 2022-04-02 10:11:15
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\store\index.js
 * @Description: Do not edit
 */


import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";


//import { LOGIN_STATUS, UID } from '@/config/cache';


const state = {
	token: localStorage.getItem("token") || '',
	userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
	uid: localStorage.getItem("uid") || 0,
	isLogin: localStorage.getItem("token") || false,
	balance: localStorage.getItem("balance") || 0,
	version: localStorage.getItem("version") || '1.0.1',
	companyInfo: JSON.parse(localStorage.getItem("companyInfo")) || {},
};

const mutations = {
	SETPHONESTATUS (state, val) {
		state.phoneStatus = val;
	},
	LOGIN (state, token) {
		localStorage.setItem('token', token);
		state.token = token;
	},
	SETUID (state, val) {
		localStorage.setItem('uid', val);
		state.uid = val;
	},
	UPDATE_LOGIN (state, token) {
		localStorage.setItem('token', JSON.stringify(token));
		state.token = token;
	},
	LOGOUT (state) {
		state.token = undefined;
		state.uid = undefined;
		localStorage.clear();
	},
	UPDATE_USERINFO (state, userInfo) {
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
		state.userInfo = userInfo;
	},
	UPDATE_BALANCE (state, balance) {
		localStorage.setItem('balance', balance);
		state.balance = balance;
	},
	UPDATE_VERSION (state, version) {
		localStorage.setItem('version', version);
		state.version = version;
	},
	UPDATE_COMPANY_INFO (state, companyInfo) {
		localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
		state.companyInfo = companyInfo;
	},
};

const actions = {
	/* USERINFO({
		state,
		commit
	}, force) {
		if (state.userInfo !== null && !force)
			return Promise.resolve(state.userInfo);
		else
			return new Promise(reslove => {
				getUserInfo().then(res => {
					commit("UPDATE_USERINFO", res.data);
					Cache.set(USER_INFO, res.data);
					reslove(res.data);
				});
			})
	} */
};



export default new Vuex.Store({
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters,
	strict: debug
});
