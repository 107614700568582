<!--
 * @Author: 陈远锋
 * @Date: 2022-02-14 17:00:12
 * @LastEditTime: 2022-02-15 15:46:25
 * @FilePath: \qyOut\src\App.vue
 * @Description: Do not edit
-->

<template>
  <div>
    <Breadcrumb
      separator="<b>></b>"
      class="layout-nav3"
      style="margin-left: 12px"
    >
      <BreadcrumbItem to="/components/breadcrumb"
        >分销商/订单管理</BreadcrumbItem
      >
      <BreadcrumbItem>余额记录</BreadcrumbItem>
    </Breadcrumb>
    <Content :style="{ minHeight: '200px', background: '#fff' }">
      <div
        class="left"
        :style="{
          background: '#f5f7f9 ',
          margin: '12px',
          padding: '14px',
        }"
      >
        <span>账户余额: ￥2199.9600</span>
      </div>
      <div
        :style="{
          background: '#f5f7f9 ',
          margin: '12px',
          padding: '14px',
        }"
      >
        <Row :gutter="16">
          <Col span="6">
            <DatePicker
              type="daterange"
              placement="bottom-start"
              placeholder="开始日期至结束日期"
              style="width: 100%"
            ></DatePicker>
          </Col>
          <Col span="6">
            <div class="box">
              <Dropdown trigger="click">
                <div href="javascript:void(0)">
                  类型
                  <Icon type="ios-arrow-down"></Icon>
                </div>
                <DropdownMenu slot="list">
                  <DropdownItem>QQ音乐</DropdownItem>
                  <DropdownItem>QQ会员</DropdownItem> 
                  <DropdownItem>网易云</DropdownItem>
                  <DropdownItem>酷狗</DropdownItem>
                  <DropdownItem>酷我</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
          <Col span="6">
           <Input v-model="value" placeholder="金额开始" style="width: 45%" />
           -
           <Input v-model="value" placeholder="金额结束" style="width: 45%" />
          </Col>
        </Row>
        <Row>
          <div style="margin-top: 10px">
            <Button type="primary">
              <Icon type="ios-search" />
              查询</Button
            >
          </div>
        </Row>
      </div>
      <div style="margin: 0 12px">
        <Table border :columns="columns1" :data="data1"></Table>
      </div>
      <div
        :style="{
          background: '#f5f7f9 ',
          margin: '12px',
          padding: '14px',
          height: '50px',
        }"
      >
        <Page
          style="float: right"
          :total="1"
          size="small"
          show-total
          show-sizer
        />
      </div>
    </Content>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      value: "",
      value2: "",
      value1: "",

      columns1: [
        {
          title: "类型",
          key: "type",
        },
        {
          title: "金额",
          key: "amount2",
        },
        {
          title: "余额快拍",
          key: "stories",
        },
        {
          title: "发生时间",
          key: "time3",
        },
        {
          title: "备注",
          key: "remark2",
        },
      ],
      data1: [],
    };
  },
};
</script>
<style scoped>
.layout-nav {
  width: 420px;
  margin: 0 auto;
  float: right;
  color: #f5f7f9;
}
.layout-nav2 {
  margin-left: 200px;
}
.layout-nav3 {
  text-align: left;
  margin: 8px 0;
}
.box {
  background: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  border: 1px solid #dbd9d9;
}
.left {
  text-align: left;
  color: rgb(0, 119, 255);
}
</style>