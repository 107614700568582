<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-27 14:41:18
 * @FilePath: \qyOut\src\pages\agentGoods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>代理商商品管理</BreadcrumbItem>
            <BreadcrumbItem>代理商商品列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Select v-model="selectCompanyId" @on-change="userOnChange" style="margin: 10px; width: 250px;">
            <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.company_name }}</Option>
        </Select>
        <Table border stripe ref="selection" :columns="columns1" :data="baseGoods" v-if="selectCompanyId" @on-selection-change="onSelectionChange">
        </Table>
        <Row>
            <Button style="margin: 10px" @click="handleSelectAll(true)">全选</Button>
            <Button ref="buttonSubmitSave" style="margin: 10px" type="primary" :disabled="dis" @click="handleSubmitSave">提交</Button>
        </Row>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            editIndex: -1,
            editAgentSalePrice: "",
            editAgentGoodsId: -1,
            loading: false,
            agentGoods: [],
            error: null,
            companies: [],
            baseGoods: [],
            selectCompanyId: 0,
            agentGoodsSelectedIds: [],
            dis: false,
            columns1: [
                { type: "selection", minWidth: 60, align: "center" },
                /* { title: "ID", key: "id", align: "center", width: 100 }, */
                {
                    title: "分类ID",
                    key: "category_id",
                    align: "center",
                    minWidth: 100,
                },
                { title: "名称", key: "title", align: "center", minWidth: 80 },
                { title: "别名", key: "alias", align: "center", minWidth: 80 },
                {
                    title: "面额",
                    key: "face_value",
                    align: "center",
                    minWidth: 80
                },
                {
                  title: "类型",
                  key: "category_desc",
                  align: "center",
                  minWidth: 80
                },
                {
                    title: "使用类型",
                    key: "biz_type_desc",
                    align: "center",
                    minWidth: 80
                },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        onSelectionChange (selectedELement) {
            console.log("selected=>", selectedELement);
            this.agentGoodsSelectedIds.length = 0;
            selectedELement.forEach((element) => {
                this.agentGoodsSelectedIds.push(element.category_id);
            });
        },
        handleSelectAll (status) {
            this.$refs.selection.selectAll(status);
        },
        handleSubmitSave () {
            this.dis = true;
            setTimeout(() => { this.dis = false; }, 5000);
            if (this.selectCompanyId == 0) {
                this.$Message.error("出错了，您没有选定代理商用户");
                return;
            }
            if (this.agentGoodsSelectedIds.length == 0) {
                this.$Message.error(
                    "出错了，您没有改变过选择，或者没有选择商品"
                );
                return;
            }
            this.baseGoods.forEach((element) => {
                console.log("element=>", element);
            });
            let data = {
                company_id: this.selectCompanyId,
                category_ids: this.agentGoodsSelectedIds,
            };
            this.axios
                .post("agentGoods/batchUpdate", data)
                .then((res) => {
                    if (res.data.status == 200 || res.data.status == 204) {
                        this.$Message.success("修改成功");
                        // setTimeout(() => {
                        //     this.$router.go(0);
                        // }, 2000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.error = error.message;
                });
        },
        userOnChange: function (company_id) {
            this.error = null;
            this.loading = true;
            console.log("company_id=>", company_id);
            // 处理基础商品表
            this.baseGoods.forEach((goods_) => {
                goods_._checked = false;
            });
            this.axios.get("agentGoods/list?company_id=" + company_id).then((res) => {
                console.log("agentGoods 读取回来的数据=>", res.data);
                this.agentGoods = res.data.agent_goods;
                let ids = [];
                this.agentGoods.forEach((goods_) => {
                    ids.push(goods_.category_id);
                });
                console.log("ids=>", ids);
                this.baseGoods.forEach((baseGoods_) => {
                    console.log("id=>", baseGoods_.category_id);
                    if (ids.includes(String(baseGoods_.category_id))) {
                        baseGoods_._checked = true;
                        console.log('id存在')
                    }
                });
                this.loading = false;
            });
        },
        fetchData () {
            this.error = null;
            this.loading = true;
            this.axios.get("company?type=0").then((res) => {
                console.log("agentUsers 读取回来的数据=>", res.data);
                this.companies = res.data.companies;
                this.companies.push({
                    id: 0,
                    name: "请选择代理商",
                });
                this.loading = false;
            }).catch((error) => {
                console.log("error=>", error);
                console.log("error=>", error.response);
                console.log("error=>", error.message);
                this.error = error;
                this.loading = false;
            });
            this.axios.get("couponCategories/totalList").then((res) => {
                res.data.coupon_categories.forEach((element) => {
                    element._checked = false;
                });
                this.baseGoods = res.data.coupon_categories;
            });
        },
    },
};
</script>
