<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:41:52
 * @LastEditTime: 2022-11-28 10:31:12
 * @FilePath: \qyOut\src\pages\coupon_orders\list.vue
 * @Description: Do not edit
-->
<template>
  <div>
    <Breadcrumb :style="{ margin: '20px 10px' }">
      <BreadcrumbItem>券码管理</BreadcrumbItem>
      <BreadcrumbItem>券码订单列表</BreadcrumbItem>
    </Breadcrumb>
    <loading :loading="loading" :error="error"></loading>
    <Form ref="selectForm" :model="selectForm" inline>
      <FormItem prop="date">
        <Select v-model="selectForm.company_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
          <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.short_name }}</Option>
        </Select>
      </FormItem>
      <FormItem prop="sh_order_no">
        <Input v-model="selectForm.sh_order_no" placeholder="三方订单号"></Input>
      </FormItem>
      <FormItem prop="code">
        <Input v-model="selectForm.code" placeholder="券码"></Input>
      </FormItem>
      <FormItem prop="doudian_open_id">
        <Input v-model="selectForm.doudian_open_id" placeholder="抖店OPENID"></Input>
      </FormItem>
      <FormItem prop="date">
        <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
      </FormItem>
      <FormItem prop="status_id">
        <RadioGroup @on-change="statusIdChanged">
          <Radio label="0">
            <span>失败</span>
          </Radio>
          <Radio label="1">
            <span>未发码</span>
          </Radio>
          <Radio label="2">
            <span>已发码</span>
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
        <Button type="primary" @click="goExportII()" style="margin-left: 5px;">导出</Button>
      </FormItem>
    </Form>
    <Table border stripe :columns="columns1" :data="orders">
      <template slot-scope="{ row }" slot="company_name">
        {{ row.company.company_name }}
      </template>
		<template slot-scope="{ row }" slot="goods_name">
			{{ row.agent_goods_detail.goods.goods_name }}
		</template>
      <template slot-scope="{ row, index }" slot="action">
        <Button v-if="row.status_desc == '已核销'" type="error" size="small" style="margin: 5px" @click="updateShippedAlert(row,index)">改为已发货</Button>
        <Button v-if="(row.status_desc != '已关闭' && row.status_desc != '已退款')" type="error" size="small" style="margin: 5px" @click="showManualDeliveryModel(row, true)">手工发码</Button>
      </template>
    </Table>
    <expandRow style="display: none"></expandRow>
    <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    <Modal v-model="deliveryModelSwitch" title="请确认您的操作！" @on-ok="manualDelivery" @on-cancel="deliveryModelCancel">
      <p>您确定要手工发码吗？</p>
    </Modal>
    <Modal v-model="modelSwitch" title="请确认您的操作！" @on-ok="updateShipped" @on-cancel="modelCancel">
      <p>您确定要这样做吗？</p>
    </Modal>
  </div>
</template>
<script>
import expandRow from '../member-1/coupon_expand.vue';
import loading from '../loading.vue';
export default {
  components: { expandRow, loading },
  data () {
    return {
      deliveryModelSwitch: false,	// 手工发码 model 开关
      modelSwitch: false,
      loading: false,
      error: null,
      orders: [],
      pages: {},
      selectForm: {
      },
      companies: [],
      ruleInline: {},
      selectedOrder: {
        id: 0
      },
      columns1: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            let order = params.row.coupons_code;
            return h(expandRow, {
              props: {
                row: order
              }
            })
          }
        },
        { align: "center", title: '时间', key: 'created_at', minWidth: 100 },
        // { align: "center", title: '代理公司', slot: 'company_name', className:this.menuStatus===false?'colShow':'colHiddle', width: 100 },
		{ align: "center", title: '代理公司', slot: 'company_name',  minWidth: 100 },
		{ align: "center", title: '产品名字', slot: "goods_name", minWidth: 100 },
        { align: "center", title: '订单号', key: 'order_no',  minWidth: 100 },
        { align: "center", title: '三方订单号', key: 'sh_order_no', minWidth: 100 },
        { align: "center", title: '手机号码', key: 'phone', minWidth: 100 },
        { align: "center", title: '数量', key: 'num', width: 100 },
        //{ align: "center", title: '单价', key: 'unit_price', width: 100 },
        { align: "center", title: '金额', key: 'sh_order_amount', width: 100 },
        { align: "center", title: '状态', key: 'status_desc', width: 100 },
        { align: "center", title: '操作', slot: 'action', width: 150 }
      ],
      showAfterRenderClass: false
    }
  },
  created () {
    console.log('this.$route.query=>', this.$route.query);
    if ('doudian_open_id' in this.$route.query) {
      this.selectForm.doudian_open_id = this.$route.query.doudian_open_id;
    }
    this.fetchData('couponsOrders/list', this.$route.query);
    this.axios.get('companies').then(res => {
      this.companies = res.data.companies
    })
  },
  methods: {
    showManualDeliveryModel: function (order, bool) {
      this.deliveryModelSwitch = bool;
      this.selectedOrder = order;
    },
    /**
     * 取消模态型显示
     */
    deliveryModelCancel: function () {
      this.deliveryModelSwitch = false;
    },
    /**
     * 手工发码
     * @param {*} order
     */
    manualDelivery: function () {
      console.log('准备给订单手工发码，order=>',);
      this.axios.get('couponsOrders/sendOrder/' + this.selectedOrder.id).then(res => {
        console.log('couponsOrders/sendOrder/ res=>', res);
        if (res.data.status == 200) {
          this.$Message.success('手工发码成功');
        } else {
          this.$Message.error('手工发码出错了');
        }
      })
    },
    goExportII: function () {
      let getData = {};
      for (let key in this.selectForm) {
        console.log('param[key],', this.selectForm[key]);
        if (this.selectForm[key] === undefined || this.selectForm[key].length == 0) {
          delete this.selectForm[key];
        }
      }
      let date = new Date();
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset()); // toJSON 的时区补偿
      getData.params = this.selectForm;
      getData.responseType = 'blob';
      this.axios.get('couponsOrders/exportCardOrders', getData).then(res => {
        console.log('couponsOrders/exportCardOrders=>', res);
        //let blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
        let blob = new Blob([res.data], { type: 'application/octet-stream' }); // 为blob设置文件类型，这里以.xlsx为例
        let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
        let a = document.createElement("a");
        a.href = url;
        a.download = '券码下载' + date.toJSON().substr(0, 19).replace(/[-T]/g, '') + '.csv';
        a.click();
        // 释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      });
    },

    modelCancel: function () {
      console.log('用户取消了模态框');
    },
    updateShippedAlert (order, index) {
      this.modelSwitch = true;
      console.log('order=>', order, 'index=>', index);
      this.selectedOrder = order;
    },
    updateShipped: function () {
      this.axios.get('couponsOrders/changeStatus/' + this.selectedOrder.id).then(res => {
        console.log("res =>", res);
        if (res.data.status == 200) {
          this.$Message.success('操作成功了');
        } else {
          this.$Message.error('出错了');
        }
      })
    },
    statusIdChanged (val) {
      console.log('statusIdChanged val=>', val);
      this.selectForm.status_id = val;
    },
    dateOnChange (date) {
      console.log(date);
      this.selectForm.start_date = date[0];
      this.selectForm.end_date = date[1];
    },
    handleSubmit: function (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.fetchData('couponsOrders/list', this.selectForm);
        } else {
          this.$Message.error('出错了');
        }
      })
    },
    pageOnChange: function (page) {
      console.log('page=>', page);
      let url = 'couponsOrders/list?page=' + page;
      this.fetchData(url, this.selectForm);
    },
    fetchData: function (url, param) {
      let data = {};
      for (let key in param) {
        console.log('param[key],', param[key]);
        if (param[key] === undefined || param[key].length == 0) {
          delete param[key];
        }
      }
      data.params = param;
      this.loading = true;
      this.axios.get(url, data).then(res => {
        console.log('couponsOrders/list res', res);
        this.orders = res.data.coupons_order;
        this.orders.forEach(element => {
          element._expanded = false;
        });
        this.pages = res.data.pages;
        this.loading = false;
      })
    }
  }
}
</script>
<style>
/*.colHiddle {*/
/*	display: none;*/
/*}*/
/*.colShow{*/
/*	display: revert;*/
/*}*/
</style>
