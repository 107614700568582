<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-09 10:09:04
 * @FilePath: \qyOut\src\pages\goods\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>基础商品</BreadcrumbItem>
            <BreadcrumbItem>编辑基础商品</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :goods="goods" :goods_types="goods_types" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            is11: '0',
            goods: {},
            goods_types: [],
            loading: false,
            error: null,
        }
    },
    created () {
        console.log("created")
        this.fetchData()
        console.log(this.$route.params)
    },
    watch: {
        $route (to, from) {
            // 对路由变化作出响应...
            console.log("to", to)
            console.log("from", from)
        }
    },
    methods: {
        handleSubmit (goods) {
            // 验证通过
            console.log("验证通过", goods);
            this.loading = true; this.error = null
            this.axios
                .post("goods/edit/" + goods.id, goods)
                .then((res) => {
                    console.log("修改返回res", res)
                    this.loading = false;
                    if (res.data.status === 200) {
                        this.$Message.success('修改成功');
                        setTimeout(() => {
                            this.$router.push("/pages/goods_list");
                        }, 2000);
                    } else {
                        for (let key in res.data.errors) {
                            this.$Message.error(res.data.errors[key][0]);
                        }
                    }
                })
        },
        show (row, index) {
            console.log("row=>", row.id)
            console.log("index=>", index)
            this.$router.push("/pages/goods_type_edit/" + row.id)
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios
                .get("goods/detail/" + this.$route.params.id)
                .then((res) => {
                    console.log("res", res);
                    this.goods = res.data.goods;
                    this.goods.is_sale = String(this.goods.is_sale);
                    this.goods.status_id = String(this.goods.status_id);
                    this.goods.goods_type_id = String(this.goods.goods_type_id);
                    this.goods.goods_id = res.data.goods.id;
                    delete this.goods.created_at;
                    delete this.goods.updated_at;
                    this.loading = false;
                });
            this.axios.get('goodsTypes/list').then(res => {
                this.goods_types = res.data.goods_types;
            })
        },
    },
};
</script>
