<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-04-08 13:50:57
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\roles\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>角色管理</BreadcrumbItem>
            <BreadcrumbItem>添加角色</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :role="role" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            role: {},
            loading: false,
            error: '',
        }
    },
    created () {
        console.log("created")
        this.fetchData()
        console.log(this.$route.params)
    },
    methods: {
        handleSubmit (role) {
            // 验证通过
            console.log("handleSubmit 开始");
            this.loading = true; this.error = null;
            this.axios.post("roles", role).then((res) => {
                console.log("修改返回res", res)
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success('添加成功');
                    setTimeout(() => {
                        this.$router.push("/pages/roles");
                    }, 2000);
                }
            })
        }
    },
};
</script>
