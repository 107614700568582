<!--
 * @Author: 陈远锋
 * @Date: 2022-04-21 09:12:02
 * @LastEditTime: 2022-05-07 13:55:43
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\origin\index.vue
 * @Description: Do not edit
-->
<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-04-02 10:12:09
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\goods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <Button style="float:right;" @click="createSwitch=true">
                新增来源网站
            </Button>
            <BreadcrumbItem>来源网站管理</BreadcrumbItem>
            <BreadcrumbItem>来源列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <Form ref="createObject" :model="createObject" :rules="ruleValidate" label-position="right" :label-width="200" v-if="createSwitch">
            <FormItem label="来源名字" prop="origin_name">
                <Input v-model="createObject.origin_name" placeholder="请输入来源名字"></Input>
            </FormItem>
            <FormItem label="来源代码" prop="origin_code">
                <Input v-model="createObject.origin_code" placeholder="请输入来源代码"></Input>
            </FormItem>

            <FormItem label="状态" prop="status_id">
                <RadioGroup v-model="createObject.status_id">
                    <Radio :label="0">关闭</Radio>
                    <Radio :label="1">正常</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="formHandleSubmit('createObject')">提交</Button>
                <Button style="margin-left: 8px" @click="createSwitch=false">取消</Button>
            </FormItem>
        </Form>

        <Table border stripe :columns="columns1" :data="origins">
            <template slot-scope="{ row, index }" slot="origin_name">
                <Input v-if="editIndex === index" type="text" v-model="editObject.origin_name" />
                <span v-else>{{ row.origin_name }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="origin_code">
                <Input v-if="editIndex === index" type="text" v-model="editObject.origin_code" />
                <span v-else>{{ row.origin_code }}</span>
            </template>
            <template slot-scope="{ row }" slot="status_desc">
                <i-switch :value="row.statusBool" size="large" @on-change="statusOnChange(row)">
                    <span slot="open">开启</span>
                    <span slot="close">关闭</span>
                </i-switch>
            </template>

            <template slot-scope="{ row, index }" slot="action">
                <div v-if="editIndex === index">
                    <Button @click="handleSave(row, index)" type="primary" style="margin-right: 6px">保存</Button>
                    <Button @click="editIndex = -1">取消</Button>
                </div>
                <div v-else>
                    <Button type="primary" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                    <Button type="error" style="margin-right: 5px" @click="showDeleteModal(row)">删除</Button>
                </div>
            </template>
        </Table>
        <Modal v-model="deleteModal" title="删除确认" @on-ok="handleDelete()">
            <p>您确定要删除这个来源吗？</p>
            <p>来源: {{ deleteObj.origin_name }}</p>
            <p>代码: {{ deleteObj.origin_code }}</p>
        </Modal>
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            deleteObj: {},
            deleteModal: false,
            createSwitch: false,
            createObject: {},
            loading: false,
            origins: [],
            error: null,
            editIndex: -1,
            editId: -1,
            editObject: { origin_name: '', origin_code: '' },
            columns1: [
                { align: "center", title: "来源ID", key: "id", width: 80 },
                { align: "center", title: "名称", slot: "origin_name" },
                { align: "center", title: "代码", slot: "origin_code" },
                { align: "center", title: "状态", slot: "status_desc" },
                { title: "操作", slot: "action", align: "center", },
            ],
            ruleValidate: {
                origin_name: [
                    { required: true, message: "来源名称不能为空", },
                ],
                origin_code: [
                    { required: true, message: "来源代码不能为空" },
                ],
            },
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        showDeleteModal (data) {
            console.log('将要删除的对象=>', data);
            this.deleteModal = true;
            this.deleteObj = data;
        },
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.axios.post("origin/create", this.createObject).then(res => {
                        if (res.data.status === 200) {
                            this.$Message.success("创建成功");
                            setTimeout(() => {
                                //this.$router.push("/pages/origins");
                                this.$router.go(0)
                            }, 2000);
                        } else {
                            this.$Message.error(res.data.msg);
                        }
                    })
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        statusOnChange: function (origin) {
            console.log('origin=>', origin);
            let statusId = origin.statusBool ? 0 : 1;
            origin.statusBool = origin.statusBool ? false : true;
            let data = {
                id: origin.id,
                status_id: statusId
            }
            this.axios.post('origin/setStatus', data).then((res) => {
                console.log("修改状态返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        switch1Change: function (e) {
            console.log('switch1 发生 change 事件，携带值为', e.detail.value)
        },
        handleEdit (row, index) {
            this.editId = row.id;
            this.editIndex = index;
            this.editObject = row;
        },
        handleDelete (row) {
            this.axios.get('origin/del/' + row.id).then((res) => {
                console.log("删除返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success("删除成功");
                    console.log('this.origins=>', this.origins);
                    let index = -1;//这里不能设置0，因为数组索引是从0开始的
                    for (let i = 0; i < this.origins.length; i++) {
                        if (this.origins[i].id == row.id) {
                            index = i;
                            break
                        }
                    }
                    if (index >= 0) {
                        this.origins.splice(index, 1);
                    }
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        handleSave (row, index) {
            console.log("enter row", row);
            console.log("enter index", index);
            this.editIndex = -1;
            let data = {
                origin_name: this.editObject.origin_name,
                origin_code: this.editObject.origin_code,
            };
            this.loading = true;
            this.axios.post('origin/edit/' + this.editId, data).then((res) => {
                console.log("修改返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    row.origin_name = this.editObject.origin_name;
                    row.origin_code = this.editObject.origin_name;
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/goods_edit/" + row.id);
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("origin/list").then((res) => {
                console.log("res", res);
                res.data.origin.forEach(element => {
                    element.statusBool = element.status_id == 1 ? true : false;
                });
                this.origins = res.data.origin;
                this.loading = false;
            });
        },
    },
};
</script>
