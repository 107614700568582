<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-04-08 11:07:44
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="allocation" :model="allocation" :rules="ruleValidate" label-position="right" :label-width="200" v-if="allocation">
<!--        <FormItem label="公司名称" prop="company">-->
<!--          <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies" :checkObjName="checkObjName"></dropdown>-->
<!--        </FormItem>-->
        <FormItem label="备注" prop="remark">
            <Input v-model="allocation.remark" placeholder="请输入备注"></Input>
        </FormItem>
        <FormItem label="调拨单商品" prop="allocation_goods">
          <Button style="margin-left: 8px" @click="addGoods()">添加</Button>
          <List>
            <ListItem v-for="(good, ind) of goods" :key="ind" style="border: 1px solid red; margin-top: 6px; padding: 0 10px; border-radius: 10px;">
              <List>
                <ListItem>
                  <div style="display: flex;">
                    <label style="width: 250px; text-align:right; padding-right: 10px;">调出公司</label>
                    <dropdown @item-click="dropDownClickPut" :isNeedSearch="true" :companies="companies" :checkObjName="good.put_company ? good.put_company.company_name : ''"  :checkId="ind"></dropdown>
                  </div>
                  <div style="display: flex;">
                    <label style="width: 250px; text-align:right; padding-right: 10px;">调入公司</label>
                    <dropdown @item-click="dropDownClickEnter" :isNeedSearch="true" :companies="companies" :checkObjName="good.enter_company ? good.enter_company.company_name : ''"  :checkId="ind"></dropdown>
                  </div>
                  <div style="display: flex;" v-if="good.categories">
                    <label style="width: 100px; text-align:right; padding-right: 10px;">分类名称</label>
                    <!--                    <Input v-model="good.alias" placeholder="请输入分类名称" style="width: 200px;">-->
                    <!--                    </Input>-->
                    <Select v-model="good.category_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
                      <Option v-for="category in good.categories" :value="category.coupon_categories.category_id" :key="category.coupon_categories.category_id">{{ category.coupon_categories.alias }}</Option>
                    </Select>
                  </div>
                  <div style="display: flex; margin-top: 6px; width: 100%">
                    <label style="width: 100px; text-align:right; padding-right: 10px;">数量</label>
                    <Input v-model="good.num" placeholder="请输入数量" style="width: 200px;">
                    </Input>
                  </div>
                  <button type="button" style="float:right; padding: 0 10px; width: 150px; " @click="delBoxGoods(ind)">删除</button>
                </ListItem>
              </List>
            </ListItem>
          </List>
        </FormItem>
        <FormItem>
            <Button ref="but_submit" type="primary" @click="formHandleSubmit('allocation')" :disabled="butDisabled">提交</Button>
        </FormItem>
    </Form>
</template>
<script>
import Dropdown from '../member/components/dropdown.vue'
export default {
    props: {
        allocation: Object,
        user_types: Array,
        companies: Array,
        checkObjName: String,
    },
    data () {
        return {
            goods: [],
            butDisabled: false,
            ruleValidate: {
            },
        };
    },
    components: {
      Dropdown,
    },
    watch:{
      allocation(){
        if (this.allocation.allocation_goods) {
          let goods = this.allocation.allocation_goods;
          goods.forEach( item => {
            if (item.put_company_id) {
              this.axios.get("agentGoods/list?company_id="+item.put_company_id).then((res) => {
                item.categories = res.data.agent_goods;
                this.goods.push(item);
              });
            }else{
              this.goods.push(item);
            }
          })
        }
      }
    },
    methods: {
        formHandleSubmit: function (name) {
            // if (!this.purchase.company_id){
            //   this.$Message.error("公司不能为空!");
            //   return;
            // }
            if (this.goods.length === 0){
              this.$Message.error("商品不能为空!");
              return;
            }
            let allocation_goods = [];
            try {
              this.goods.forEach((goods_) => {
                if (!goods_.category_id || !goods_.num || !goods_.put_company_id || !goods_.enter_company_id){
                  throw 'error';
                }else{
                  allocation_goods.push(goods_);
                }
              });
            } catch (e) {
              this.$Message.error("请完整填写商品信息!");
              return;
            }
            this.allocation.allocation_goods = allocation_goods;
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.allocation);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        dropDownClickPut(e) {
          this.goods[e.index].put_company_id = e.id;
          this.axios.get("agentGoods/list?company_id="+e.id).then((res) => {
            this.goods[e.index].categories = res.data.agent_goods;
            this.$forceUpdate();
          });
        },
        dropDownClickEnter(e) {
          this.goods[e.index].enter_company_id = e.id;
          // this.axios.get("agentGoods/list?company_id="+e.id).then((res) => {
          //   this.goods[e.index].categories = res.data.agent_goods;
          //   this.$forceUpdate();
          // });
        },
        addGoods: function () {
          this.goods.push({});
        },
        delBoxGoods (ind) {
          let delIndex = -1;
          for (let i = 0; i < this.goods.length; i++) {
            console.log(this.goods[i]);
            if (i === ind) {
              delIndex = i;
            }
          }
          if (delIndex > -1) {
            this.goods.splice(delIndex, 1);
          }
        },
    },
};
</script>
