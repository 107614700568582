<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-09 11:30:56
 * @FilePath: \qyOut\src\pages\supplier\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <div style="font-size:15px">
          <Icon type="md-arrow-back"></Icon>
          <a @click="goBack"> 返回</a>
        </div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>电子券管理</BreadcrumbItem>
            <BreadcrumbItem>订单详情</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :coupon="coupon" :companies="companies" :checkObjName="checkObjName" :categories="categories" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
import axios from "axios";
export default {
    components: { formPage, loading },
    data () {
        return {
            coupon: {},
            loading: false,
            companies: [],
            checkObjName: '',
            error: null,
            categories:[],
            fromName: {}
        }
    },
    created () {
        this.fetchData()
        axios.get("company?type=0").then(res => {
          this.companies = res.data.companies
        })
        this.axios.get("couponCategories/totalList").then((res) => {
          console.log("res", res);
          this.categories = res.data.coupon_categories;
          this.loading = false;
        });
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        // 通过 `vm` 访问组件实例,将值传入fromPath
        vm.fromName = from.name;
      });
    },
    watch: {
        $route (to, from) {
            // 对路由变化作出响应...
            console.log("to", to)
            console.log("from", from)
        }
    },
    methods: {
        handleSubmit (purchase) {
            // 验证通过
            console.log("验证通过", purchase);
            // this.loading = true; this.error = null
            // this.axios
            //     .post("purchase/edit/" + purchase.id, purchase)
            //     .then((res) => {
            //         console.log("修改返回res", res)
            //         this.loading = false;
            //         if (res.data.status === 200) {
            //             this.$Message.success('修改成功');
            //             setTimeout(() => {
            //                 this.$router.push("/pages/purchases");
            //             }, 2000);
            //         } else {
            //           this.$Message.error(res.data.msg);
            //         }
            //     })
        },

        fetchData () {
            this.error = null;
            this.loading = true;
            this.axios
                .get("coupons/show/" + this.$route.params.id)
                .then((res) => {
                    console.log("res", res);
                    this.coupon = res.data.coupon;
                    this.checkObjName = res.data.coupon.coupon_categories.company.company_name;
                    console.log('checkObjName'+this.checkObjName);
                    this.loading = false;
                });

        },
        goBack() {
          this.$router.push({ name: this.fromName, params: this.$route.query});
        }
    },
};
</script>
