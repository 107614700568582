<!--
 * @Author: 陈远锋
 * @Date: 2022-04-02 10:33:27
 * @LastEditTime: 2022-04-02 11:05:12
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\companies\form.vue
 * @Description: Do not edit
-->
<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-03-14 13:36:16
 * @FilePath: \qyOut\src\pages\company\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="company" :model="company" :rules="ruleValidate" label-position="right" :label-width="200" v-if="company">
        <FormItem label="公司编码" prop="code">
          <Input v-model="company.code" placeholder="请输入公司编码"></Input>
        </FormItem>
        <FormItem label="公司名称" prop="company_name">
            <Input v-model="company.company_name" placeholder="请输入公司名称"></Input>
        </FormItem>
        <FormItem label="联络人" prop=" contact">
            <Input v-model="company.contact" placeholder="请输入公司联络人"></Input>
        </FormItem>
        <FormItem label="电话" prop="phone">
            <Input v-model="company.phone" placeholder="请输入联系电话"></Input>
        </FormItem>
        <FormItem label="类型" prop="type">
          <RadioGroup v-model="company.type">
            <Radio label="0">代理商</Radio>
            <Radio label="1">供应商</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem>
            <Button ref="but_submit" type="primary" @click="formHandleSubmit('company')" :disabled="butDisabled">提交</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        company: Object,
        company_types: Array,
    },
    data () {
        return {
            butDisabled: false,
            ruleValidate: {
              code: [
                    {
                        required: true,
                        message: "The code cannot be empty",
                        trigger: "blur",
                    },
                ],
              company_name: [
                    {
                        required: true,
                        message: "The company_name cannot be empty",
                        trigger: "blur",
                    },
                ],
              contact: [
                    {
                        required: true,
                        message: "The contact cannot be empty",
                        trigger: "blur",
                    },
                ],
              phone: [
                    {
                        required: true,
                        message: "The phone cannot be empty",
                        trigger: "blur",
                    },
                ],
              type: [
                    {
                        required: true,
                        message: "The type cannot be empty",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.company);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
