<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 16:02:57
 * @LastEditTime: 2022-03-08 16:23:48
 * @FilePath: \qyOut\src\pages\member\batch_orders_expand.vue
 * @Description: Do not edit
-->

<template>
    <div class="expand-row">
        <!-- <Row>
            <Col span="4">时间</Col>
            <Col span="4">订单号</Col>
            <Col span="4">账号</Col>
            <Col span="4">价格</Col>
        </Row>

        <Row v-for="(item, index) of row" :key="index">
            <Col span="4">{{ item.created_at }}</Col>
            <Col span="4">{{ item.order_no }}</Col>
            <Col span="4">{{ item.account }}</Col>
            <Col span="4">{{ item.price }}</Col>
        </Row> -->
        <Table border stripe :columns="columns2" :data="row"> </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
            tableData: [],
            columns2: [
                {
                    align: "center",
                    title: '时间',
                    key: 'created_at'
                },
                {
                    align: "center",
                    title: '订单号',
                    key: 'order_no'
                }, {
                    align: "center",
                    title: '账号',
                    key: 'account'
                }, {
                    align: "center",
                    title: '价格',
                    key: 'price'
                }, {
                    align: "center",
                    title: '状态',
                    key: 'status_desc'
                }
            ]
        }
    },
    created () {
        //this.tableData.push(this.row);
    }
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
}
</style>
