<template>
  <div>
    <Breadcrumb :style="{ margin: '20px 10px' }">
      <BreadcrumbItem>电子券管理</BreadcrumbItem>
      <BreadcrumbItem>废弃券</BreadcrumbItem>
    </Breadcrumb>
    <loading :loading="loading" :error="error"></loading>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <Form ref="cancelData" :model="cancelData" :rules="ruleValidate" label-position="right" :label-width="200" v-if="cancelData">
      <FormItem label="券码" prop="voucher">
        <Input v-model="cancelData.voucher" placeholder="请输入券码"></Input>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit('cancelData')">废弃券</Button>
        <Button style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import loading from '../loading.vue';
export default {
  components: { loading },
  data () {
    return {
      loading: false,
      error: null,
      cancelData: {},
      ruleValidate: {
        voucher: [
          {
            required: true,
            message: "券码不能为空",
            trigger: "blur",
          },
          {
            type: "string",
            message: "券码是字符串",
            trigger: "blur",
          },
        ],
      },
    }
  },
  created () {
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          // 验证通过
          console.log("验证通过");
          this.loading = true;
          this.axios
              .post("coupons/cancelCoupons", this.cancelData)
              .then((res) => {
                if (res.data.status === 200) {
                  this.$Message.success('废弃成功');
                  setTimeout(() => {
                    this.$router.push("/pages/coupons");
                  }, 2000);
                } else {
                  this.$Message.error(res.data.msg);
                }
              });
        } else {
          this.$Message.error("请输入正确的数据!");
        }
      });
    },
  },
}
</script>

<style scoped>

</style>