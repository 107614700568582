<!--
 * @Author: 陈远锋
 * @Date: 2022-02-15 14:56:47
 * @LastEditTime: 2022-02-15 15:47:46
 * @FilePath: \qyOut\src\Pay.vue
 * @Description: Do not edit
-->
<template>
  <div >
          <Breadcrumb separator="<b>></b>" class="layout-nav3" style="margin-left: 12px">
            <BreadcrumbItem to="/components/breadcrumb" >分销商管理</BreadcrumbItem>
            <BreadcrumbItem>充值记录</BreadcrumbItem>
          </Breadcrumb>
          <Content :style="{ minHeight: '200px', background: '#fff' }">
            <div
              :style="{
                background: '#f5f7f9 ',
                margin: '12px',
                padding: '14px',
              }"
            >
              <Row>
                <div style="margin-top: 10px">
                  <Button style="float: left" type="primary">
                    <Icon type="md-download" />
                    导出excel</Button
                  >
                </div>
              </Row>
            </div>
            <div style="margin: 0 12px">
              <Table border :columns="columns1" :data="data1"></Table>
            </div>
            <div
              :style="{
                background: '#f5f7f9 ',
                margin: '12px',
                padding: '14px',
                 height:'50px'
              }"
            >
                <Page style="float: right;" :total="1" size="small" show-total show-sizer />
            </div>
          </Content>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
      value2: "",
      columns1: [
        {
          title: "创建时间",
          key: "time1",
        },
        {
          title: "充值金额",
          key: "amount1",
        },
        {
          title: "备注",
          key: "remark",
        },
        
      ],
      data1: [
        {
          time1: "2021-12-20 11:58:48",
          amount1: 5000.0000,
          remark: '加款',
          
        },
        {
          time1: "2021-10-19 14:39:03",
          amount1: 1000.000,
          remark: '加款',
        
        },
      ],
    };
  },
};
</script>
<style scoped>
.layout-nav3 {
  text-align: left;

  margin: 8px;
}

</style>