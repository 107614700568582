<!--
 * @Author: 陈远锋
 * @Date: 2022-05-19 15:06:00
 * @LastEditTime: 2022-05-20 10:46:21
 * @FilePath: \qyOut\src\pages\coupon_orders\sendCode.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>发码管理</BreadcrumbItem>
            <BreadcrumbItem>发码下单</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <Form v-if="receiveDiv" ref="formItem" :model="formItem" :rules="ruleValidate" label-position="right" :label-width="200">
            <FormItem label="产品" prop="agent_goods_id">
                <RadioGroup v-model="formItem.agent_goods_id" @on-change="goodsSelected" :label-in-value="true">
                    <Radio v-for="item in goods" :label="item.id" :key="item.id" style="width: 400px;">
                        {{ item.goods_name }} --面额 [{{ item.original_price }}] ---售价 [{{ item.sale_price }}]
                    </Radio>
                </RadioGroup>
            </FormItem>

            <FormItem label="账号" prop="phone">
                <Input v-model="formItem.phone" placeholder="请输入手机号码"></Input>
            </FormItem>
            <FormItem>
                <Button ref="button" type="primary" @click="showModal1" :disabled="butDisabled">提交</Button>
            </FormItem>
        </Form>
        <div v-if="receiveDiv==false">
            <div style="width: 200px; margin:10px auto 10px auto; ">
                <p style="text-align: center;">请截图保存</p>
                <canvas id="canvas"></canvas>
            </div>
        </div>
        <Modal v-model="modal1" title="请您确认订单信息" @on-ok="formHandleSubmit" @on-cancel="modal1 = false">
            <p>产品：{{ selectedGoods.goods_name }}</p>
            <p></p>
            <p>账号：{{formItem.phone}}</p>
        </Modal>

    </div>
</template>
<script>
import { mapState } from "vuex";
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            qrCode: '',
            receiveDiv: true,
            modal1: false,
            selectedGoods: {},
            butDisabled: false,
            goods: [],
            loading: false,
            error: null,
            formItem: { agent_goods_id: '', phone: '' },
            ruleValidate: {
                agent_goods_id: [
                    {
                        required: true,
                        message: "请选择要要充值的产品",
                    },
                ],
                phone: [
                    { required: true, message: "账号不能为空" },
                ],
            },
        }
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo"
    ]),
    created () {
        this.fetchData();
        console.log("process.env.NODE_ENV=>", process.env.NODE_ENV);
    },
    methods: {
        goodsSelected (item) {
            console.log('item=>', item);
            //this.selectedGoods = item;
            this.goods.forEach(goods => {
                if (goods.id == item) {
                    this.selectedGoods = goods;
                }
            })
        },
        formHandleSubmit: function (name = 'formItem') {
            console.log('this.formItem=>', this.formItem);
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.axios.post('couponsOrders/getQrCode', this.formItem).then(res => {
                        console.log('批量下单返回', res);
                        if (res.data.status == 200) {
                            this.$Message.success('下单成功');
                            this.loading = false;
                            this.receiveDiv = false;
                            this.formItem = { agent_goods_id: '', phone: '' };
                            setTimeout(() => {
                                this.$router.push("/coupon/" + res.data.coupons_order.code[0]);
                            }, 2000);
                        } else if (res.data.status == 1008) {
                            this.$Message.error("您余额不足!!");
                        } else {
                            console.log(res.data);
                            this.$Message.error("下单出错了, 原因=>" + res.data.msg);
                        }
                    })
                } else {
                    this.$Message.error("请正确填写表单");
                }
            })
        },
        showModal1 () {
            this.$refs['formItem'].validate((valid) => {
                if (valid) {
                    console.log(this.formItem.num);
                    this.modal1 = true;
                } else {
                    console.log('验证没有通过');
                    this.$Message.error('请正确填写表单！')
                }
            })
        },
        fetchData: function () {
            let code = '2000';
            if (process.env.NODE_ENV == 'development') {
                code = '2005;'
            }
            this.axios.get('agentGoods/list?type=1&goods_type_code=' + code + '&company_id=' + this.userInfo.company_id).then(res => {
                console.log('agentGoods/list res=>', res);
                this.goods = res.data.agent_goods;
            })
        }
    }
}
</script>
<style scoped>
</style>
