<!--
 * @Author: 陈远锋
 * @Date: 2022-05-19 15:06:16
 * @LastEditTime: 2022-05-20 23:23:23
 * @FilePath: /qyOut/src/pages/coupon_orders/showCode.vue
 * @Description: Do not edit
-->
<template>
    <div class="out" style="">
        <img style="width: 60px; height: 60px; margin: 0px auto 0 auto; display: block;" src="https://static.zjgnwl.com/qy/img/202205/20-01.png" alt="logo" />
        <div class="center">
            <div style="width: 200px; margin:0px auto 0px auto; ">
                <p class="title">中国石油官方加油抵扣券</p>
                <canvas id="canvas"></canvas>
            </div>
            <p style="text-align: center;">凭此券码到中国石油浙江区域自营加油站核销使用</p>
            <div class="tips" style="font-size: 12px;">
                <p class="tipsTitle">使用说明</p>
                <p style="text-align: center; margin-bottom: 10px;">为了您顺利使用，请仔细阅读以下流程</p>
                <ul style="list-style-type: decimal; margin-left: 3px;">
                    <li>本券仅限浙江地区中国石油自营加油站内使用；</li>
                    <li>本券仅限支付油品消费时使用，不可与其他优惠券叠加使用；</li>
                    <li>本券仅限站内消费油品抵扣，使用前请向收银员同时出示本券；</li>
                    <li>本券同一用户油品券每天仅限使用一张，同一APP账号、同一手机、同一自然人、同一车辆满足任一条件均视为同一用户；</li>
                    <li>本券不可提现，不设找零，商品无质量问题不可退换 ; 复制、修改、转发无效，过期作废；</li>
                    <li>本券在下单成功同时在“中国石油浙江销售分公司”公众号里-我的券包同步显示、使用，公众号绑定号码与抖音下单号码需保持一直；</li>
                    <li>在法律允许的范围内，中国石油浙江销售分公司对此券拥有最终解释权；</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcode'
export default {
    data () {
        return {

        }
    },
    created () {
        document.title = "浙江中石油优惠券";
    },
    methods: {
        //生产二维码
        useQrCode (code) {
            var canvas = document.getElementById("canvas");
            // this.$QRCode.toCanvas(canvas, url, function (error) {
            //   if (error) console.error(error)
            // })
            QRCode.toCanvas(canvas, code, { width: 200 }); //直接修改二维码尺寸
        },
    },
    mounted () {
        document.title = "浙江中石油优惠券";
        let code = this.$route.params.code;
        this.useQrCode(code);
    },
}
</script>
<style>
.out {
    min-height: 100vh;
    background: linear-gradient(to bottom right, #ff3232, #ff8c33);
    padding-top: 20px;
}
.center {
    margin: -30px 20px 30px 20px;
    background-color: white;
    padding: 40px 20px 20px 20px;
    border-radius: 10px;
}
.title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
.tips {
    border-top: 1px dotted rgba(156, 163, 175, 1);
    margin-top: 5px;
    padding: 5px 0;
}
.tipsTitle {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}
</style>
