<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-04-08 11:07:44
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="coupon_categories" :model="coupon_categories" :rules="ruleValidate" label-position="right" :label-width="200" v-if="coupon_categories">
        <FormItem label="券分类ID" prop="category_id">
          <Input v-model="coupon_categories.category_id" placeholder="请输入券分类ID"></Input>
        </FormItem>
        <FormItem label="名称" prop="title">
          <Input v-model="coupon_categories.title" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem label="别名" prop="alias">
          <Input v-model="coupon_categories.alias" placeholder="请输入别名"></Input>
        </FormItem>
        <FormItem label="公司名称" prop="company">
          <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies" :checkObjName="checkObjName"></dropdown>
        </FormItem>
        <FormItem label="类型" prop="cou_category">
          <RadioGroup v-model="coupon_categories.cou_category">
            <Radio :label="10">满减券</Radio>
            <Radio :label="40">折扣券</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="使用类型编码" prop="biz_type">
          <RadioGroup v-model="coupon_categories.biz_type">
            <Radio label="4001">油品券</Radio>
            <Radio label="4002">汽油券</Radio>
            <Radio label="4003">柴油券</Radio>
            <Radio label="4004">非油券</Radio>
            <Radio label="4005">异业券</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="面额类型" prop="face_type">
          <RadioGroup v-model="coupon_categories.face_type">
            <Radio :label="1">固定金额</Radio>
            <Radio :label="3">动态金额</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="面额" prop="face_value">
          <Input v-model="coupon_categories.face_value" placeholder="请输入券面额"></Input>
        </FormItem>
        <FormItem label="进价" prop="bid">
          <Input v-model="coupon_categories.bid" placeholder="请输入券进价"></Input>
        </FormItem>
        <FormItem label="成本" prop="unit_cost">
          <Input v-model="coupon_categories.unit_cost" placeholder="请输入券成本"></Input>
        </FormItem>
        <FormItem label="折扣率" prop="cou_discount_value">
          <Input v-model="coupon_categories.cou_discount_value" placeholder="请输入折扣率"></Input>
        </FormItem>
        <FormItem label="折扣展示" prop="cou_discount_value_str">
          <Input v-model="coupon_categories.cou_discount_value_str" placeholder="请输入折扣展示"></Input>
        </FormItem>
        <FormItem label="最大折扣金额" prop="cou_discount_max_value">
          <Input v-model="coupon_categories.cou_discount_max_value" placeholder="请输入最大折扣金额"></Input>
        </FormItem>
        <FormItem label="券图片路径" prop="img">
          <img :src="coupon_categories.img" alt="">
        </FormItem>
        <FormItem label="券使用说明" prop="declare">
          {{ coupon_categories.declare }}
        </FormItem>
        <FormItem label="有效期类型" prop="valid_type">
          <RadioGroup v-model="coupon_categories.valid_type">
            <Radio :label="1">固定</Radio>
            <Radio :label="2">动态</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="有效期" prop="valid_date">
          <Input v-model="coupon_categories.valid_date" placeholder="请输入有效期"></Input>
        </FormItem>
        <FormItem label="库存数量" prop="cnt">
          <Input v-model="coupon_categories.cnt" placeholder="请输入库存数量"></Input>
        </FormItem>
        <FormItem label="是否控制库存" prop="control_cnt">
          <RadioGroup v-model="coupon_categories.control_cnt">
            <Radio :label="1">控制</Radio>
            <Radio :label="0">不控制</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="机构编码" prop="ou_code">
          <Input v-model="coupon_categories.ou_code" placeholder="请输入机构编码"></Input>
        </FormItem>
        <FormItem label="机构名称" prop="ou_name">
          <Input v-model="coupon_categories.ou_name" placeholder="请输入机构名称"></Input>
        </FormItem>
        <FormItem>
<!--            <Button ref="but_submit" type="primary" @click="formHandleSubmit('purchase')" :disabled="butDisabled">提交</Button>-->
        </FormItem>
    </Form>
</template>
<script>
import Dropdown from '../member/components/dropdown.vue'
export default {
    props: {
        coupon_categories: Object,
        companies: Array,
        checkObjName: String,
    },
    data () {
        return {
            butDisabled: false,
            ruleValidate: {
            },
        };
    },
    components: {
      Dropdown,
    },
    watch:{
      coupon_categories(){

      }
    },
    methods: {
        formHandleSubmit: function (name) {
            if (!this.purchase.company_id){
              this.$Message.error("公司不能为空!");
              return;
            }
            if (this.goods.length === 0){
              this.$Message.error("商品不能为空!");
              return;
            }
            let purchase_goods = [];
            try {
              this.goods.forEach((goods_) => {
                if (!goods_.bid || !goods_.num || !goods_.alias){
                  throw 'error';
                }else{
                  purchase_goods.push(goods_);
                }
              });
            } catch (e) {
              this.$Message.error("请完整填写商品信息!");
              return;
            }
            this.purchase.purchase_goods = purchase_goods;
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.purchase);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        dropDownClick(e) {
          this.coupon_categories.company_id = e.id;
        },
    },
};
</script>
