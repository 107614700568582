<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-04-08 10:48:50
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\goods_type\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>商品类型</BreadcrumbItem>
            <BreadcrumbItem>编辑商品类型</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <div v-if="goods_type" class="content">
            <Form ref="goods_type" :model="goods_type" :rules="ruleValidate" label-position="right" :label-width="100">
                <FormItem label="id">
                    <Input disabled v-model="goods_type.id" placeholder="Enter something..."></Input>
                </FormItem>
                <FormItem label="编码" prop="code">
                    <Input v-model="goods_type.code" placeholder="Enter something..."></Input>
                </FormItem>
                <FormItem label="名称">
                    <Input v-model="goods_type.name" placeholder="Enter something..."></Input>
                </FormItem>
                <FormItem label="状态">
                    <RadioGroup v-model.number="goods_type.status_id">
                        <Radio :label="0">
                            <span>关闭</span>
                        </Radio>
                        <Radio :label="1">
                            <span>正常</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem label="类型">
                    <RadioGroup v-model="goods_type.type">
                        <Radio :label="0">
                            <span>直充</span>
                        </Radio>
                        <Radio :label="1">
                            <span>卡券</span>
                        </Radio>
                        <Radio :label="2">
                            <span>权益包</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleSubmit('goods_type')">提交</Button>
                </FormItem>
            </Form>
        </div>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            goods_type: {},
            loading: false,
            error: null,
            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: "The name cannot be empty",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        message: "code必须是数字",
                        trigger: "blur",
                    },
                ],
                city: [
                    {
                        required: true,
                        message: "Please select the city",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    created () {
        console.log("created");
        this.fetchData();
        console.log(this.$route.params);
    },
    watch: {
        $route (to, from) {
            // 对路由变化作出响应...
            console.log("to", to);
            console.log("from", from);
        },
    },
    methods: {
        handleSubmit (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.axios
                        .post(
                            "goodsTypes/edit/" + this.$route.params.id,
                            this.goods_type
                        )
                        .then((res) => {
                            console.log("修改返回res", res);
                            this.loading = false;
                            if (res.data.status === 200) {
                                this.$Message.success("修改成功");
                                setTimeout(() => {
                                    this.$router.push("/pages/goods_type_list");
                                }, 2000);
                            }
                        });
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/goods_type_edit/" + row.id);
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios
                .get("goodsTypes/detail/" + this.$route.params.id)
                .then((res) => {
                    console.log("res", res);
                    this.goods_type = res.data.goods_types;
                    delete this.goods_type.created_at;
                    delete this.goods_type.updated_at;
                    this.loading = false;
                });
        },
    },
};
</script>
