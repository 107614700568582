<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-15 14:48:27
 * @FilePath: \qyOut\src\pages\goods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/goods_create" style="float:right;">
                新增基础商品
            </router-link>
            <BreadcrumbItem>基础商品</BreadcrumbItem>
            <BreadcrumbItem>基础商品列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
		<Form ref="selectForm" :model="selectForm" inline>
			<FormItem prop="goods_no">
				<Input v-model="selectForm.goods_no" placeholder="商品编号"></Input>
			</FormItem>
			<FormItem prop="goods_name">
				<Input v-model="selectForm.goods_name" placeholder="商品名称"></Input>
			</FormItem>
			<FormItem>
				<Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
			</FormItem>
		</Form>
        <div v-if="goods" class="content">
            <Table border stripe :columns="columns1" :data="goods">
                <template slot-scope="{ row }" slot="name">
                    <strong>{{ row.name }}</strong>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
					<Button v-if="row.area_type === 0" type="primary" size="small" style="margin-right: 5px" @click="area(row, index)">分配</Button>
                    <router-link v-if="row.type == 2" :to="'/pages/goods_box_goods_edit/'+row.id" style="border: 1px solid blue; margin-left: 6px; padding: 2px; border-radius: 4px; height: 40px;">
                        权益包商品
                    </router-link>
                </template>
            </Table>
        </div>
        <expandRow style="display: none"></expandRow>
		<Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import expandRow from './supplier_goods_expand.vue';
import loading from '../loading.vue';
export default {
    components: { expandRow, loading },
    data () {
        return {
            loading: false,
            goods: null,
            error: null,
			pages: {},
			selectForm: {},
            columns1: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row.supplier_goods
                            }
                        })
                    }
                },
                /* { title: "时间", key: "updated_at", align: "center", }, */
                /* { align: "center", title: "ID", key: "id", width: 80 }, */
                { align: "center", title: "分类ID", key: "goods_type_id", width: 80 },
                { align: "center", title: "商品编号", key: "goods_no" },
                { align: "center", title: "商品类型", key: "type_desc" },
                { align: "center", title: "名称", key: "goods_name" },
                { align: "center", title: "原价", key: "original_price", width: 100 },
                { align: "center", title: "上架", key: "sale_desc", },
                {
                    align: "center",
                    title: "状态",
                    key: "status_desc",
                },
                { title: "操作", slot: "action", align: "center", },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData("goods/list");
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/goods_edit/" + row.id);
        },
		area (row, index) {
			console.log("row=>", row.id);
			console.log("index=>", index);
			this.$router.push("/pages/goods_area/" + row.id);
		},
        fetchData: function (url, params) {
			let data = {};
			for (let key in params) {
				console.log('param[key],', params[key]);
				if (params[key] === undefined || params[key].length == 0) {
					delete params[key];
				}
			}
			data.params = params;
			this.loading = true;
            this.error = this.post = null;
            this.loading = true;
			this.axios.get(url, data).then((res) => {
                console.log("res", res);
                this.goods = res.data.goods;
                this.loading = false;
				this.pages = res.data.pages;
                this.goods.forEach(element => {
                    element._expanded = false;
                });
            });
        },
		handleSubmit: function (name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.fetchData('goods/list', this.selectForm);
				} else {
					this.$Message.error('出错了');
				}
			})
		},
		pageOnChange: function (page) {
			this.pages.links.forEach(link => {
				console.log('link=>', link);
				if (link.label == page) {
					this.fetchData(link.url);
					return;
				}
			})
		},
    },
};
</script>
