<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-31 23:00:24
 * @FilePath: \qyOut\src\pages\roles\permission.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>角色管理</BreadcrumbItem>
            <BreadcrumbItem>编辑角色权限</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="role" :model="role" label-position="right" :label-width="200" v-if="role">
            <FormItem label="角色名字" prop="name">
                <Input v-model="role.name" placeholder="请输入角色名字" disabled></Input>
            </FormItem>
            <FormItem label="能力" prop="ability">
                <CheckboxGroup v-model="selectedPermission">
                    <Checkbox :label="permission.id" v-for="(permission, index) of permissions" :key="index" style="width: 150px;">
                        {{ permission.name}}
                    </Checkbox>
                </CheckboxGroup>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="handleSubmit">提交</Button>
                <Button style="margin-left: 8px">取消</Button>
            </FormItem>
        </Form>
    </div>
</template>
<script>
import axios from 'axios';
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            id: -1,
            is11: '0',
            role: {},
            goods_types: [],
            loading: false,
            error: null,
            permissions: [],
            selectedPermission: []
        }
    },
    created () {
        console.log("created")
        console.log(this.$route.params);
        this.id = this.$route.params.id;
        axios.get('roles/' + this.id).then(res => {
            this.role = res.data.role;
            this.selectedPermission = [];
            this.role.permissions.forEach(per => {
                this.selectedPermission.push(per.id);
            });
        });
        axios.get('permissions').then(res => {
            this.permissions = res.data.permissions;
        });
    },
    watch: {
        $route (to, from) {
            // 对路由变化作出响应...
            console.log("to", to)
            console.log("from", from)
        }
    },
    methods: {
        handleSubmit () {
            // 验证通过
            console.log("验证通过", this.selectedPermission);
            this.loading = true; this.error = null
            this.axios.patch("roles/" + this.id + "/permission", { id: this.selectedPermission }).then((res) => {
                console.log("修改返回res", res)
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success('修改成功');
                    setTimeout(() => {
                        this.$router.push("/pages/roles");
                    }, 2000);
                } else {
                    for (let key in res.data.errors) {
                        this.$Message.error(res.data.errors[key][0]);
                    }
                }
            })
        },
        show (row, index) {
            console.log("row=>", row.id)
            console.log("index=>", index)
            this.$router.push("/pages/goods_type_edit/" + row.id)
        }
    },
};
</script>
