var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{staticClass:"layout-nav3",staticStyle:{"margin-left":"12px"},attrs:{"separator":"<b>></b>"}},[_c('BreadcrumbItem',{attrs:{"to":"/components/breadcrumb"}},[_vm._v("分销商管理")]),_c('BreadcrumbItem',[_vm._v("充值记录")])],1),_c('Content',{style:({ minHeight: '200px', background: '#fff' })},[_c('div',{style:({
              background: '#f5f7f9 ',
              margin: '12px',
              padding: '14px',
            })},[_c('Row',[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('Button',{staticStyle:{"float":"left"},attrs:{"type":"primary"}},[_c('Icon',{attrs:{"type":"md-download"}}),_vm._v(" 导出excel")],1)],1)])],1),_c('div',{staticStyle:{"margin":"0 12px"}},[_c('Table',{attrs:{"border":"","columns":_vm.columns1,"data":_vm.data1}})],1),_c('div',{style:({
              background: '#f5f7f9 ',
              margin: '12px',
              padding: '14px',
               height:'50px'
            })},[_c('Page',{staticStyle:{"float":"right"},attrs:{"total":1,"size":"small","show-total":"","show-sizer":""}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }