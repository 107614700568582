<!--
 * @Author: 陈远锋
 * @Date: 2022-02-14 17:00:12
 * @LastEditTime: 2022-12-16 16:16:08
 * @FilePath: \qyOut\src\App.vue
 * @Description: Do not edit
-->
<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "token",
    ]),
    created: function () {
        // `this` 指向 vm 实例
        console.log("---------created========");
        console.log("isLogin=>", Boolean(this.token));
        console.log("token=>", this.token);
        console.log('this.$router.currentRoute.path=>', this.$router.currentRoute.path)
		if (Boolean(this.token) === true) {
			axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
			if (this.$router.currentRoute.path === '/' || this.$router.currentRoute.path === '/login' ) {
				this.$router.push("/pages/member_info");
			}
		} else {
			console.log('当前在登陆页面')
		}
        // if (this.$router.currentRoute.path == "/login") {
        //     console.log('当前在登陆页面')
        // }
        // else if (this.token == false && this.$router.currentRoute.path.indexOf("/coupon/") == -1
        //     && this.$router.currentRoute.path.indexOf("/login") == -1
        // ) {
        //     console.log("没有登陆过，跳去登陆页面");
        //     this.$router.push("/login");
        // } else {
        //     axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
		// 	if (this.$router.currentRoute.path === '/' || this.$router.currentRoute.path === '/login' ) {
		// 		this.$router.push("/pages/member_info");
		// 	}
        // }
    },
    mounted: function () {
        //document.title = "光凝权益平台";
        console.log("--------mounted========");
        console.log("isLogin=>", Boolean(this.token));
        console.log('this.$router.currentRoute.path=>', this.$router.currentRoute.path);
		if (Boolean(this.token) === true) {
			axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
			console.log('path', this.$router.currentRoute.path);
			if (this.$router.currentRoute.path === '/' || this.$router.currentRoute.path === '/login' ) {
				this.$router.push("/pages/member_info");
			}
		} else {
			console.log('当前在登陆页面')
		}
        // if (this.$router.currentRoute.path == "/login") {
        //     console.log('当前在登陆页面')
        // }
		// else if (this.token == false && this.$router.currentRoute.path.indexOf("/coupon/") == -1) {
        //     console.log("没有登陆过，跳去登陆页面");
        //     this.$router.push("/login");
        // } else {
        //     axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
        //     console.log('path', this.$router.currentRoute.path);
        //     if (this.$router.currentRoute.path === '/' || this.$router.currentRoute.path === '/login' ) {
        //         this.$router.push("/pages/member_info");
        //     }
        // }
    },
};
</script>
