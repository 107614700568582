<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:41:52
 * @LastEditTime: 2022-04-01 16:18:30
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\prepaid_orders\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>订单管理</BreadcrumbItem>
            <BreadcrumbItem>批量订单列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="date">
                <Select v-model="selectForm.company_id" style="width: 250px; margin-right: 10px">
                    <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.short_name }}</Option>
                </Select>
            </FormItem>
            <FormItem prop="date">
                <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
            </FormItem>

            <FormItem>
                <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
            </FormItem>
        </Form>
        <Table border stripe :columns="columns1" :data="orders">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.company_name }}
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
            </template>
        </Table>
        <expandRow style="display: none"></expandRow>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import expandRow from './batch_orders_expand.vue';
import loading from '../loading.vue';
export default {
    components: { expandRow, loading },
    data () {
        return {
            loading: false,
            error: null,
            orders: [],
            pages: {},
            selectForm: {},
            companies: [],
            ruleInline: {},
            columns1: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        let order = params.row.order;
                        return h(expandRow, {
                            props: {
                                row: order
                            }
                        })
                    }
                },
                { title: "时间", key: "updated_at", align: "center", },
                { align: "center", title: '代理商', slot: 'company_name' },
                { align: "center", title: '产品名', key: 'goods_name' },
                { align: "center", title: '订单号', key: 'order_no' },
                { align: "center", title: '合计数量', key: 'total_num', width: 100 },
                {
                    align: "center", title: '合计金额', key: 'total', width: 100
                }, {
                    align: "center", title: '成功数量', key: 'success_num', width: 100
                }, {
                    align: "center", title: '失败数量', key: 'error_num', width: 100
                }
            ]
        }
    },
    created () {
        this.fetchData('perpaidOrders/list');
        this.axios.get('companies').then(res => {
            this.companies = res.data.companies
        })
    },
    methods: {
        dateOnChange (date) {
            console.log(date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
        },
        handleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    //let url = 'perpaidOrders/bills';
                    this.fetchData('perpaidOrders/list', this.selectForm);
                } else {
                    this.$Message.error('出错了');
                }
            })
        },
        pageOnChange: function (page) {
            this.pages.links.forEach(link => {
                console.log('link=>', link);
                if (link.label == page) {
                    this.fetchData(link.url);
                    return;
                }
            })
        },
        fetchData: function (url, params) {
            let data = {};
            for (let key in params) {
                console.log('param[key],', params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }
            data.params = params;
            this.loading = true;
            this.axios.get(url, data).then(res => {
                console.log('perpaidOrders/list res', res);
                this.orders = res.data.orders;
                this.orders.forEach(element => {
                    element._expanded = false;
                });
                this.pages = res.data.pages;
                this.loading = false;
            })
        }
    }
}
</script>
