<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-04-08 12:57:09
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\companies\add_balance.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>代理公司管理</BreadcrumbItem>
            <BreadcrumbItem>公司加款</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <Form ref="company" :model="company" :rules="ruleValidate" label-position="right" :label-width="200" v-if="company">

            <FormItem label="公司名称" prop="company">
                <Input v-model="company.company_name" disabled placeholder="Enter something..."></Input>
            </FormItem>
            <FormItem label="现有余额" prop="balance">
                <Input v-model="company.balance" disabled placeholder="Enter something..."></Input>
            </FormItem>
            <FormItem label="操作类别" prop="total">
                <RadioGroup v-model.number="pm">
                    <Radio :label="0">扣减</Radio>
                    <Radio :label="1">增加</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="操作金额" prop="total">
                <Input v-model="total" placeholder="请输入加款金额"></Input>
            </FormItem>
            <FormItem label="备注" prop="mark">
                <Input v-model="mark" placeholder="请输入备注"></Input>
            </FormItem>
            <FormItem>
                <Button ref="but_submit" type="primary" @click="formHandleSubmit('company')" :disabled="butDisabled">提交</Button>
            </FormItem>
        </Form>

    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            company: {},
            loading: false,
            error: null,
            total: 0,
            mark: '',
            pm: -1,
            butDisabled: false,
            ruleValidate: {
                total: [
                    {
                        required: true, message: "金额不能为空", transform: (value) => {
                            return parseInt(value, 10);  // 转为数字
                        },
                        pattern: /.+/
                    }
                ],
            }
        }
    },
    created () {
        console.log("created")
        this.fetchData()
        console.log(this.$route.params)
    },
    methods: {
        formHandleSubmit (name) {
            // 验证通过
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            if (this.total < 0) {
                this.error = '出错了，金额不能小于0';
                return;
            }
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.loading = true; this.error = null;
                    let data = {
                        pm: this.pm,
                        total: this.total,
                        mark: this.mark
                    };
                    this.axios.post("companies/" + this.company.id + "/addDeposit", data).then((res) => {
                        console.log("修改返回res", res)
                        this.loading = false;
                        if (res.data.status === 201) {
                            this.$Message.success('加款成功');
                            setTimeout(() => {
                                this.$router.push("/pages/companies");
                            }, 2000);
                        } else {
                            for (let key in res.data.errors) {
                                this.$Message.error(res.data.errors[key][0]);
                            }
                        }
                    })
                } else {
                    this.$Message.error('请正确填写表单');
                }
            });
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("companies/" + this.$route.params.id).then((res) => {
                console.log("res", res);
                this.company = res.data.company;
                this.loading = false;
            });
        },
    },
};
</script>
