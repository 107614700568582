<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-03-30 16:52:37
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\roles\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="role" :model="role" :rules="ruleValidate" label-position="right" :label-width="200" v-if="role">

        <FormItem label="角色名字" prop="name">
            <Input v-model="role.name" placeholder="请输入角色名字"></Input>
        </FormItem>
        <FormItem label="能力" prop="ability">
            <Input v-model="role.ability" placeholder="请输入角色能力"></Input>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="formHandleSubmit('role')">提交</Button>
            <Button style="margin-left: 8px">取消</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        role: Object,
        role_types: Array,
    },
    data () {
        return {
            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: "The name cannot be empty",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        message: "code必须是数字",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "Please select the city",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.role);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
