<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-04-08 11:07:44
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="member" :model="member" :rules="ruleValidate" label-position="right" :label-width="200" v-if="member">
        <FormItem label="公司名称" prop="company">
<!--            <Select v-model="user.company_id">-->
<!--                <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.company_name }}</Option>-->
<!--            </Select>-->
          <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies"></dropdown>
        </FormItem>
        <FormItem label="手机号码" prop="phone">
            <Input v-model="member.phone" placeholder="请输入手机号"></Input>
        </FormItem>
        <FormItem>
            <Button ref="but_submit" type="primary" @click="formHandleSubmit('member')" :disabled="butDisabled">提交</Button>
        </FormItem>
    </Form>
</template>
<script>
import Dropdown from './components/dropdown.vue'
export default {
    props: {
        member: Object,
        user_types: Array,
        companies: Array
    },
    data () {
        return {
            butDisabled: false,
            ruleValidate: {
                phone: [
                    { required: true, message: "手机号码不能为空", trigger: "blur", },
                ],
            },
        };
    },
    components: {
      Dropdown,
    },
    methods: {
        formHandleSubmit: function (name) {
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.member);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        dropDownClick(e) {
          this.member.company_id = e.id;
        }
    },
};
</script>
