<!--
 * @Author: 陈远锋
 * @Date: 2022-03-11 16:31:36
 * @LastEditTime: 2022-04-08 13:12:31
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\coupon_expand.vue
 * @Description: Do not edit
-->
<template>
    <div class="expand-row">
        <Table border stripe :columns="columns2" :data="row">
        </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
            columns2: [
                { align: "center", title: '时间', key: 'created_at' },
                { align: "center", title: '分类名称', key: "alias" },
                { align: "center", title: '数量', key: 'num' },
                { align: "center", title: '进价', key: 'bid' },
                { align: "center", title: '状态', key: 'status_desc' },
            ]
        }
    },
	methods: {
	},
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
}
</style>
