<!--
 * @Author: 王莹莹
 * @Date: 2022-09-28 14:48:12
 * @LastEditTime: 2022-09-28 14:48:12
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\warn\index.vue
 * @Description: Do not edit
-->
<template>
  <div>
    <Breadcrumb :style="{ margin: '20px 10px' }">
      <BreadcrumbItem>报错警告</BreadcrumbItem>
      <BreadcrumbItem>警告列表</BreadcrumbItem>
    </Breadcrumb>
    <loading :loading="loading" :error="error"></loading>
    <Form ref="selectForm" :model="selectForm" inline>
      <FormItem prop="date">
        <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
      </FormItem>
    </Form>
    <div v-if="warns" class="content">
      <Table border stripe :columns="columns1" :data="warns">
<!--        <template slot="action">-->
<!--          1111-->
<!--        </template>-->
      </Table>
      <Page style="margin: 10px; text-align:center;" show-total @on-change="pageOnChange" :total="pages.total" :current="pages.current_page" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
  </div>
</template>

<script>
import loading from "../loading";
export default {
  name: "index",
  components: { loading },
  data () {
    return {
      loading: false,
      warns: [],
      error: null,
      columns1: [
        // { align: "center", title: "守卫名字", key: "guard_name", width: 120 },
        { align: "center", title: "ID", minWidth: 50, key: 'id' },
        { align: "center", title: "报错信息", key: "content", minWidth: 500 },
        { title: "创建时间", key: "created_at", align: "center", minWidth: 200 },
        // { align: "center", title: "父ID", key: "pid", slot: 'pid' },
        // { align: "center", title: "级别", key: "level", width: 100, slot: 'level' },
        // { title: "操作", slot: "action", width: 150, align: "center", },
      ],
      selectForm: {},
      pages: {
        total: 0,
        path: '',
        links: []
      },
    }
  },
  created () {
    this.fetchData();
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchData",
  },
  methods: {
    fetchData (url = 'coupons/couponWarnList', params = {}) {
      this.error = null;
      this.loading = true;
      let data = {};
      for (let key in params) {
        console.log('param[key],', params[key]);
        if (params[key] === undefined || params[key].length === 0) {
          delete params[key];
        }
      }
      data.params = params;
      this.axios.get(url, data).then((res) => {
        this.warns = res.data.coupons;
        this.pages = res.data.meta;
        this.loading = false;
      });
    },
    pageOnChange: function (page) {
      this.pages.links.forEach(link => {
        if (link.label === page) {
          this.fetchData(link.url);
          return;
        }
      })
      let url = this.pages.path.indexOf('?') > 0
          ? this.pages.path + "&page=" + page
          : this.pages.path + "?page=" + page;
      console.log('url=>', url);
      this.fetchData(url, this.selectForm);
    },
    handleSubmit: function (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.fetchData('coupons/couponWarnList', this.selectForm);
        } else {
          this.$Message.error('出错了');
        }
      })
    },
    dateOnChange (date) {
      this.selectForm.start_date = date[0];
      this.selectForm.end_date = date[1];
    },
  }
}
</script>

<style scoped>

</style>