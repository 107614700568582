<!--
 * @Author: 陈远锋
 * @Date: 2022-02-18 14:17:16
 * @LastEditTime: 2022-02-18 14:36:39
 * @FilePath: \qyOut\src\test.vue
 * @Description: Do not edit
-->
<template>
  <div>
    <Table :columns="historyColumns" :data="historyData"></Table>
    <Page
      :total="dataCount"
      :page-size="pageSize"
      show-total
      class="paging"
      @on-change="changepage"
    ></Page>
  </div>
</template>
<style scoped>
.paging {
  
  margin-top: 10px;
}
</style>

<script>
let testData = {
  histories: [
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款89569",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款123456",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
    {
      Platform: "348048374270009345",
      Merchant: "BLUE29509438175969280",
      Account: 13857609736,
      Order: "QQ音乐绿钻月卡",
      Price: 11.4,
      Purchase: 1,
      total: 11.4,
      status: "全部失败",
      Payment: "全部退款",
      time: "2022-02-15 10:19:58",
    },
  ],
};
export default {
  data() {
    return {
      ajaxHistoryData: [],
      dataCount: 0,
      pageSize: 15,
      historyColumns: [
        {
          title: "平台订单号",
          key: "Platform",
        },
        {
          title: "商户订单号",
          key: "Merchant",
        },
        {
          title: "充值账号",
          key: "Account",
        },
        {
          title: "订单商品",
          key: "Order",
        },
        {
          title: "价格",
          key: "Price",
        },
        {
          title: "购买数量",
          key: "Purchase",
        },
        {
          title: "订单总额",
          key: "total",
        },
        {
          title: "订单状态",
          key: "status",
        },
        {
          title: "支付状态",
          key: "Payment",
        },
        {
          title: "创建时间",
          key: "time",
        },
      ],
      historyData: [],
    };
  },
  methods: {
    // 获取历史记录信息
    handleListApproveHistory() {
      // 保存取到的所有数据
      this.ajaxHistoryData = testData.histories;
      this.dataCount = testData.histories.length;
      // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
      if (testData.histories.length < this.pageSize) {
        this.historyData = this.ajaxHistoryData;
      } else {
        this.historyData = this.ajaxHistoryData.slice(0, this.pageSize);
      }
    },
    changepage(index) {
      var _start = (index - 1) * this.pageSize;
      var _end = index * this.pageSize;
      this.historyData = this.ajaxHistoryData.slice(_start, _end);
    },
  },
  created() {
    this.handleListApproveHistory();
  },
};
</script>