var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"expand-row"},[_c('Table',{attrs:{"border":"","stripe":"","columns":_vm.columns2,"data":_vm.row},scopedSlots:_vm._u([{key:"company_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.company.company_name)+" ")]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.title)+" ")]}},{key:"alias",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.alias)+" ")]}},{key:"biz_type_desc",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.biz_type_desc)+" ")]}},{key:"category_desc",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.category_desc)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }