
<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:26:22
 * @LastEditTime: 2022-12-09 09:48:47
 * @FilePath: \qyOut\src\pages\coupon\index.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>电子券管理</BreadcrumbItem>
            <BreadcrumbItem>废弃券列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="company">
              <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies" :checkObjName="selectForm.company_name" style="width: 250px; margin-right: 10px; margin-left: 10px;"></dropdown>
            </FormItem>
            <FormItem prop="category_id">
              <Select v-model="selectForm.category_id" clearable multiple filterable style="width: 250px; margin-right: 10px; margin-left: 10px;">
                <Option v-for="category in categories" :value="category.category_id" :key="category.category_id">{{ category.title }}</Option>
              </Select>
            </FormItem>
            <FormItem prop="alias">
              <Input v-model="selectForm.alias" placeholder="别名"></Input>
            </FormItem>
            <FormItem prop="id">
              <Input v-model="selectForm.id" placeholder="ID"></Input>
            </FormItem>
            <FormItem prop="voucher">
              <Input v-model="selectForm.voucher" placeholder="券码"></Input>
            </FormItem>
            <FormItem prop="business_id">
              <Input v-model="selectForm.business_id" placeholder="手机号"></Input>
            </FormItem>
            <FormItem prop="box_order_no">
              <Input v-model="selectForm.box_order_no" placeholder="三方订单号"></Input>
            </FormItem>
            <FormItem prop="date">
              <DatePicker type="datetimerange" placeholder="废弃时间范围" @on-change="dateOnChange" style="width: 320px"></DatePicker>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="goSelect()">查询</Button>
            </FormItem>
        </Form>
        <Table border stripe :columns="columns1" :data="coupons">
          <template slot-scope="{ row}" slot="alias">
            {{row.coupon_categories.title}}
          </template>
          <template slot-scope="{ row, index }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">详情</Button>
          </template>
        </Table>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" :current="pages.current_page" show-total show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import Dropdown from '../member/components/dropdown.vue'
import loading from '../loading.vue';
export default {
    components: { Dropdown, loading },
    data () {
        return {
            companies: [],
            categories: null,
            deleteModal: false,
            deleteObj: {
                id: 0
            },
            loading: false,
            error: null,
            coupons: [],
            pages: {
                total: 0,
                path: '',
                links: []
            },
            selectForm: {
                company_id: '',
                category_id: '',
                alias: '',
            },
            columns1: [
                // { align: "center", title: '商品名字', key: 'supplier_goods_name' },
                { align: "center", title: 'ID', key: 'id', minWidth:100 },
                { align: "center", title: '客户ID', key: 'gn_id', minWidth:250 },
                { align: "center", title: '批次', key: 'batch_no', minWidth:250 },
                { align: "center", title: '券码', key: 'voucher', minWidth:150 },
                { align: "center", title: '名称', slot: 'alias', minWidth:200 },
                { align: "center", title: '手机号', key: 'business_id', minWidth:150 },
                { align: "center", title: '面值', key: 'face_value', minWidth:100 },
                { align: "center", title: '三方订单号', key: 'box_order_no', minWidth:250 },
                { align: "center", title: '状态', key: 'status_desc', minWidth:80 },
                {
                  title: "操作",
                  slot: "action",
                  width: 250,
                  align: "center",
                },
            ],
            copyValue: ''
        }
    },
    created () {
        if (this.$route.params.page) {
          let url ='coupons/cancelList?page='+this.$route.params.page;
          delete this.$route.params.page;
          this.selectForm = this.$route.params;
          this.fetchData(url, this.$route.params);
        } else {
          this.fetchData()
        }
        this.axios.get("company").then((res) => {
          console.log("res", res);
          this.companies = res.data.companies;
          this.loading = false;
        });
        this.axios.get("couponCategories/totalList").then((res) => {
          console.log("res", res);
          this.categories = res.data.coupon_categories;
          this.loading = false;
        });
    },
    methods: {
        goSelect: function () {
            this.fetchData('coupons/cancelList', this.selectForm);
        },
        pageOnChange: function (page) {
            this.pages.links.forEach(link => {
                if (link.label == page) {
                    this.fetchData(link.url);
                    return;
                }
            })
            let url = this.pages.path.indexOf('?') > 0
                ? this.pages.path + "&page=" + page
                : this.pages.path + "?page=" + page;
            console.log('url=>', url);
            this.fetchData(url, this.selectForm);
        },
        dateOnChange (date) {
            console.log('date', date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
            this.fetchData('coupons/cancelList', this.selectForm);
        },
        fetchData (url = 'coupons/cancelList', params = {}) {
            let data = {};
            for (let key in params) {
                console.log('param[key],', params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }
            data.params = params;
            this.axios.get(url, data).then(res => {
                console.log('coupons/cancelList res', res);
                this.coupons = res.data.coupons;
                this.pages = res.data.meta;
                console.log('this.pages=>', this.pages);
            })
        },
        dropDownClick(e) {
          this.selectForm.company_id = e.id;
          this.selectForm.company_name = e.company_name;
        },
        show (row, index) {
          console.log("row=>", row.id);
          console.log("index=>", index);
          let params = this.selectForm;
          params.page = this.pages.current_page;
          this.$router.push({ path: "/pages/coupon_detail/" + row.id, query: params});
        },
    }
}
</script>
