<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-03-31 11:09:14
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\supplier\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/supplier_create" style="float: right">
                新增供应商
            </router-link>
            <BreadcrumbItem>供应商</BreadcrumbItem>
            <BreadcrumbItem>供应商列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Table v-if="suppliers" border stripe :columns="columns1" :data="suppliers">
            <template slot-scope="{ row }" slot="name">
                <strong>{{ row.name }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
            </template>
        </Table>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            suppliers: [],
            error: null,
            columns1: [
                { title: "时间", key: "updated_at", align: "center", width: 200 },
                { title: "公司名称", key: "supplier", align: "center", },
                { title: "联系人", key: "name", align: "center", },
                { title: "手机号码", key: "phone", align: "center", },
                { title: "级别", key: "level", align: "center", width: 100, },
                { title: "状态", key: "status_desc", align: "center" },
                { title: "代码", key: "code" },
                { title: "供应商余额", key: "balance", align: 'right' },
                { title: "操作", slot: "action", width: 150, align: "center" },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/supplier_edit/" + row.id);
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("supplier/list").then((res) => {
                console.log("读取回来的数据", res.data);
                this.suppliers = res.data.suppliers;
                this.loading = false;
            });
        },
    },
};
</script>
