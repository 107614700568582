
<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:26:22
 * @LastEditTime: 2022-11-28 14:31:39
 * @FilePath: \qyOut\src\pages\coupon\total.vue
 * @Description: Do not edit
-->
<template>
  <div>
    <Breadcrumb :style="{ margin: '20px 10px' }">
      <BreadcrumbItem>财务管理</BreadcrumbItem>
      <BreadcrumbItem>券分类统计</BreadcrumbItem>
    </Breadcrumb>
    <loading :loading="loading" :error="error"></loading>

    <Form ref="selectForm" :model="selectForm" inline>
      <FormItem prop="category_id">
        <Select v-model="selectForm.category_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
          <Option v-for="category in categories" :value="category.category_id" :key="category.category_id">{{ category.title }}</Option>
        </Select>
      </FormItem>
      <FormItem prop="date">
        <DatePicker type="datetimerange" placeholder="核销时间范围" @on-change="dateOnChange" style="width: 320px"></DatePicker>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="goSelect()">查询</Button>
      </FormItem>
    </Form>

    <div style="margin: 10px; text-align: center; font-size: 20px; font-weight: bold;">总计</div>
    <Table border stripe :columns="columns1" :data="batches">
    </Table>
    <expandRow style="display: none"></expandRow>
    <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />

    <Modal v-model="shelvesModal" title="上下架确认" @on-ok="onDownShelves()">
      <p>您确定要把这个批次的券 【<strong v-if="upDownShelvesStatusId==1">上架</strong><strong v-else>下架</strong>】 吗？</p>
      <p>名称: {{ shelvesObj.name }}</p>
      <p>批次: {{ shelvesObj.batch_no }}</p>
      <p>数量: {{ shelvesObj.num }}</p>
    </Modal>

    <div style="margin: 10px; text-align: center; font-size: 20px; font-weight: bold;">今日</div>
    <Table border stripe :columns="columns2" :data="today_batches">
    </Table>
    <expandRow style="display: none"></expandRow>
    <Page style="margin: 10px; text-align:center;" @on-change="pagesOnChange" :total="today_pages.total" :page-size="today_pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />

  </div>
</template>
<script>
import loading from '../loading.vue';
import expandRow from "../coupon/total_expand";
export default {
  components: { expandRow, loading },
  data () {
    return {
      supplierGoods: [],
      shelvesModal: false,
      deleteObj: {
        id: 0
      },
      shelvesObj: {
        id: -1
      },
      upDownShelvesBatchNo: -1,
      upDownShelvesStatusId: -1,
      loading: false,
      error: null,
      batches: [],
      today_batches: [],
      total: [],
      pages: {
        total: 0
      },
      today_pages: {
        total: 0
      },
      selectForm: {
        start_date: '',
        end_date: '',
        code: '',
        batch_no: '',
        price: ''
      },
      categories: null,
      columns1: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                row: params.row.agent_qty
              }
            })
          }
        },
        { align: "center", title: '分类ID', key: 'category_id', minWidth: 100  },
        { align: "center", title: '名称', key: 'title', minWidth: 250  },
        { align: "center", title: '总数', key: 'total_num', minWidth: 80  },
        { align: "center", title: '实际剩余', key: 'coupon_category_qty', minWidth: 80  },
        { align: "center", title: '已发放', key: 'send_num', minWidth: 80  },
        { align: "center", title: '已核销', key: 'verify_num', minWidth: 80  },
        { align: "center", title: '已核销金额', key: 'verify_price', minWidth: 80  },
        { align: "center", title: '未核销', key: 'unverify_num', minWidth: 80  },
        { align: "center", title: '已取消', key: 'cancel_num', minWidth: 80  },
        { align: "center", title: '未发放', key: 'unsend_num', minWidth: 80  },
        // { title: "操作", slot: "action", align: "center", }
      ],
      columns2: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                row: params.row.agent_qty
              }
            })
          }
        },
        { align: "center", title: '分类ID', key: 'category_id', minWidth: 100  },
        { align: "center", title: '名称', key: 'title', minWidth: 250 },
        { align: "center", title: '已发放', key: 'send_num', minWidth: 80 },
        { align: "center", title: '已核销', key: 'verify_num', minWidth: 80 },
        { align: "center", title: '已取消', key: 'cancel_num', minWidth: 80 },
        // { title: "操作", slot: "action", align: "center", }
      ],
      copyValue: ''
    }
  },
  created () {
    this.fetchData();
    this.fetchDataToday();
    this.axios.get("couponCategories/totalList").then((res) => {
      console.log("res", res);
      this.categories = res.data.coupon_categories;
      this.loading = false;
    });
  },
  methods: {
    goSelect: function () {
      this.fetchData('couponStatistics/list', this.selectForm);
    },
    onDownShelves: function () {
      console.log('this.upDownShelvesBatchNo=>', this.upDownShelvesBatchNo);
      console.log('this.upDownShelvesStatusId=>', this.upDownShelvesStatusId);
      this.axios.get('coupons/batchShelves/' + this.upDownShelvesBatchNo + '/' + this.upDownShelvesStatusId).then(res => {
        console.log('coupons/batchShelves res=>', res);
        if (res.data.status == 200) {
          this.$Message.success('操作成功了');

        } else {
          this.$Message.error('上下架失败了');
        }
        this.fetchData();
      })
    },
    pageOnChange: function (page) {
      this.pages.links.forEach(link => {
        if (link.label == page) {
          this.fetchData(link.url);
          return;
        }
      })
    },
    pagesOnChange: function (page) {
      this.today_pages.links.forEach(link => {
        if (link.label == page) {
          this.fetchDataToday(link.url);
          return;
        }
      })
    },
    dateOnChange (date) {
      console.log('date', date);
      this.selectForm.start_date = date[0];
      this.selectForm.end_date = date[1];
      this.fetchData('couponStatistics/list', this.selectForm);
    },
    fetchData (url = 'couponStatistics/list', params = {}) {
      let data = {};
      for (let key in params) {
        console.log('param[key],', params[key]);
        if (params[key] === undefined || params[key].length == 0) {
          delete params[key];
        }
      }
      data.params = params;
      this.axios.get(url, data).then(res => {
        console.log('$member->phone res', res);
        res.data.coupon_categories.forEach(item => {
          item.agent_qty = '查询中...';
          item.total_num = '查询中...';
          item.unsend_num = '查询中...';
        })
        this.batches = res.data.coupon_categories;
        this.pages = res.data.meta;
        console.log('this.pages=>', this.pages);
        this.batches.forEach(batch => {
          this.axios.get('couponStatistics/qty/' + batch.id).then(res1 => {
            console.log('couponStatistics/qty res1', res1);
            batch.agent_qty = res1.data.qty.agent_qty;
            batch.total_num = res1.data.qty.total_num;
            batch.unsend_num = res1.data.qty.unsend_num;
            batch.coupon_category_qty = res1.data.qty.coupon_category_qty;
          })
        })
      })
    },
    fetchDataToday (url = 'couponStatistics/todayList', params = {}) {
      let data = {};
      for (let key in params) {
        console.log('param[key],', params[key]);
        if (params[key] === undefined || params[key].length == 0) {
          delete params[key];
        }
      }
      data.params = params;
      this.axios.get(url, data).then(res => {
        console.log('$member->phone res', res);
        res.data.coupon_categories.forEach(item => {
          item.agent_qty = '查询中...';
          item.total_num = '查询中...';
          item.unsend_num = '查询中...';
        })
        this.today_batches = res.data.coupon_categories;
        this.today_pages = res.data.meta;
        console.log('this.pages=>', this.pages);
        this.today_batches.forEach(today_batch => {
          this.axios.get('couponStatistics/todayQty/' + today_batch.id).then(res1 => {
            console.log('couponStatistics/todayQty res1', res1);
            today_batch.agent_qty = res1.data.today_qty.agent_qty;
            today_batch.total_num = res1.data.today_qty.total_num;
            today_batch.unsend_num = res1.data.today_qty.unsend_num;
          })
        })
      })
    }
  }
}
</script>
