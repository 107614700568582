<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-04-08 11:44:58
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\companies\index.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/companies_create" style="float:right;">
                添加公司
            </router-link>
            <BreadcrumbItem>公司管理</BreadcrumbItem>
            <BreadcrumbItem>公司列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <div v-if="companies" class="content">
            <Table border stripe :columns="columns1" :data="companies">
                <template slot-scope="{ row, index }" slot="code">
                  <Input v-if="editIndex === index" type="text" v-model="editCompany.code" />
                  <span v-else>{{ row.code }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="company_name">
                    <Input v-if="editIndex === index" type="text" v-model="editCompany.company_name" />
                    <span v-else>{{ row.company_name }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="contact">
                    <Input v-if="editIndex === index" type="text" v-model="editCompany.contact" />
                    <span v-else>{{ row.contact }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="phone">
                    <Input v-if="editIndex === index" type="text" v-model="editCompany.phone" />
                    <span v-else>{{ row.phone }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="type">
                  <RadioGroup v-model="editCompany.type" v-if="editIndex === index">
                    <Radio :label="0">代理商</Radio>
                    <Radio :label="1">供应商</Radio>
                  </RadioGroup>
                  <span v-else>{{ row.type_desc }}</span>
                </template>
                <template slot-scope="{ row , index}" slot="facility">
                    <div v-if="userInfo.isSuperAdmin">
                        <Input v-if="editIndex === index" type="text" v-model="editCompany.facility" />
                        <span v-else>{{ row.facility }}</span>
                    </div>
                    <div v-else>
                        <span>{{ row.facility }}</span>
                    </div>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                    <div v-if="editIndex === index">
                        <Button @click="handleSave(row, index)" type="primary" style="margin-right: 6px">保存</Button>
                        <Button @click="editIndex = -1">取消</Button>
                    </div>
                    <div v-else>
                        <Button type="primary" size="small" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                        <Button type="error"  size="small" style="margin-right: 5px" @click="showDeleteId(row)">删除</Button>
                        <Button type="success"  size="small" style="margin-right: 5px" @click="getCouponCategories(row)">获取券分类</Button>

                      <!--                        <router-link :to="'/pages/companies_transfer_deposit/'+row.id" v-if="userInfo.company_id != row.id">-->
<!--                            <Button type="primary" size="small" style="margin-right: 5px">转账</Button>-->
<!--                        </router-link>-->
<!--                        <router-link :to="'/pages/companies_add_balance/'+row.id" v-if="userInfo.isSuperAdmin">-->
<!--                            <Button type="primary" size="small" style="margin: 5px">超级管理直接加款</Button>-->
<!--                        </router-link>-->
                    </div>
                </template>
            </Table>
        </div>
<!--        <expandRow style="display: none"></expandRow>-->
        <Modal v-model="deleteModal" title="删除确认" @on-ok="handleDelete(row)">
          <p>您确定要删除这个数据吗？</p>
          <p>公司: {{ deleteObj.company_name }}</p>
          <p>编码: {{ deleteObj.code }}</p>
        </Modal>
    </div>
</template>
<script>
import { mapState } from "vuex";
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            deleteObj: {},
            deleteModal: false,
            loading: false,
            companies: null,
            error: null,
            editCompany: {
                phone: '',
                facility: '', company_name: '', type: 0, contact: ''
            },
            columns1: [
                /*  { title: "时间", key: "updated_at", align: "center", }, */
                /* { align: "center", title: "ID", key: "id", width: 80 }, */
                { align: "center", title: "公司编码", slot: "code", minWidth: 80 },
                { align: "center", title: "公司名称", slot: 'company_name', minWidth: 100 },
                { align: "center", title: "联系人", slot: 'contact', minWidth: 100 },
                { align: "center", title: "联系电话", minWidth: 150, slot: 'phone' },
                { align: "center", title: "类型", slot: 'type', minWidth: 80 },
                { title: "操作", slot: "action", minWidth: 300, align: "center", },
            ],
            editIndex: -1,
        };
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo", 'balance', 'companyInfo'
    ]),
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        handleEdit (row, index) {
            this.editId = row.id;
            this.editIndex = index;
            this.editCompany = row;
            console.log('editCompany=>', this.editCompany);
        },
        handleSave (row, index) {
            console.log("enter row", row);
            console.log("enter index", index);
            this.editIndex = -1;

            this.loading = true;
            this.axios.patch('company/' + this.editId, this.editCompany).then((res) => {
                console.log("role/edit res=>", res);
                console.log("修改返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    row = this.editCompany;
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
                this.fetchData();
            });
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("company").then((res) => {
                console.log("res", res);
                this.companies = res.data.companies;
                this.loading = false;
            });
        },
        /**
         * 显示删除 openid
         * @param {*} data
         */
        showDeleteId (data) {
          console.log('将要删除的对象=>', data);
          this.deleteModal = true;
          this.deleteObj = data;
          console.log('将要删除的对象=>', this.deleteObj);
        },
        /**
         * 删除操作
         */
        handleDelete () {
          this.loading = true;
          this.axios.get('company/del/' + this.deleteObj.id).then((res) => {
            console.log("删除返回res", res);
            this.loading = false;
            if (res.data.status === 200 ) {
              this.$Message.success("删除成功");
              let index = -1;//这里不能设置0，因为数组索引是从0开始的
              for (let i = 0; i < this.companies.length; i++) {
                if (this.companies[i].id === this.deleteObj.id) {
                  index = i;
                  break
                }
              }
              if (index >= 0) {
                this.companies.splice(index, 1);
              }
            } else {
              this.$Message.error(res.data.msg);
            }
          });
        },
        getCouponCategories(row){
          this.axios.get("couponCategories/getCouponCategories/" + row.id).then((res) => {
            if (res.data.status === 200) {
              this.$Message.success("获取成功");
            } else {
              this.$Message.error(res.data.msg);
            }
          });
        },
    },
};
</script>
