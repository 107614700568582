<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-04-08 11:07:44
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="coupon" :model="coupon" :rules="ruleValidate" label-position="right" :label-width="200" v-if="coupon">
        <FormItem label="客户ID" prop="gn_id">
          <Input v-model="coupon.gn_id" placeholder="请输入客户ID"></Input>
        </FormItem>
        <FormItem label="三方订单号" prop="box_order_no">
          <Input v-model="coupon.box_order_no" placeholder="请输入三方订单号"></Input>
        </FormItem>
        <FormItem label="公司名称" prop="company">
          <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies" :checkObjName="checkObjName"></dropdown>
        </FormItem>
        <FormItem label="券分类" prop="category_id">
          <Select v-model="coupon.category_id">
            <Option v-for="category in categories" :value="category.category_id" :key="category.category_id">{{ category.title }}</Option>
          </Select>
        </FormItem>
        <FormItem label="批次号" prop="batch_no">
            <Input v-model="coupon.batch_no" placeholder="请输入批次号"></Input>
        </FormItem>
        <FormItem label="券码" prop="voucher">
          <Input v-model="coupon.voucher" placeholder="请输入券码"></Input>
        </FormItem>
        <FormItem label="别名" prop="alias">
          <Input v-model="coupon.alias" placeholder="请输入别名"></Input>
        </FormItem>
        <FormItem label="手机号" prop="business_id">
          <Input v-model="coupon.business_id" placeholder="请输入手机号"></Input>
        </FormItem>
        <FormItem label="券面值" prop="face_value">
          <Input v-model="coupon.face_value" placeholder="请输入券面值"></Input>
        </FormItem>
        <FormItem label="类型" prop="cou_category">
          <RadioGroup v-model="coupon.cou_category">
            <Radio :label="10">满减券</Radio>
            <Radio :label="40">折扣券</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="折扣率" prop="cou_discount_value">
          <Input v-model="coupon.cou_discount_value" placeholder="请输入折扣率"></Input>
        </FormItem>
        <FormItem label="折扣展示" prop="cou_discount_value_str">
          <Input v-model="coupon.cou_discount_value_str" placeholder="请输入折扣展示"></Input>
        </FormItem>
        <FormItem label="可用开始时间" prop="start">
          <Input v-model="coupon.start" placeholder="请输入可用开始时间"></Input>
        </FormItem>
        <FormItem label="可用结束时间" prop="end">
          <Input v-model="coupon.end" placeholder="请输入可用结束时间"></Input>
        </FormItem>
        <FormItem label="券图片路径" prop="img">
          <img :src="coupon.img" alt="">
        </FormItem>
        <FormItem label="券使用说明" prop="declare">
          {{ coupon.declare }}
        </FormItem>
        <FormItem label="状态" prop="status_desc">
          <Input v-model="coupon.status_desc" placeholder="请输入状态"></Input>
        </FormItem>
        <FormItem label="使用时间" prop="use_time">
          <Input v-model="coupon.use_time" placeholder="请输入使用时间"></Input>
        </FormItem>
        <FormItem label="核销店铺" prop="use_shop_name">
          <Input v-model="coupon.use_shop_name" placeholder="请输入核销店铺"></Input>
        </FormItem>
        <FormItem label="核销店铺编码" prop="use_shop_code">
          <Input v-model="coupon.use_shop_code" placeholder="请输入核销店铺编码"></Input>
        </FormItem>
        <FormItem label="核销金额" prop="end">
          <Input v-model="coupon.use_amount" placeholder="请输入核销金额"></Input>
        </FormItem>
        <FormItem label="生成券时间" prop="end">
          <Input v-model="coupon.create_time" placeholder="请输入生成券时间"></Input>
        </FormItem>
        <FormItem label="订单明细" prop="goods">
          {{ coupon.goods }}
        </FormItem>
        <FormItem>
<!--            <Button ref="but_submit" type="primary" @click="formHandleSubmit('member')" :disabled="butDisabled">提交</Button>-->
        </FormItem>
    </Form>
</template>
<script>
import Dropdown from '../member/components/dropdown.vue'
export default {
    props: {
        coupon: Object,
        companies: Array,
        categories: Array,
        checkObjName: String,
    },
    data () {
        return {
            butDisabled: false,
            ruleValidate: {
                phone: [
                    { required: true, message: "手机号码不能为空", trigger: "blur", },
                ],
            },
        };
    },
    components: {
      Dropdown,
    },
    methods: {
        formHandleSubmit: function (name) {
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.member);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        dropDownClick(e) {
          this.coupon.company_id = e.id;
        }
    },
};
</script>
