<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-04-02 13:26:25
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\edit_roles.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>用户管理</BreadcrumbItem>
            <BreadcrumbItem>编辑用户角色</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form :model="user" label-position="right" :label-width="200">
            <FormItem label="用户名字" prop="name">
                <Input v-model="user.name" placeholder="请输入角色名字" disabled></Input>
            </FormItem>
            <FormItem label="能力" prop="ability">
                <CheckboxGroup v-model="selectedRoles">
                    <Checkbox :label="role.id" v-for="(role, index) of roles" :key="index" style="width: 150px;">
                        {{ role.name}}
                    </Checkbox>
                </CheckboxGroup>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="handleSubmit" :disabled="butDisabled">提交</Button>
            </FormItem>
        </Form>
    </div>
</template>
<script>
import { mapState } from "vuex";
import axios from 'axios';
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            id: -1,
            is11: '0',
            user: {},
            loading: false,
            error: null,
            roles: [],
            selectedRoles: [],
            butDisabled: false
        }
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo"
    ]),
    created () {
        console.log("created")
        console.log(this.$route.params);
        this.id = this.$route.params.id;
        axios.get('roles').then(res => {
            this.roles = res.data.roles;
        });
        axios.get('users/' + this.id).then(res => {
            this.user = res.data.user;
            this.selectedRoles = [];
            if ('roles' in this.user) {
                this.user.roles.forEach(per => {
                    this.selectedRoles.push(per.id);
                });
            }
        });
    },
    watch: {
        $route (to, from) {
            // 对路由变化作出响应...
            console.log("to", to)
            console.log("from", from)
        }
    },
    methods: {
        handleSubmit () {
            // 验证通过
            console.log("验证通过", this.selectedRoles);
            this.loading = true; this.error = null;
            this.butDisabled = true;
            setTimeout(() => {
                this.butDisabled = false;
            }, 3000);
            this.axios.patch("users/" + this.id + "/roles", { id: this.selectedRoles }).then((res) => {
                console.log("修改返回res", res)
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success('修改成功');
                    setTimeout(() => {
                        this.$router.push("/pages/users");
                    }, 2000);
                } else {
                    for (let key in res.data.errors) {
                        this.$Message.error(res.data.errors[key][0]);
                    }
                }
            })
        }
    },
};
</script>
