<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-27 14:18:44
 * @FilePath: \qyOut\src\pages\user\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/member_create" style="float:right;">
                添加客户
            </router-link>
            <BreadcrumbItem>客户管理</BreadcrumbItem>
            <BreadcrumbItem>客户列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="selectForm" :model="selectForm" inline>
          <FormItem prop="phone">
            <Input v-model="selectForm.phone" placeholder="手机号"></Input>
          </FormItem>
          <FormItem prop="date">
            <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="goSelect()">查询</Button>
          </FormItem>
        </Form>
        <Table border stripe v-if="members" :columns="columns1" :data="members">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.company_name }}
            </template>
            <template slot-scope="{ row }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="statusOnChange(row)">修改状态</Button>
            </template>
        </Table>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
        <Modal v-model="shelvesModal" title="修改客户状态" @on-ok="onDownShelves()">
          <p>
            <RadioGroup v-model="rowObj.status_id">
                <Radio :label="0">正常</Radio>
                <Radio :label="1">禁用</Radio>
            </RadioGroup>
          </p>
        </Modal>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            shelvesModal: false,
            loading: false,
            members: null,
            error: null,
            rowObj: {
              status_id:0
            },
            pages: {
              total: 0,
              path: '',
              links: []
            },
            selectForm: {
              phone: '',
            },
            columns1: [
                /* { title: "时间", key: "updated_at", align: "center" }, */
                { title: "ID", key: "id", align: "center", minWidth: 150 },
                { title: "客户编码", key: "gn_id", align: "center", minWidth: 250 },
                { title: "手机", key: "phone", align: "center", minWidth: 150 },
                { title: "公司名称", align: "center", slot: "company_name", minWidth: 100 },
                { title: "创建时间", key: "created_at", align: "center", minWidth: 250 },
                { title: "状态", key: "status_desc", align: "center", minWidth: 80 },
                {
                    title: "操作",
                    slot: "action",
                    width: 250,
                    align: "center",
                },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        goSelect: function () {
          console.log(this.selectForm);
          this.fetchData('member/list', this.selectForm);
        },
        dateOnChange (date) {
          console.log('date', date);
          this.selectForm.start_date = date[0];
          this.selectForm.end_date = date[1];
          this.fetchData('member/list', this.selectForm);
        },
        pageOnChange: function (page) {
          this.pages.links.forEach(link => {
            if (link.label == page) {
              this.fetchData(link.url);
              return;
            }
          })
          let url = this.pages.path.indexOf('?') > 0
              ? this.pages.path + "&page=" + page
              : this.pages.path + "?page=" + page;
          console.log('url=>', url);
          this.fetchData(url, this.selectForm);
        },
        statusOnChange: function (row) {
            this.rowObj = row;
            this.shelvesModal = true;
        },
        onDownShelves: function () {
          let data = {
            status_id: this.rowObj.status_id
          }
          this.axios.post('member/editStatus/' + this.rowObj.id, data).then(res => {
            if (res.data.status === 200) {
              this.$Message.success('修改状态成功');
            } else {
              this.$Message.error(res.data.msg);
            }
            this.fetchData();
          })
        },
        fetchData (url = "member/list", params = {}) {
            this.error = null;
            this.loading = true;
            let data = {};
            for (let key in params) {
              console.log('param[key],', params[key]);
              if (params[key] === undefined || params[key].length == 0) {
                delete params[key];
              }
            }
            data.params = params;
            this.axios.get(url, data).then((res) => {
                console.log("res", res);
                this.members = res.data.member;
                this.pages = res.data.meta;
                this.loading = false;
            });
        },
    },
};
</script>
