var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{style:({ margin: '20px 10px' })},[_c('router-link',{staticStyle:{"float":"right"},attrs:{"to":"/pages/user_create"}},[_vm._v(" 新建用户 ")]),_c('BreadcrumbItem',[_vm._v("用户管理")]),_c('BreadcrumbItem',[_vm._v("用户列表")])],1),_c('loading',{attrs:{"loading":_vm.loading,"error":_vm.error}}),(_vm.users)?_c('Table',{attrs:{"border":"","stripe":"","columns":_vm.columns1,"data":_vm.users},scopedSlots:_vm._u([{key:"company_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.company.company_name)+" ")]}},{key:"company_short_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.company.short_name)+" ")]}},{key:"roles",fn:function(ref){
var row = ref.row;
return [_c('List',{staticStyle:{"margin":"3px"},attrs:{"border":""}},_vm._l((row.roles),function(role,ind){return _c('ListItem',{key:ind},[_vm._v(" "+_vm._s(role.name)+" ")])}),1)]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.show(row, index)}}},[_vm._v("修改")]),_c('router-link',{attrs:{"type":"primary","to":'/pages/user_edit_roles/'+row.id}},[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"}},[_vm._v("设置权限")])],1),_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.goOpenidList(row)}}},[_vm._v("Openid")])]}}],null,false,1124793895)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }