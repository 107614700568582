<!--
 * @Author: 陈远锋
 * @Date: 2022-03-09 09:24:22
 * @LastEditTime: 2022-03-09 10:07:26
 * @FilePath: \qyOut\src\pages\loading.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Col class="demo-spin-col" v-if="loading">
        <Spin fix>
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
        </Col>
        <Alert v-if="error" type="error" style="margin: 0 20px 10px 20px;">
            {{ error }}
        </Alert>
    </div>
</template>
<script>
export default {
    props: {
        loading: Boolean,
        error: String
    }
}
</script>
<style>
.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.demo-spin-col {
    height: 100px;
    position: relative;
    border: 1px solid #eee;
}
</style>
