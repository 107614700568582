<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-22 13:55:53
 * @FilePath: \qyOut\src\pages\goods_type\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/goods_type_create" style="float:right;">
                新增商品类型
            </router-link>
            <div style="float:right;"></div>
            <BreadcrumbItem>商品类型</BreadcrumbItem>
            <BreadcrumbItem>商品类型列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <div v-if="post" class="content">
            <Table border stripe :columns="columns1" :data="post">
                <template slot-scope="{ row }" slot="name">
                    <strong>{{ row.name }}</strong>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
                    <Button type="error" size="small" style="margin-right: 5px" @click="showDeleteModal(row)">删除</Button>
                </template>
            </Table>
        </div>
        <Modal v-model="deleteModal" title="删除确认" @on-ok="handleDelete()">
          <p>您确定要删除这个商品类型吗？</p>
          <p>编码: {{ deleteObj.code }}</p>
          <p>名称: {{ deleteObj.name }}</p>
        </Modal>
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            post: null,
            error: null,
            deleteObj: {},
            deleteModal: false,
            columns1: [
                { title: "时间", key: "updated_at", align: "center", width: 200 },
                /* { align: "center", title: "主键", key: "id", }, */
                { align: "center", title: "编码", key: "code", width: 120 },
                {
                    align: "center",
                    title: "名称",
                    key: "name", width: 200
                },
                {
                    align: "center",
                    title: "状态",
                    key: "status_desc", width: 100
                },
                {
                    align: "center",
                    title: "类型",
                    key: "type_desc", width: 100
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            //this.$router.push("/pages/goods_type_list");
            this.$router.push("/pages/goods_type_edit/" + row.id);
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("goodsTypes/list").then((res) => {
                console.log("goodsTypes/list res", res);
                this.post = res.data.goods_types;
                this.loading = false;
            }).catch(error => {
                console.log('goodsTypes/list error =>', error);
            });
        },
        showDeleteModal (data) {
          console.log('将要删除的对象=>', data);
          this.deleteModal = true;
          this.deleteObj = data;
        },
        handleDelete () {
          this.axios.get('goodsTypes/del/' + this.deleteObj.id).then((res) => {
            console.log("删除返回res", res);
            this.loading = false;
            if (res.data.status === 200) {
              this.$Message.success("删除成功");
              let index = -1;//这里不能设置0，因为数组索引是从0开始的
              for (let i = 0; i < this.post.length; i++) {
                if (this.post[i].id === this.deleteObj.id) {
                  index = i;
                  break
                }
              }
              if (index >= 0) {
                this.post.splice(index, 1);
              }
            } else {
              this.$Message.error(res.data.msg);
            }
          });
        },
    },
};
</script>
