<!--
 * @Author: 陈远锋
 * @Date: 2022-03-04 13:11:06
 * @LastEditTime: 2023-01-04 10:28:32
 * @FilePath: \src\Login.vue
 * @Description: Do not edit
-->
<template>
    <div class="page-account">
        <div class="container containerSamll">
            <div class="swiper-container swiperPross">
                <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
                    <div class="swiperPic swiper-slide" style="width: 660px;">
                        <img src="https://static.zjgnwl.com/img/202212/12-30.png" style="object-fit: fill;">
                    </div>
                </div>
            </div>
            <div class="index_from page-account-container">
                <div class="page-account-top ">
                    <div class="login_tab flex">
                        <div class="login_tab_item active_tab">光凝券平台</div>
                    </div>
                </div>
                <img :src="qrCode" />
<!--              <Form ref="formItem" :rules="ruleInline" :model="formItem" :label-width="80">-->
<!--                <FormItem label="账号" prop="account">-->
<!--                  <Input v-model="formItem.account" placeholder="请输入账号">-->
<!--                    <Icon type="ios-person-outline" slot="prepend"></Icon>-->
<!--                  </Input>-->
<!--                </FormItem>-->
<!--                <FormItem label="密码" prop="password">-->
<!--                  <Input type="password" v-model="formItem.password" placeholder="请输入密码">-->
<!--                    <Icon type="ios-lock-outline" slot="prepend"></Icon>-->
<!--                  </Input>-->
<!--                </FormItem>-->
<!--                <FormItem>-->
<!--                  <Button ref="button" type="primary" @click="handleSubmit('formItem')">登陆</Button>-->
<!--                </FormItem>-->
<!--              </Form>-->
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    data () {
        return {
            formItem: {
                account: "",
                password: "",
            },
            ruleInline: {
                account: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    {
                        type: "string",
                        min: 6,
                        message: "密码至少是6位",
                        trigger: "blur",
                    },
                ],
            },
            uuid: '',
            qrCode: ''
        };
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "isLogin",
    ]),
    created () {
        console.log("进入 Login.vue created");

        /*扫描二维码*/
        this.axios.get("wechat/getLogin?oa_id=gn").then(res => {
            console.log('res=>', res);
            if (res.status === 200) {
                this.uuid = res.data.uuid;
                this.qrCode = res.data.qrCode;
                console.log('this.qrCode', this.qrCode);
            } else {
                this.$Message.error(res.data.smg);
            }
        })
        setTimeout(() => {
            this.checkIsLogin()
        }, 3000);
    },
    methods: {
        checkIsLogin () {
            this.axios.get("wechat/postLogin", { params: { uuid: this.uuid } }).then(res => {
                if (res.data.status === 200) {
                    console.log("ok");

                    let isSuperAdmin = false;
                    res.data.user.roles.forEach(role => {
                        if (role.name === '超级管理员') {
                            isSuperAdmin = true;
                        }
                    })
                    res.data.user.isSuperAdmin = isSuperAdmin;
                    this.$store.commit("LOGIN", res.data.user.token);
                    this.$store.commit("SETUID", res.data.user.id);
                    this.$store.commit("UPDATE_USERINFO", res.data.user);
                    //王莹莹 2023-04-13 暂无数据
                    //this.$store.commit("UPDATE_BALANCE", res.data.user.company.balance);
                    //this.$store.commit("UPDATE_VERSION", res.data.user.company.version);
                    this.$store.commit("UPDATE_COMPANY_INFO", res.data.user.company);
                    axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.user.token;
                    this.$router.push("/pages/index");
                    localStorage.setItem('menu', JSON.stringify(res.data.user.permissions));
                } else {
                    setTimeout(() => {
                        this.checkIsLogin()
                    }, 3000);
                }
            })
        },
        handleSubmit (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.axios.post("user/login", this.formItem).then((res) => {
                        console.log("login res", res);
                        if (res.data.status === 200) {
                            console.log("ok");

                            let isSuperAdmin = false;
                            res.data.user.roles.forEach(role => {
                                if (role.name === '超级管理员') {
                                    isSuperAdmin = true;
                                }
                            })
                            res.data.user.isSuperAdmin = isSuperAdmin;
                            this.$store.commit("LOGIN", res.data.user.token);
                            this.$store.commit("SETUID", res.data.user.id);
                            this.$store.commit("UPDATE_USERINFO", res.data.user);
                            // this.$store.commit("UPDATE_BALANCE", res.data.user.company.balance);
                            // this.$store.commit("UPDATE_VERSION", res.data.user.company.version);
                            // this.$store.commit("UPDATE_COMPANY_INFO", res.data.user.company);
                            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.user.token;
                            this.$router.push("/pages/index");
                            localStorage.setItem('menu', JSON.stringify(res.data.user.permissions));
                        } else if (res.data.status === 404) {
                            this.$Message.error("账号错误!");
                        } else {
                            console.log();
                            // this.$Message.error(res.data.errors.error);
                            this.$Message.error(res.data.msg);
                        }
                    })
                        .catch((error) => {
                            console.log("error=>", error);
                            this.$Message.error(error);
                        });
                    //});
                } else {
                    this.$Message.error("error!");
                }
            });
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
.page-account[data-v-048ee217] {
    display: flex;
}
@media (min-width: 768px) {
    .page-account {
        background-image: url("https://static.zjgnwl.com/img/202212/bg12-30.png");
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
    }
    .page-account {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        overflow: auto;
    }
}
.swiperPic,
.swiperPic img,
.swiperPross {
    width: 660px;
    height: 100%;
}
.swiperPross {
    border-radius: 6px 0 0 6px;
}
.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}
.page-account-container {
    border-radius: 0 6px 6px 0;
}
.index_from {
    padding: 0 40px 0 40px;
    box-sizing: border-box;
}
@media (min-width: 768px) {
    .page-account-container {
        padding: 32px 0 24px 0;
    }

    .page-account-container {
        flex: 1 1 auto;
        padding: 32px 0;
        text-align: center;
        width: 435px;
        margin: 0 auto;
    }
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
.containerSamll {
    background: #fff !important;
}
.container {
    height: 660px !important;
    padding: 0 !important;
    border-radius: 6px;
    z-index: 1;
    display: flex;
}
.login_tab {
    font-size: 28px;
    padding: 80px 0 20px;
    justify-content: center;
}
.flex {
    display: flex;
}
.active_tab {
    color: #1495ed;
    font-weight: 600;
}
.login_tab_item {
    width: 55%;
    text-align: center;
    cursor: pointer;
}
/*body {*/
/*	background-image: url('https://static.zjgnwl.com/mall-pro/admin/view_admin/img/bg.77d678be.jpg');*/
/*	background-repeat: no-repeat;*/
/*	background-size: cover;*/
/*	background-position: center;*/
/*	background-attachment: fixed;*/
/*	-webkit-background-size: cover;*/
/*	-moz-background-size: cover;*/
/*	-o-background-size: cover;*/
/*}*/
</style>

