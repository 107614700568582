<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-04-08 11:07:44
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="purchase" :model="purchase" :rules="ruleValidate" label-position="right" :label-width="200" v-if="purchase">
<!--        <FormItem label="公司名称" prop="company">-->
<!--          <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies" :checkObjName="checkObjName"></dropdown>-->
<!--        </FormItem>-->
        <FormItem label="备注" prop="reason">
            <Input v-model="purchase.reason" placeholder="请输入备注"></Input>
        </FormItem>
        <FormItem label="入库单商品" prop="purchase_goods">
          <Button style="margin-left: 8px" @click="addGoods()">添加</Button>
          <List>
            <ListItem v-for="(good, ind) of goods" :key="ind" style="border: 1px solid red; margin-top: 6px; padding: 0 10px; border-radius: 10px;">
              <List>
                <ListItem>
                  <div style="display: flex;">
                    <label style="width: 100px; text-align:right; padding-right: 10px;">分类名称</label>
                    <Input v-model="good.alias" placeholder="请输入分类名称" style="width: 200px;">
                    </Input>
                  </div>
                  <div style="display: flex; margin-top: 6px; width: 100%">
                    <label style="width: 100px; text-align:right; padding-right: 10px;">数量</label>
                    <Input v-model="good.num" placeholder="请输入数量" style="width: 200px;">
                    </Input>
                  </div>
                  <div style="display: flex; margin-top: 6px; width: 100%">
                    <label style="width: 100px; text-align:right; padding-right: 10px;">进价</label>
                    <Input v-model="good.bid" placeholder="请输入进价" style="width: 200px;">
                    </Input>
                  </div>
                  <button type="button" style="float:right; padding: 0 10px; width: 150px; " @click="delBoxGoods(ind)">删除</button>
                </ListItem>
              </List>
            </ListItem>
          </List>
        </FormItem>
        <FormItem>
            <Button ref="but_submit" type="primary" @click="formHandleSubmit('purchase')" :disabled="butDisabled">提交</Button>
        </FormItem>
    </Form>
</template>
<script>
// import Dropdown from '../member/components/dropdown.vue'
export default {
    props: {
        purchase: Object,
        user_types: Array,
        companies: Array,
        checkObjName: String,
    },
    data () {
        return {
            goods: [],
            butDisabled: false,
            ruleValidate: {
            },
        };
    },
    components: {
      // Dropdown,
    },
    watch:{
      purchase(){
        if (this.purchase.purchase_goods) {
          this.goods= this.purchase.purchase_goods;
        }
      }
    },
    methods: {
        formHandleSubmit: function (name) {
            // if (!this.purchase.company_id){
            //   this.$Message.error("公司不能为空!");
            //   return;
            // }
            if (this.goods.length === 0){
              this.$Message.error("商品不能为空!");
              return;
            }
            let purchase_goods = [];
            try {
              this.goods.forEach((goods_) => {
                if (!goods_.bid || !goods_.num || !goods_.alias){
                  throw 'error';
                }else{
                  purchase_goods.push(goods_);
                }
              });
            } catch (e) {
              this.$Message.error("请完整填写商品信息!");
              return;
            }
            this.purchase.purchase_goods = purchase_goods;
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.purchase);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        dropDownClick(e) {
          this.purchase.company_id = e.id;
        },
        addGoods: function () {
          this.goods.push({});
        },
        delBoxGoods (ind) {
          let delIndex = -1;
          for (let i = 0; i < this.goods.length; i++) {
            console.log(this.goods[i]);
            if (i === ind) {
              delIndex = i;
            }
          }
          if (delIndex > -1) {
            this.goods.splice(delIndex, 1);
          }
        },
    },
};
</script>
