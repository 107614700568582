<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-27 14:41:18
 * @FilePath: \qyOut\src\pages\agentGoods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>代理商商品管理</BreadcrumbItem>
            <BreadcrumbItem>代理商商品列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Table border stripe ref="selection" :columns="columns1" :data="agentGoods" v-if="agentGoods">
          <template slot-scope="{ row }" slot="title">
            {{ row.coupon_categories.title }}
          </template>
          <template slot-scope="{ row }" slot="alias">
            {{ row.coupon_categories.alias }}
          </template>
          <template slot-scope="{ row }" slot="face_value">
            {{ row.coupon_categories.face_value }}
          </template>
          <template slot-scope="{ row }" slot="category_desc">
            {{ row.coupon_categories.category_desc }}
          </template>
          <template slot-scope="{ row }" slot="biz_type_desc">
            {{ row.coupon_categories.biz_type_desc }}
          </template>
        </Table>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            agentGoods: [],
            error: null,
            baseGoods: [],
            dis: false,
            columns1: [
                /* { title: "ID", key: "id", align: "center", width: 100 }, */
                {
                    title: "分类ID",
                    key: "category_id",
                    align: "center",
                    minWidth: 80,
                },
                { title: "库存", key: "qty", align: "center", minWidth: 60 },
                { title: "名称", slot: "title", align: "center", minWidth: 100 },
                { title: "别名", slot: "alias", align: "center", minWidth: 100 },
                {
                    title: "面额",
                    slot: "face_value",
                    align: "center",
                    minWidth: 80
                },
                {
                  title: "类型",
                  slot: "category_desc",
                  align: "center",
                  minWidth: 80
                },
                {
                    title: "使用类型",
                    slot: "biz_type_desc",
                    align: "center",
                    minWidth: 80
                },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        fetchData() {
            this.error = null;
            this.loading = true;
            this.axios.get("agentGoods/list").then((res) => {
                console.log("agentGoods 读取回来的数据=>", res.data);
                this.agentGoods = res.data.agent_goods;
                this.loading = false;
            });
        },
    },
};
</script>
