<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-09 11:30:56
 * @FilePath: \qyOut\src\pages\supplier\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>供应商管理</BreadcrumbItem>
            <BreadcrumbItem>编辑供应商</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :supplier="supplier" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            supplier: {},
            loading: false,
            error: null,
        }
    },
    created () {
        console.log("created")
        this.fetchData()
        console.log(this.$route.params)
    },
    watch: {
        $route (to, from) {
            // 对路由变化作出响应...
            console.log("to", to)
            console.log("from", from)
        }
    },
    methods: {
        handleSubmit (supplier) {
            // 验证通过
            console.log("验证通过", supplier);
            this.loading = true; this.error = null
            this.axios
                .post("supplier/edit/" + supplier.id, supplier)
                .then((res) => {
                    console.log("修改返回res", res)
                    this.loading = false;
                    if (res.data.status === 200) {
                        this.$Message.success('修改成功');
                        setTimeout(() => {
                            this.$router.push("/pages/supplier_list");
                        }, 2000);
                    } else {
                        for (let key in res.data.errors) {
                            this.$Message.error(res.data.errors[key][0]);
                        }
                    }
                })
        },

        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios
                .get("supplier/detail/" + this.$route.params.id)
                .then((res) => {
                    console.log("res", res);
                    this.supplier = res.data.data;
                    //this.supplier.is_sale = String(this.supplier.is_sale);
                    //this.supplier.status_id = String(this.supplier.status_id);
                    // this.supplier.supplier_type_id = String(this.supplier.supplier_type_id);
                    // this.supplier.supplier_id = res.data.supplier.id;
                    this.loading = false;
                });

        },
    },
};
</script>
