<!--
 * @Author: 陈远锋
 * @Date: 2022-03-11 16:31:36
 * @LastEditTime: 2022-04-08 13:12:31
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\coupon_expand.vue
 * @Description: Do not edit
-->
<template>
    <div class="expand-row">
        <Table border stripe :columns="columns2" :data="row">
            <!-- <template slot-scope="{ row }" slot="goods_name">
                {{row.agent_goods.goods.goods_name}}
            </template> -->
			<template slot-scope="{ row, index }" slot="action">
				<Button v-if="row.status_desc === '已核销'" type="primary" size="small" style="margin: 5px" @click="cancelVerifyAlert(row,index)">反核销</Button>
				<Button v-if="(row.status_desc != '已关闭' && row.status_desc != '已退款')" type="primary" size="small" style="margin: 5px" @click="verifyCouponAlert(row,index)">核销</Button>
			</template>
        </Table>
		<Modal v-model="modelSwitch" title="请确认您的操作！" @on-ok="cancelVerify" @on-cancel="modelCancel">
			<p>您确定要反核销这个券码吗？</p>
			<p>券码: {{ cancelObj.code }}</p>
			<p>订单号: {{ cancelObj.son_order_no }}</p>
		</Modal>
		<Modal v-model="modelSwitchVerify" title="请确认您的操作！" @on-ok="verifyCoupon" @on-cancel="modelVerify">
			<p>您确定要核销这个券码吗？</p>
			<p>券码: {{ verifyObj.code }}</p>
			<p>订单号: {{ verifyObj.son_order_no }}</p>
		</Modal>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
			modelSwitch: false,
			modelSwitchVerify: false,
			cancelObj: {},
			verifyObj: {},
            tableData: [],
            columns2: [
                { align: "center", title: '时间', key: 'created_at' },
                { align: "center", title: '产品名字', key: "goods_name" },
                { align: "center", title: '券码', key: 'code' },
				{ align: "center", title: '卡号', key: 'card' },{
                    align: "center",
                    title: '价格',
                    key: 'price'
                },
                { align: "cente", title: '生效时间', key: 'effective_time' },
                { align: "center", title: '失效时间', key: 'expires_time' },
                { align: "center", title: '状态', key: 'status_desc' },
				{ align: "center", title: '操作', slot: 'action' }
            ]
        }
    },
	methods: {
		cancelVerifyAlert (order, index) {
			this.modelSwitch = true;
			console.log('order=>', order, 'index=>', index);
			this.cancelObj = order;
		},
		modelCancel: function () {
			console.log('用户取消了模态框');
		},
		cancelVerify: function () {
			this.axios.get('couponsOrders/cancelVerify/' + this.cancelObj.code).then(res => {
				console.log("res =>", res);
				if (res.data.status === 200) {
					this.$Message.success('反核销成功了');
				} else {
					this.$Message.error(res.data.msg);
				}
			})
		},
		verifyCouponAlert (order, index) {
			this.modelSwitchVerify = true;
			console.log('order=>', order, 'index=>', index);
			this.verifyObj = order;
		},
		modelVerify: function () {
			console.log('用户取消了模态框');
		},
		verifyCoupon: function () {
			this.axios.get('couponsOrders/verifyCoupon/' + this.verifyObj.code).then(res => {
				console.log("res =>", res);
				if (res.data.status === 200) {
					this.$Message.success('核销成功了');
				} else {
					this.$Message.error(res.data.msg);
				}
			})
		},
	},
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
}
</style>
