<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-09 11:30:56
 * @FilePath: \qyOut\src\pages\supplier\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>电子券管理</BreadcrumbItem>
            <BreadcrumbItem>券分类详情</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :coupon_categories="coupon_categories" :companies="companies" :checkObjName="checkObjName" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
import axios from "axios";
export default {
    components: { formPage, loading },
    data () {
        return {
            coupon_categories: {},
            loading: false,
            companies: [],
            checkObjName: '',
            error: null,
        }
    },
    created () {
        console.log("edit")
        this.fetchData()
        axios.get("company?type=0").then(res => {
          this.companies = res.data.companies
        })
    },
    watch: {
        $route (to, from) {
            // 对路由变化作出响应...
            console.log("to", to)
            console.log("from", from)
        }
    },
    methods: {
        handleSubmit (purchase) {
            // 验证通过
            console.log("验证通过", purchase);
            this.loading = true; this.error = null
            this.axios
                .post("purchase/edit/" + purchase.id, purchase)
                .then((res) => {
                    console.log("修改返回res", res)
                    this.loading = false;
                    if (res.data.status === 200) {
                        this.$Message.success('修改成功');
                        setTimeout(() => {
                            this.$router.push("/pages/purchases");
                        }, 2000);
                    } else {
                      this.$Message.error(res.data.msg);
                    }
                })
        },

        fetchData () {
            this.error = null;
            this.loading = true;
            this.axios
                .get("couponCategories/show/" + this.$route.params.id)
                .then((res) => {
                    console.log("res", res);
                    this.coupon_categories = res.data.coupon_categories;
                    this.checkObjName = res.data.coupon_categories.company.company_name;
                    this.loading = false;
                });

        },
    },
};
</script>
