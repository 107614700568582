<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-27 14:18:44
 * @FilePath: \qyOut\src\pages\user\list.vue
 * @Description: Do not edit
-->
<template>
  <div>
    <Breadcrumb :style="{ margin: '20px 10px' }">
      <router-link to="/pages/agent_goods_distribute_create" style="float:right;">
        添加分配
      </router-link>
      <BreadcrumbItem>代理商商品管理</BreadcrumbItem>
      <BreadcrumbItem>分配列表</BreadcrumbItem>
    </Breadcrumb>
    <loading :loading="loading" :error="error"></loading>
    <Form ref="selectForm" :model="selectForm" inline>
      <FormItem prop="company">
        <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies" style="width: 250px; margin-right: 10px; margin-left: 10px;"></dropdown>
<!--        :checkObjName="selectForm.company_name"-->
      </FormItem>
      <FormItem prop="status_id">
        <Select v-model="selectForm.status_id" style="width: 100px; margin-right: 10px; margin-left: 10px;">
          <Option :value="0">未通过</Option>
          <Option :value="1">通过</Option>
        </Select>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
      </FormItem>
    </Form>
    <Table border stripe v-if="agent_goods_distribute" :columns="columns1" :data="agent_goods_distribute">
<!--      <template slot-scope="{ row }" slot="company_name">-->
<!--        {{ row.company.company_name }}-->
<!--      </template>-->
      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)" v-if="row.status_id === 0">修改</Button>
        <Button type="success" size="small" style="margin-right: 5px" @click="statusOnChange(row)" v-if="row.status_id === 0">审核通过</Button>
      </template>
    </Table>
    <expandRow style="display: none"></expandRow>
    <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    <Modal v-model="shelvesModal" title="审核通过" @on-ok="onDownShelves()">
      <p>您确定要审核通过这个分配吗？</p>
      <p>编码: {{ rowObj.distribute_no }}</p>
<!--      <p>公司: {{ rowObj.company_name }}</p>-->
    </Modal>
    <Modal v-model="deleteModal" title="删除确认" @on-ok="handleDelete()">
      <p>您确定要删除这个入库单吗？</p>
      <p>单号: {{ deleteObj.purchase_no }}</p>
<!--      <p>公司: {{ deleteObj.company_name }}</p>-->
    </Modal>
  </div>
</template>
<script>
import Dropdown from '../member/components/dropdown.vue'
import loading from "../loading.vue";
import expandRow from '../agentGoodsDistribute/expand.vue';
export default {
  components: { expandRow, loading, Dropdown },
  data () {
    return {
      companies: [],
      categories: null,
      shelvesModal: false,
      loading: false,
      agent_goods_distribute: null,
      error: null,
      deleteObj: {},
      deleteModal: false,
      rowObj: {
        status_id:0
      },
      selectForm: {},
      pages: {
        total: 0
      },
      columns1: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            let goods = params.row.agent_distribute_goods;
            return h(expandRow, {
              props: {
                row: goods
              }
            })
          }
        },
        /* { title: "时间", key: "updated_at", align: "center" }, */
        { title: "编码", key: "distribute_no", align: "center", minWidth: 200 },
        // { title: "公司名称", align: "center", slot: "company_name", minWidth: 80 },
        { title: "备注", key: "remark", align: "center", minWidth: 80 },
        { title: "创建时间", key: "created_at", align: "center", minWidth: 200 },
        { title: "状态", key: "status_desc", align: "center", minWidth: 80 },
        {
          title: "操作",
          slot: "action",
          width: 250,
          align: "center",
        },
      ],
    };
  },
  created () {
    console.log("mounted");
    this.fetchData();
    this.axios.get("company").then((res) => {
      console.log("res", res);
      this.companies = res.data.companies;
      this.loading = false;
    });
    this.axios.get("couponCategories/totalList").then((res) => {
      console.log("res", res);
      this.categories = res.data.coupon_categories;
      this.loading = false;
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchData",
  },
  methods: {
    statusOnChange: function (row) {
      this.rowObj = row;
      // this.rowObj.company_name = row.company.company_name;
      this.shelvesModal = true;
    },
    onDownShelves: function () {
      this.axios.get('agentGoodsDistribute/pass/' + this.rowObj.id).then(res => {
        if (res.data.status === 200) {
          this.$Message.success('审核成功');
        } else {
          this.$Message.error(res.data.msg);
        }
        this.fetchData();
      })
    },
    fetchData (url = 'agentGoodsDistribute/list', params = {}) {
      this.error = null;
      this.loading = true;
      let data = {};
      for (let key in params) {
        console.log('param[key],', params[key]);
        if (params[key] === undefined || params[key].length === 0) {
          delete params[key];
        }
      }
      data.params = params;
      this.axios.get(url, data).then((res) => {
        console.log("res", res);
        this.agent_goods_distribute = res.data.agent_goods_distribute;
        this.pages = res.data.meta;
        this.loading = false;
      });
    },
    show (row, index) {
      console.log("row=>", row.id);
      console.log("index=>", index);
      this.$router.push("/pages/agent_goods_distribute_edit/" + row.id);
    },
    showDeleteModal (data) {
      console.log('将要删除的对象=>', data);
      this.deleteModal = true;
      this.deleteObj = data;
      // this.deleteObj.company_name = data.company.company_name;
    },
    handleDelete () {
      this.axios.get('purchase/del/' + this.deleteObj.id).then((res) => {
        console.log("删除返回res", res);
        this.loading = false;
        if (res.data.status === 200) {
          this.$Message.success("删除成功");
          let index = -1;//这里不能设置0，因为数组索引是从0开始的
          for (let i = 0; i < this.purchases.length; i++) {
            if (this.purchases[i].id === this.deleteObj.id) {
              index = i;
              break
            }
          }
          if (index >= 0) {
            this.purchases.splice(index, 1);
          }
        } else {
          this.$Message.error(res.data.msg);
        }
      });
    },
    handleSubmit: function (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.fetchData('agentGoodsDistribute/list', this.selectForm);
        } else {
          this.$Message.error('出错了');
        }
      })
    },
    pageOnChange: function (page) {
      this.pages.links.forEach(link => {
        if (link.label == page) {
          this.fetchData(link.url);
          return;
        }
      })
    },
    dropDownClick(e) {
      this.selectForm.company_id = e.id;
    },
  },
};
</script>
