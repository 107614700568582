var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{style:({ margin: '20px 10px' })},[_c('BreadcrumbItem',[_vm._v("代理商商品管理")]),_c('BreadcrumbItem',[_vm._v("代理商商品列表")])],1),_c('loading',{attrs:{"loading":_vm.loading,"error":_vm.error}}),(_vm.agentGoods)?_c('Table',{ref:"selection",attrs:{"border":"","stripe":"","columns":_vm.columns1,"data":_vm.agentGoods},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.title)+" ")]}},{key:"alias",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.alias)+" ")]}},{key:"face_value",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.face_value)+" ")]}},{key:"category_desc",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.category_desc)+" ")]}},{key:"biz_type_desc",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_categories.biz_type_desc)+" ")]}}],null,false,620418747)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }