<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-04-08 11:07:44
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="user" :model="user" :rules="ruleValidate" label-position="right" :label-width="200" v-if="user">
        <FormItem label="公司名称" prop="company">
            <Select v-model="user.company_id">
                <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.company_name }}</Option>
            </Select>
        </FormItem>
        <FormItem label="账号" prop="account">
            <Input v-model="user.account" placeholder="请输入账号"></Input>
        </FormItem>
        <FormItem label="密码" prop="password">
            <Input v-model="user.password" placeholder="不修改密码请留空"></Input>
        </FormItem>
        <FormItem label="手机号码" prop="phone">
            <Input v-model="user.phone" placeholder="Enter something..."></Input>
        </FormItem>

        <FormItem label="联系人" prop="name">
            <Input v-model="user.name" placeholder="Enter something..."></Input>
        </FormItem>

        <FormItem label="IP限制" prop="ips" v-if="isSuperAdmin">

            <Input v-model="user.ips" placeholder="输入内容..."></Input>
        </FormItem>
        <FormItem label="密钥" prop="key" v-if="isSuperAdmin">
            <Input v-model="user.key" placeholder="输入内容..."></Input>
        </FormItem>
        <FormItem>
            <Button ref="but_submit" type="primary" @click="formHandleSubmit('user')" :disabled="butDisabled">提交</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        isSuperAdmin: Boolean,	// 登陆的用户信息
        user: Object,
        user_types: Array,
        companies: Array
    },
    data () {
        return {
            butDisabled: false,
            ruleValidate: {
                account: [
                    { required: true, message: "账号不能为空", trigger: "blur" },
                ],
                phone: [
                    { required: true, message: "手机号码不能为空", trigger: "blur", },
                ],
                discount: [
                    { required: true, message: "折扣不能为空", trigger: "blur" },
                ],
                name: [
                    { required: true, message: "姓名不能为空", trigger: "blur" },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.user);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
