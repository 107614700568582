<!--
 * @Author: 陈远锋
 * @Date: 2022-04-21 14:47:35
 * @LastEditTime: 2022-06-27 14:11:57
 * @FilePath: \qyOut\src\pages\goods\box_goods_edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/goods_list" style="float:right;">
                基础列表
            </router-link>
            <BreadcrumbItem>基础商品</BreadcrumbItem>
            <BreadcrumbItem>编辑权益包产品</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="goods" :model="goods" :rules="ruleValidate" label-position="right" :label-width="200" v-if="goods">
            <FormItem label="商品编号" prop="goods_no">
                <Input v-model="goods.goods_no" placeholder="请输入商品编号" disabled></Input>
            </FormItem>
            <FormItem label="名称" prop="goods_name">
                <Input v-model="goods.goods_name" placeholder="Enter something..." disabled></Input>
            </FormItem>
            <FormItem label="类型" prop="type">
                {{ goods.type_desc }}
            </FormItem>
            <FormItem label="子商品" prop="is_sale">
                <List>
                    <ListItem v-for="(goods, ind) of goods.box_goods" :key="ind" style="border: 1px solid red; margin-top: 6px; padding: 0 10px; border-radius: 10px;">
                        <List>
                            <ListItem>
                                <RadioGroup v-model="goods.son_goods_no">
                                    <Radio v-for="(baseGoods, baseInd) of goodsList" :key="baseInd" :label="baseGoods.goods_no" style="width: 250px;">
                                        {{ baseGoods.goods_name }}-{{baseGoods.goods_no}}
                                    </Radio>
                                </RadioGroup>
                            </ListItem>
                            <ListItem>
                                <div style="width: 100%">
                                    <div style="display: flex;">
                                        <label style="width: 100px; text-align:right; padding-right: 10px;">数量</label>
                                        <Input v-model="goods.num" placeholder="请输入数量" style="width: 400px;"></Input>
                                    </div>
                                    <div style="display: flex; margin-top: 6px; width: 100%">
                                        <label style="width: 100px; text-align:right; padding-right: 10px;">mark</label>
                                        <Input v-model="goods.mark" placeholder="请输入 mark 值" style="width: 400px;">
                                        </Input>
                                    </div>
                                    <button type="button" style="float:right; padding: 0 10px; margin-top: -40px; " @click="delBoxGoods(goods)">删除本行</button>
                                </div>
                            </ListItem>
                        </List>
                    </ListItem>
                </List>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="formHandleSubmit('goods')">提交</Button>
                <Button style="margin-left: 8px">取消</Button>
                <Button style="margin-left: 8px" @click="addBoxGoods(goods)">添加一个子商品</Button>
            </FormItem>
        </Form>
    </div>
</template>
<script>

import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            editGoods: [],
            goodsList: [], // type=0和1 的基础商品，不包含权益包
            goods: {},	// 要修改子商品的权益包
            formData: {},
            goods_types: [],
            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: "The name cannot be empty",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        message: "code必须是数字",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "Please select the city"
                    },
                ],
            },
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    methods: {
        addBoxGoods: function () {
            this.goods.box_goods.push({});
        },
        delBoxGoods (delBoxGoods) {
            console.log('enter delBoxGoods', delBoxGoods);
            let delIndex = -1;
            for (let i = 0; i < this.goods.box_goods.length; i++) {
                console.log(this.goods.box_goods[i]);
                if (this.goods.box_goods[i].son_goods_no == delBoxGoods.son_goods_no) {
                    delIndex = i;
                }
            }
            if (delIndex > -1) {
                this.goods.box_goods.splice(delIndex, 1);
            }
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("goods/detail/" + this.$route.params.id).then((res) => {
                console.log("res", res);
                this.goods = res.data.goods;
                this.goods.goods_id = res.data.goods.id;
                delete this.goods.created_at;
                delete this.goods.updated_at;
                this.loading = false;
                for (let i = this.goods.box_goods.length - 1; i >= 0; i--) {
                    if (this.goods.box_goods[i].is_del == 1) {
                        this.goods.box_goods.splice(i, 1);
                    }
                }
            });
            this.axios.get('goodsTypes/list').then(res => {
                this.goods_types = res.data.goods_types;
            })
            this.axios.get('goods/list?type=0').then(res => {
                this.goodsList = this.goodsList.concat(res.data.goods);
                console.log('this.goodsList=>', this.goodsList);

                this.axios.get('goods/list?type=1').then(res => {
                    this.goodsList = this.goodsList.concat(res.data.goods);
                    console.log('this.goodsList=>', this.goodsList);
                })
            })
            this.axios.get('goods/list?type=3').then(res => {
                this.goodsList = this.goodsList.concat(res.data.goods);
                console.log('this.goodsList 3 实物商品=>', this.goodsList, res);
            })
        },
        formHandleSubmit: function (name) {
            this.goods.box_goods.forEach(good => {
                if (good == undefined) {
                    console.log("good is undefined");
                    this.$Message.error("请输入正确的数据!");
                    return;
                }
                if (good.num == undefined || good.num == 0) {
                    console.log("good.num.length == 0");
                    this.$Message.error("数量不能为空，请检查子商品的数量!");
                    return;
                }
            })
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    // 这里提交
                    console.log('this.goods=>', this.goods);
                    let data = [];
                    this.goods.box_goods.forEach(good => {
                        if (good == undefined) {
                            console.log("good is undefined");
                            this.$Message.error("请输入正确的数据!");
                            return;
                        }
                        if (good.num.length == 0) {
                            console.log("good.num.length == 0");
                            this.$Message.error("数量不能为空，请检查子商品的数量!");
                            return;
                        }
                        let tmp = {
                            num: good.num,
                            mark: good.mark,
                            goods_no: good.son_goods_no
                        }
                        data.push(tmp);
                    })
                    // 				api/goods/boxGoods/21
                    this.axios.post('goods/boxGoods/' + this.goods.id, data).then(res => {
                        console.log("更新权益包产品返回=>", res.data);
                        if (res.data.status === 200) {
                            this.$Message.success('修改成功');
                            setTimeout(() => {
                                this.$router.push("/pages/goods_list");
                            }, 2000);
                        } else {
                            for (let key in res.data.errors) {
                                this.$Message.error(res.data.errors[key][0]);
                            }
                        }
                    })
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
