
<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:26:22
 * @LastEditTime: 2023-01-04 10:53:08
 * @FilePath: \src\pages\douShopUser\index.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>抖店用户管理</BreadcrumbItem>
            <BreadcrumbItem>抖店用户列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="doudian_open_id">
                <Input v-model="selectForm.doudian_open_id" placeholder="OPENID"></Input>
            </FormItem>
            <FormItem prop="date">
                <Select v-model="selectForm.shop_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
                    <Option v-for="shop in shops" :value="shop.id" :key="shop.id">{{ shop.shop_name }}</Option>
                </Select>
            </FormItem>
            <FormItem prop="phone">
                <Input v-model="selectForm.phone" placeholder="手机号码"></Input>
            </FormItem>
            <FormItem prop="aaaa">
                <RadioGroup v-model="selectForm.type">
                    <Radio label="0">
                        <Icon type="logo-apple"></Icon>
                        <span>新用户</span>
                    </Radio>
                    <Radio label="1">
                        <Icon type="logo-android"></Icon>
                        <span>老用户</span>
                    </Radio>
                </RadioGroup>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="goSelect()">查询</Button>
            </FormItem>
        </Form>

        <Table border stripe :columns="columns1" :data="users">
            <template slot-scope="{ row }" slot="shop_name">
                {{ row.shops.shop_name }}
            </template>
            <template slot-scope="{ row }" slot="phones">
                <Button type="primary" size="small" style="margin-right: 5px" @click="showPhones(row)">{{ row.doudian_user_phone_count }}</Button>
            </template>
            <template slot-scope="{ row }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="goCouponList(row)">去订单管理页面</Button>
                <Button type="primary" size="small" style="margin-right: 5px" @click="showOrders(row)">订单统计</Button>
            </template>
        </Table>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
        <Modal v-model="orderModal" title="订单统计">
            <List border size="large">
                <ListItem>关闭订单个数：{{ douDianOrder.cancel_order.cancel_count }}</ListItem>
                <ListItem>关闭订单金额：{{ douDianOrder.cancel_order.cancel_amount }}</ListItem>
                <ListItem>退款中订单个数：{{ douDianOrder.refund_order.refund_count }}</ListItem>
                <ListItem>退款中订单金额：{{ douDianOrder.refund_order.refund_amount }}</ListItem>
                <ListItem>退款成功订单个数：{{ douDianOrder.refund_order_success.refund_success_count }}</ListItem>
                <ListItem>退款成功订单金额：{{ douDianOrder.refund_order_success.refund_success_amount }}</ListItem>
                <ListItem>当天订单个数：{{ douDianOrder.today_order.today_order_count }}</ListItem>
                <ListItem>当天订单金额：{{ douDianOrder.today_order.today_order_amount }}</ListItem>
            </List>
        </Modal>

        <Modal v-model="phoneModal" title="手机号码">
            <List border>
                <ListItem v-for="(phone, ind) of phones" :key="ind">{{phone}}</ListItem>
            </List>
        </Modal>
    </div>
</template>
<script>
import loading from '../loading.vue';
import exportExcel from "@/libs/newToExcel.js";
export default {
    components: { loading },
    data () {
        return {
            supplierGoods: [],
            orderModal: false,
            phoneModal: false,
            loading: false,
            error: null,
            users: [],
            pages: {
                total: 0
            },
            shops: [],
            selectForm: {
                start_date: '',
                end_date: '',
                code: '',
                batch_no: '',
                price: '',
                shop_id: ''
            },
            douDianOrder: {
                cancel_order: {
                    cancel_count: 0,
                    cancel_amount: 0,
                },
                refund_order: {

                },
                refund_order_success: {},
                today_order: {}
            },
            phones: [],
            columns1: [
                { align: "center", title: 'OPENID', key: 'doudian_open_id', width: 400 },
                { align: "center", title: '抖店', slot: 'shop_name' },
                { align: "center", title: '下单总金额', key: 'coupon_orders_sum_sh_order_amount' },
                { align: "center", title: '下单数', key: 'coupon_orders_count' },
                { align: "center", title: '首次下单时间', key: 'coupon_orders_min_created_at' },
                { align: "center", title: '最近下单时间', key: 'coupon_orders_max_created_at' },
                { align: "center", title: '手机号码', slot: 'phones' },
                { align: "center", title: '用户类型', key: 'type_desc' },
                { title: "操作", slot: "action", align: "center", width: 400 },
            ],
            copyValue: ''
        }
    },
    created () {
        console.log(this.$route.params)
        this.fetchData();
        this.axios.get('shops/list').then(res => {
            this.shops = res.data.shops
        })
    },
    methods: {
        getNow: function () {
            Date.prototype.format = function (fmt) {
                var o = {
                    "M+": this.getMonth() + 1, //月份
                    "d+": this.getDate(), //日
                    "h+": this.getHours(), //小时
                    "m+": this.getMinutes(), //分
                    "s+": this.getSeconds(), //秒
                    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                    "S": this.getMilliseconds() //毫秒
                };
                if (/(y+)/.test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
                }
                for (var k in o) {
                    if (new RegExp("(" + k + ")").test(fmt)) {
                        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                    }
                }
                return fmt;
            }
        },
        goExportII: function () {
            let getData = {};
            for (let key in this.selectForm) {
                console.log('param[key],', this.selectForm[key]);
                if (this.selectForm[key] === undefined || this.selectForm[key].length == 0) {
                    delete this.selectForm[key];
                }
            }
            let date = new Date();
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset()); // toJSON 的时区补偿
            getData.params = this.selectForm;
            getData.responseType = 'blob';
            this.axios.get('couponsOrders/exportCardOrders', getData).then(res => {
                console.log('couponsOrders/exportCardOrders=>', res);
                //let blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
                let blob = new Blob([res.data], { type: 'application/octet-stream' }); // 为blob设置文件类型，这里以.xlsx为例
                let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
                let a = document.createElement("a");
                a.href = url;
                a.download = '券码下载' + date.toJSON().substr(0, 19).replace(/[-T]/g, '') + '.csv';
                a.click();
                // 释放这个临时的对象url
                window.URL.revokeObjectURL(url);
            });
        },
        /**
         * 导出
         */
        async goExport () {
            let [th, fileKey, data, fileName] = [[], [], [], '券码下载' + this.getNow()];
            //   let fileName = "";

            let getData = {};
            for (let key in this.selectForm) {
                console.log('param[key],', this.selectForm[key]);
                if (this.selectForm[key] === undefined || this.selectForm[key].length == 0) {
                    delete this.selectForm[key];
                }
            }
            getData.params = this.selectForm;
            this.axios.get('coupons/exportBatch', getData).then(res => {
                console.log('coupons/exportBatch res', res);
                exportExcel(th, fileKey, fileName, data);
            })
        },
        goSelect: function () {
            this.fetchData('doudianUser/list', this.selectForm);
        },
        /**
         * 显示删除 Modal
         * @param {*} data
         */
        showDeleteModal (data) {
            console.log('将要删除的对象=>', data);
            this.deleteModal = true;
            this.deleteObj = data;
        },
        /**
         * 删除操作
         */
        handleDelete () {
            this.loading = true;
            this.axios.get('coupons/del/' + this.deleteObj.id).then((res) => {
                console.log("删除返回res", res);
                this.loading = false;
                if (res.data.status === 204) {
                    this.$Message.success("删除成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },

        showPhones (row) {
            console.log("row=>", row);
            this.loading = true;
            this.axios.get('doudianUser/doudianUserPhone/' + row.id).then(res => {
                this.phones = res.data.doudian_phone;
                this.phoneModal = true;
                this.loading = false;
            });
        },
        /**
         * 显示订单统计
         * @param {*} row
         */
        showOrders (row) {
            console.log("row=>", row.id);
            this.loading = true;
            this.axios.get('doudianUser/doudianUserOrders/' + row.id).then(res => {
                console.log('doudianUserOrders res=>', res);
                this.loading = false;
                this.orderModal = true;
                this.douDianOrder = res.data.doudian_order;
            });
        },
        goCouponList (row) {
            console.log("row=>", row);
            this.$router.push("/pages/coupon_orders?doudian_open_id=" + encodeURIComponent(row.doudian_open_id));
        },
        pageOnChange: function (page) {
            console.log('page=>', page);
            let isFind = false;
            this.pages.links.forEach(link => {
                if (link.label == page) {
                    isFind = true;
                    console.log("找到了对应的链接, label=", link.label, ", page=", page);
                    return this.fetchData(link.url);
                }
            })
            if (isFind == false) {
                this.selectForm.page = page;
                this.fetchData(this.pages.path, this.selectForm);
            }
        },
        dateOnChange (date) {
            console.log('date', date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
            this.fetchData('doudianUser/list', this.selectForm);
        },
        fetchData (url = 'doudianUser/list', params = {}) {
            let data = {};
            for (let key in params) {
                console.log('param[key],', params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }
            data.params = params;
            this.axios.get(url, data).then(res => {
                console.log('coupons/list res', res);
                res.data.doudian.forEach(element => {
                    element.phones = []
                })

                this.users = res.data.doudian;
                this.pages = res.data.pages;
                console.log('this.pages=>', this.pages);
            })
        }
    }
}
</script>
