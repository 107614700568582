<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-06-22 14:06:04
 * @FilePath: \qyOut\src\pages\goods\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="goods" :model="goods" :rules="ruleValidate" label-position="right" :label-width="200" v-if="goods">
        <FormItem label="商品分类" prop="goods_type_id">
            <Select v-model="goods.goods_type_id">
                <Option v-for="item in goods_types" :value="item.code" :key="item.code">{{ item.name }}-{{ item.code }}</Option>
            </Select>
        </FormItem>
        <FormItem label="商品编号" prop="goods_no">
            <Input v-model="goods.goods_no" placeholder="请输入商品编号"></Input>
        </FormItem>
        <FormItem label="名称" prop="goods_name">
            <Input v-model="goods.goods_name" placeholder="Enter something..."></Input>
        </FormItem>
        <FormItem label="原价" prop="original_price">
            <Input v-model="goods.original_price" placeholder="Enter something..."></Input>
        </FormItem>
        <FormItem label="详情" prop="detail">
            <Input v-model="goods.detail" type="textarea" placeholder="请输入产品详情" :autosize="{minRows: 3,maxRows: 6}"></Input>
        </FormItem>
        <FormItem label="图片" prop="picture_url">
            <Input v-model="goods.picture_url" placeholder="请输入产品图片网址" :autosize="{minRows: 3,maxRows: 6}"></Input>
        </FormItem>
        <FormItem label="兑换流程" prop="flow_path">
            <Input v-model="goods.flow_path" type="textarea" placeholder="请输入产品兑换流程"></Input>
        </FormItem>
        <FormItem label="类型" prop="type">
            <RadioGroup v-model="goods.type">
                <Radio :label="0">直充</Radio>
                <Radio :label="1">卡券</Radio>
                <Radio :label="2">权益包</Radio>
                <Radio :label="3">实物</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem label="上架" prop="is_sale">
            <RadioGroup v-model="goods.is_sale">
                <Radio :label="0">下架</Radio>
                <Radio :label="1">上架</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem label="状态" prop="status_id">
            <RadioGroup v-model="goods.status_id">
                <Radio :label="0">关闭</Radio>
                <Radio :label="1">正常</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem label="发送代理商 " prop="is_send">
            <RadioGroup v-model="goods.is_send">
                <Radio :label="0">不发送</Radio>
                <Radio :label="1">发送</Radio>
            </RadioGroup>
        </FormItem>
		<FormItem label="区域" prop="area_type">
			<RadioGroup v-model="goods.area_type">
				<Radio :label="0">全国</Radio>
				<Radio :label="1">省</Radio>
			</RadioGroup>
		</FormItem>
		<FormItem label="区域名称" prop="area_name">
			<Input v-model="goods.area_name" placeholder="请输入区域名称"></Input>
		</FormItem>
		<FormItem label="库存预警" prop="is_qty_warn">
			<RadioGroup v-model="goods.is_qty_warn">
				<Radio :label="0">不预警</Radio>
				<Radio :label="1">预警</Radio>
			</RadioGroup>
		</FormItem>
		<FormItem label="库存预警" prop="warn_num">
			<Input v-model="goods.warn_num" placeholder="请输入库存预警数"></Input>
		</FormItem>
		<FormItem label="使用第三方api" prop="is_api">
			<RadioGroup v-model="goods.is_api">
				<Radio :label="0">否</Radio>
				<Radio :label="1">是</Radio>
			</RadioGroup>
		</FormItem>
        <FormItem label="有效期天数" prop="expired_days">
            <Input v-model="goods.expired_days" placeholder="请输入有效期天数"></Input>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="formHandleSubmit('goods')">提交</Button>
            <Button style="margin-left: 8px">取消</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        goods: {
            type: Object,
            default: function () {
                return { is_send: 0 }
            }
        },
        goods_types: Array,
    },
    data () {
        return {
            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: "The name cannot be empty",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        message: "code必须是数字",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "Please select the city"
                    },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.goods);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
