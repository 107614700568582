<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-04-08 11:08:33
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>用户管理</BreadcrumbItem>
            <BreadcrumbItem>添加用户</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :user="user" :companies="companies" :isSuperAdmin="isSuperAdmin" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import { mapState } from "vuex";
import formPage from './form.vue';
import loading from '../loading.vue';
import axios from 'axios';
export default {
    components: { formPage, loading },
    data () {
        return {
            loading: false,
            error: '',
            companies: [],
            isSuperAdmin: false,
            user: {}
        }
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo", 'balance', 'companyInfo'
    ]),
    created () {
        axios.get("companies").then(res => {
            this.companies = res.data.companies
        })
        console.log("roles=>", this.userInfo.roles);
        this.userInfo.roles.forEach(role => {
            if (role.name === '超级管理员') {
                this.isSuperAdmin = true;
                console.log("this.isSuperAdmin=>", this.isSuperAdmin);
            }
        })
    },
    methods: {
        handleSubmit (goods) {
            // 验证通过
            console.log("handleSubmit 开始");
            this.loading = true; this.error = null;
            this.axios.post("users", goods).then((res) => {
                console.log("修改返回res", res)
                this.loading = false;
                if (res.data.status === 201) {
                    this.$Message.success('添加成功');
                    setTimeout(() => {
                        this.$router.push("/pages/users");
                    }, 2000);
                } else {
                    console.log(res.data.errors);
                    for (let key in res.data.errors) {
                        console.log(res.data.errors[key]);
                        res.data.errors[key].forEach(err => {
                            console.log(err);
                            this.$Message.error(err);
                        })
                    }
                }
            }).catch(error => {
                console.log(error.$Message);
                this.error = error.message;
            })
        }
    },
};
</script>
