<!--
 * @Author: 陈远锋
 * @Date: 2022-03-11 16:31:36
 * @LastEditTime: 2022-04-08 13:12:31
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\coupon_expand.vue
 * @Description: Do not edit
-->
<template>
    <div class="expand-row">
        <Table border stripe :columns="columns2" :data="row">
        </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
            columns2: [
                { align: "center", title: '公司名称', key: 'company_name' },
                { align: "center", title: '已核销', key: 'agent_verify_num' },
                { align: "center", title: '已取消', key: "agent_cancel_num" },
                { align: "center", title: '已发放', key: 'agent_send_num' },
                { align: "center", title: '未核销', key: "agent_unverify_num" },
                { align: "center", title: '未发放', key: 'unsend_num' },
            ]
        }
    },
	methods: {
	},
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
}
</style>
