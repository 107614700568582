<!--
 * @Author: 陈远锋
 * @Date: 2022-04-21 14:47:35
 * @LastEditTime: 2022-06-27 14:11:57
 * @FilePath: \qyOut\src\pages\goods\box_goods_edit.vue
 * @Description: Do not edit
-->
<template>
	<div>
		<Breadcrumb :style="{ margin: '20px 10px' }">
			<BreadcrumbItem>基础商品</BreadcrumbItem>
			<BreadcrumbItem>商品归属地</BreadcrumbItem>
		</Breadcrumb>
		<loading :loading="loading" :error="error"></loading>
		<Form ref="goods" :model="goods" :rules="ruleValidate" label-position="right" :label-width="200" v-if="goods">
			<FormItem label="商品编号" prop="goods_no">
				<Input v-model="goods.goods_no" placeholder="请输入商品编号" disabled></Input>
			</FormItem>
			<FormItem label="名称" prop="goods_name">
				<Input v-model="goods.goods_name" placeholder="Enter something..." disabled></Input>
			</FormItem>
			<FormItem label="商品归属地" prop="is_sale">
				<List>
					<ListItem v-for="(goods, ind) of goods_area" :key="ind" style="border: 1px solid red; margin-top: 6px; padding: 0 10px; border-radius: 10px;">
						<List>
							<ListItem>
									<div style="display: flex;">
										<label style="width: 100px; text-align:right; padding-right: 10px;">名称</label>
										<Select v-model="goods.province_goods_id" style="width: 250px; margin-right: 10px">
											<Option v-for="good in goodsList" :value="good.id" :key="good.id">{{ good.goods_name }}-{{good.goods_no}}</Option>
										</Select>
									</div>
									<div style="display: flex; margin-top: 6px; width: 100%">
										<label style="width: 100px; text-align:right; padding-right: 10px;">省份</label>
										<Input v-model="goods.province" placeholder="请输入省份" style="width: 200px;">
										</Input>
									</div>
									<div style="display: flex; margin-top: 6px; width: 100%">
										<label style="width: 100px; text-align:right; padding-right: 10px;">分配</label>
										<RadioGroup v-model="goods.is_area">
											<Radio :label="0">否</Radio>
											<Radio :label="1">是</Radio>
										</RadioGroup>
									</div>
									<button type="button" style="float:right; padding: 0 10px; width: 150px; " @click="saveGoodsArea(goods)">保存</button>
<!--									<button type="button" style="float:right; padding: 0 10px; width: 150px; " @click="delBoxGoods(goods)">删除</button>-->
							</ListItem>
						</List>
					</ListItem>
				</List>
			</FormItem>
			<FormItem>
<!--				<Button type="primary" @click="formHandleSubmit('goods')">提交</Button>-->
<!--				<Button style="margin-left: 8px">取消</Button>-->
				<Button style="margin-left: 8px" @click="addGoodsArea(goods)">添加分配</Button>
			</FormItem>
		</Form>
	</div>
</template>
<script>

import loading from '../loading.vue';
export default {
	components: { loading },
	data () {
		return {
			goodsList: [], // type=0和1 的基础商品，不包含权益包
			goods: {},	// 要修改子商品的权益包
			formData: {},
			goods_area: [],
			ruleValidate: {
				name: [
					{
						required: true,
						message: "The name cannot be empty",
						trigger: "blur",
					},
				],
				code: [
					{
						required: true,
						message: "code不能为空",
						trigger: "blur",
					},
					{
						type: "string",
						message: "code必须是数字",
						trigger: "blur",
					},
				],
				type: [
					{
						required: true,
						message: "Please select the city"
					},
				],
			},
		};
	},
	created () {
		console.log("mounted");
		this.fetchData();
	},
	methods: {
		addGoodsArea: function () {
			this.goods_area.push({'is_area':1});
		},
		delBoxGoods (delBoxGoods) {
			console.log('enter delBoxGoods', delBoxGoods);
			let delIndex = -1;
			for (let i = 0; i < this.goods_area.length; i++) {
				console.log(this.goods_area[i]);
				if (this.goods_area[i].province_goods_id == delBoxGoods.province_goods_id) {
					delIndex = i;
				}
			}
			if (delIndex > -1) {
				this.goods_area.splice(delIndex, 1);
			}
		},
		saveGoodsArea( good) {
			if (good === undefined) {
				console.log("good is undefined");
				this.$Message.error("请输入正确的数据!");
				return;
			}
			if (good.province_goods_id === undefined || good.province_goods_id === 0) {
				console.log("good.country_goods_id.length == 0");
				this.$Message.error("请选择商品!");
				return;
			}
			if (good.province === undefined) {
				console.log("good.country_goods_id.length == 0");
				this.$Message.error("请输入省份!");
				return;
			}
			let data = {
				country_goods_id: this.goods.id,
				province_goods_id: good.province_goods_id,
				province: good.province,
				is_area: good.is_area,
			}
			if (good.id === undefined) {
				//添加
				this.axios.post('goods/addGoodsArea', data).then(res => {
					console.log("新建归属地商品返回=>", res.data);
					if (res.data.status === 200) {
						this.$Message.success('保存成功');
						setTimeout(() => {
							this.$router.push("/pages/goods_area/"+ this.goods.id);
						}, 2000);
					} else {
						for (let key in res.data.errors) {
							this.$Message.error(res.data.errors[key][0]);
						}
					}
				})
			} else {
				this.axios.post('goods/editGoodsArea/' + good.id, data).then(res => {
					console.log("编辑归属地商品返回=>", res.data);
					if (res.data.status === 200) {
						this.$Message.success('保存成功');
						setTimeout(() => {
							this.$router.push("/pages/goods_area/"+ this.goods.id);
						}, 2000);
					} else {
						for (let key in res.data.errors) {
							this.$Message.error(res.data.errors[key][0]);
						}
					}
				})
			}
		},
		fetchData () {
			this.error = this.post = null;
			this.loading = true;
			this.axios.get("goods/detail/" + this.$route.params.id).then((res) => {
				console.log("res", res);
				this.goods = res.data.goods;
				this.goods.goods_id = res.data.goods.id;
				delete this.goods.created_at;
				delete this.goods.updated_at;
				this.loading = false;
			});
			this.axios.get('goods/goodsArea/'+ this.$route.params.id).then(res => {
				console.log(this.goods_area);
				this.goods_area = res.data.goods_area;
				for (let i = this.goods_area.length - 1; i >= 0; i--) {
					this.goods_area[i].is_add = 0;
				}
			})
			this.axios.get('goods/goodsList?type=1').then(res => {
				this.goodsList = this.goodsList.concat(res.data.goods);
				console.log('this.goodsList=>', this.goodsList);
			})
		},
		formHandleSubmit: function (name) {
			this.goods_area.forEach(good => {
				if (good == undefined) {
					console.log("good is undefined");
					this.$Message.error("请输入正确的数据!");
					return;
				}
				if (good.country_goods_id == undefined || good.country_goods_id == 0) {
					console.log("good.country_goods_id.length == 0");
					this.$Message.error("请选择商品!");
					return;
				}
			})
			this.$refs[name].validate((valid) => {
				if (valid) {
					// 验证通过
					console.log("验证通过");
					this.loading = true;
					this.error = null;
					// 这里提交
					console.log('this.goods=>', this.goods);
					let data = [];
					this.goods_area.forEach(good => {
						if (good == undefined) {
							console.log("good is undefined");
							this.$Message.error("请输入正确的数据!");
							return;
						}
						if (good.country_goods_id == undefined || good.country_goods_id == 0) {
							console.log("good.country_goods_id.length == 0");
							this.$Message.error("请选择商品!");
							return;
						}
						if (good.province == undefined) {
							console.log("good.country_goods_id.length == 0");
							this.$Message.error("请输入省份!");
							return;
						}
						let tmp = {
							country_goods_id: this.goods.country_goods_id,
							province_goods_id: good.province_goods_id,
							province: good.province,
							is_area: good.is_area,
						}
						data.push(tmp);
					})
					// 				api/goods/boxGoods/21
					this.axios.post('goods/boxGoods/' + this.goods.id, data).then(res => {
						console.log("更新权益包产品返回=>", res.data);
						if (res.data.status === 200) {
							this.$Message.success('修改成功');
							setTimeout(() => {
								this.$router.push("/pages/goods_list");
							}, 2000);
						} else {
							for (let key in res.data.errors) {
								this.$Message.error(res.data.errors[key][0]);
							}
						}
					})
				} else {
					this.$Message.error("请输入正确的数据!");
				}
			});
		},
	},
};
</script>
