<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-03-14 10:10:00
 * @FilePath: \qyOut\src\pages\supplierGoods\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="supplierGoods" :model="supplierGoods" :rules="ruleValidate" label-position="right" :label-width="200" v-if="supplierGoods">
        <FormItem label="名字">
            <Input v-model="supplierGoods.supplier_goods_name" placeholder="请输入商品编号" disabled></Input>
        </FormItem>
        <FormItem label="编号" prop="goods_no">
            <Input v-model="supplierGoods.goods_no" placeholder="请输入商品编号"></Input>
        </FormItem>
        <FormItem label="原价" prop="original_price">
            <Input v-model="supplierGoods.original_price" placeholder="请输入商品原价"></Input>
        </FormItem>
        <FormItem label="状态ID" prop="status_id">
            <RadioGroup v-model="supplierGoods.status_id">
                <Radio label="0">下架</Radio>
                <Radio label="1">上架</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem label="等级" prop="level">
            <Input v-model="supplierGoods.level" placeholder="请输入等级"></Input>
        </FormItem>

        <FormItem>
            <Button type="primary" @click="formHandleSubmit('supplierGoods')">提交</Button>
            <Button style="margin-left: 8px">取消</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        supplierGoods: Object,
    },
    data() {
        return {
            ruleValidate: {
                goods_no: [
                    {
                        required: true,
                        message: "name不能为空",
                        trigger: "blur",
                    },
                ],
                status_id: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                ],
                level: [
                    {
                        required: true,
                        message: "supplier不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            console.log("name=>", name);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    console.log("this.supplierGoods=>", this.supplierGoods);
                    this.$emit("handleSubmit", this.supplierGoods);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
