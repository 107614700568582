<!--
 * @Author: 陈远锋
 * @Date: 2022-03-08 17:23:25
 * @LastEditTime: 2022-03-08 17:27:10
 * @FilePath: \qyOut\src\pages\goods\supplier_goods_expand.vue
 * @Description: Do not edit
-->
<template>
    <div class="expand-row">
        <Table border stripe :columns="columns2" :data="row"> </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
            tableData: [],
            columns2: [
                {
                    align: "center",
                    title: '供货商ID',
                    key: 'supplier_id'
                },
                {
                    align: "center",
                    title: '产品名字',
                    key: 'supplier_goods_name'
                }, {
                    align: "center",
                    title: '产品ID',
                    key: 'supplier_goods_no'
                }, {
                    align: "center",
                    title: '上/下架',
                    key: 'status_desc'
                }, {
                    align: "center",
                    title: '原价',
                    key: 'original_price'
                }, {
                    align: "center",
                    title: '成本价',
                    key: 'channel_price'
                }, {
                    align: "center",
                    title: '排序',
                    key: 'level'
                }
            ]
        }
    }
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
}
</style>
