/*
 * @Author: 陈远锋
 * @Date: 2022-10-21 11:17:33
 * @LastEditTime: 2022-10-21 11:22:38
 * @FilePath: \qyOut\src\libs\newToExcel.js
 * @Description: Do not edit
 */
import { export_json_to_excel } from './Export2Excel';

/**
* @method exportExcel
* @param {Array} header   表头
* @param {Array} filterVal 表头属性字段
* @param {String} filename 文件名称
* @param {Array} tableData 列表数据
**/
export default function exportExcel (header, filterVal, filename, tableData) {
	var data = formatJson(filterVal, tableData);
	//console.log('exportExcel, data=>', data);
	export_json_to_excel(
		header,
		data,
		filename
	)
}

function formatJson (filterVal, tableData) {
	//console.log("tableData length=>", tableData.length);
	//console.log("filterVal length=>", filterVal.length);
	return tableData.map(v => {
		//console.log("formatJson v=>", v);
		return filterVal.map(j => {
			//console.log("formatJson j=>", j);
			return v[j]
		})
	})
}
