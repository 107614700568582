<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-09 10:19:29
 * @FilePath: \qyOut\src\pages\supplier\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>供应商管理</BreadcrumbItem>
            <BreadcrumbItem>新建供应商</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <div v-if="error" class="error">
            {{ error }}
        </div>

        <formPage :supplier="supplier" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            supplier: {},
            loading: false,
            error: null,
        }
    },
    created () {
        console.log("created")
        this.fetchData()
        console.log(this.$route.params)
    },
    methods: {
        handleSubmit (supplier) {
            // 验证通过
            console.log("handleSubmit 开始");
            this.loading = true; this.error = null;
            this.axios
                .post("supplier/create", supplier)
                .then((res) => {
                    console.log("新建返回res", res)
                    this.loading = false;
                    if (res.data.status === 200) {
                        this.$Message.success('添加成功');
                        setTimeout(() => {
                            this.$router.push("/pages/supplier_list");
                        }, 2000);
                    }
                })
        },
        fetchData () {
            this.axios.get('goodsTypes/list').then(res => {
                this.goods_types = res.data.goods_types;
            })
        },
    },
};
</script>
