<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-04-08 13:53:11
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\permissions\index.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <!-- <router-link to="/pages/goods_create" style="float:right;">
                新增权限
            </router-link> -->
            <BreadcrumbItem>权限管理</BreadcrumbItem>
            <BreadcrumbItem>权限列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <div v-if="permissions" class="content">
            <Table border stripe :columns="columns1" :data="permissions">
                <template slot-scope="{ row, index }" slot="name">
                    <Input v-if="editIndex === index" type="text" v-model="editName" />
                    <span v-else>{{ row.name }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="url">
                    <Input v-if="editIndex === index" type="text" v-model="editUrl" />
                    <span v-else>{{ row.url }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="route">
                    <Input v-if="editIndex === index" type="text" v-model="editRoute" />
                    <span v-else>{{ row.route }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="isHide">
                    <Input v-if="editIndex === index" type="text" v-model="editIsHide" />
                    <span v-else>{{ row.is_hide }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="pid">
                    <Input v-if="editIndex === index" type="text" v-model="editPid" />
                    <span v-else>{{ row.pid }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="level">
                    <Input v-if="editIndex === index" type="text" v-model="editLevel" />
                    <span v-else>{{ row.level }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <div v-if="editIndex === index">
                        <Button @click="handleSave(row, index)" type="primary" style="margin-right: 6px">保存</Button>
                        <Button @click="editIndex = -1">取消</Button>
                    </div>
                    <div v-else>
                        <Button type="primary" size="small" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                    </div>
                </template>
            </Table>
        </div>
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            permissions: [],
            error: null,
            columns1: [
                /* { title: "时间", key: "updated_at", align: "center", width: 250 }, */
                { align: "center", title: "守卫名字", key: "guard_name", width: 120 },
                { align: "center", title: "名字", width: 200, slot: 'name' },
                { align: "center", title: "前端网址", key: "url", width: 300, slot: 'url' },
                { align: "center", title: "路由别名", key: "route", width: 300, slot: 'route' },
                { align: "center", title: "隐藏", key: "is_hide", slot: 'is_hide' },
                { align: "center", title: "父ID", key: "pid", slot: 'pid' },
                { align: "center", title: "级别", key: "level", width: 100, slot: 'level' },
                { title: "操作", slot: "action", width: 150, align: "center", },
            ],
            editIndex: -1,
            editName: "",
            editUrl: "",
            editRoute: "",
            editIsHide: "",
            editPid: "",
            editLevel: "",
            editId: -1,
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        handleEdit (row, index) {
            this.editId = row.id;
            this.editIndex = index;
            this.editName = row.name;
            this.editUrl = row.url;
            this.editRoute = row.route;
            this.editIsHide = row.is_hide;
            this.editPid = row.pid;
            this.editLevel = row.level;
        },
        handleSave (row, index) {
            console.log("enter row", row);
            console.log("enter index", index);
            this.editIndex = -1;
            let data = {
                name: this.editName,
                route: this.editRoute,
                url: this.editUrl,
                is_hide: this.editIsHide,
                pid: this.editPid,
                level: this.editLevel,
            };

            this.loading = true;
            this.axios.patch('permissions/' + this.editId, data).then((res) => {
                console.log("修改返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    row.name = this.editName;
                    row.route = this.editRoute;
                    row.is_hide = this.editIsHide;
                    row.level = this.editLevel;
                    row.pid = this.editPid;
                    row.url = this.editUrl;
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("permissions").then((res) => {
                console.log("res", res);
                this.permissions = res.data.permissions;
                this.loading = false;
            });
        },
    },
};
</script>
