<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-04-02 14:14:19
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>用户管理</BreadcrumbItem>
            <BreadcrumbItem>编辑用户资料</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :user="user" :companies="companies" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
import axios from 'axios';
export default {
    components: { formPage, loading },
    data () {
        return {
            user: {},
            loading: false,
            error: null,
            companies: [],
        }
    },
    created () {
        console.log("created")
        this.fetchData()
        console.log(this.$route.params);
        axios.get("companies").then(res => {
            this.companies = res.data.companies
        })
    },
    methods: {
        handleSubmit (user) {
            // 验证通过
            console.log("验证通过", user);
            this.loading = true; this.error = null
            this.axios.patch("users/" + user.id, user).then((res) => {
                console.log("修改返回res", res)
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success('修改成功');
                    setTimeout(() => {
                        this.$router.push("/pages/users");
                    }, 2000);
                } else {
                    for (let key in res.data.errors) {
                        this.$Message.error(res.data.errors[key][0]);
                    }
                }
            })
        },
        show (row, index) {
            console.log("row=>", row.id)
            console.log("index=>", index)
            this.$router.push("/pages/goods_type_edit/" + row.id)
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("users/" + this.$route.params.id).then((res) => {
                console.log("res", res);
                this.user = res.data.user;
                this.loading = false;
            });
        },
    },
};
</script>
