/*
 * @Author: 陈远锋
 * @Date: 2022-03-04 10:20:34
 * @LastEditTime: 2022-04-01 14:36:56
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\store\getters.js
 * @Description: Do not edit
 */
export default {
	token: state => state.app.token,
	isLogin: state => !!state.app.token,
	backgroundColor: state => state.app.backgroundColor,
	userInfo: state => state.app.userInfo || {},
	uid: state => state.app.uid,
	homeActive: state => state.app.homeActive,
	home: state => state.app.home,
	cartNum: state => state.indexData.cartNum,
	balance: state => state.balance,
};
