<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-03-15 11:12:07
 * @FilePath: \qyOut\src\pages\goods\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="goods" :model="goods" :rules="ruleValidate" label-position="right" :label-width="200" v-if="goods">
        <FormItem label="商品分类" prop="goods_type_id">
            <Select v-model="goods.goods_type_id">
                <Option v-for="item in goods_types" :value="item.code" :key="item.code">{{ item.name }}-{{ item.code }}</Option>
            </Select>
        </FormItem>
        <FormItem label="商品编号" prop="goods_no">
            <Input v-model="goods.goods_no" placeholder="请输入商品编号"></Input>
        </FormItem>
        <FormItem label="名称" prop="goods_name">
            <Input v-model="goods.goods_name" placeholder="Enter something..."></Input>
        </FormItem>
        <FormItem label="原价" prop="original_price">
            <Input v-model="goods.original_price" placeholder="Enter something..."></Input>
        </FormItem>
        <FormItem label="类型" prop="type">
            <RadioGroup v-model="goods.type">
                <Radio label="0">直充</Radio>
                <Radio label="1">卡券</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem label="上架" prop="is_sale">
            <RadioGroup v-model="goods.is_sale">
                <Radio label="0">下架</Radio>
                <Radio label="1">上架</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem label="状态" prop="status_id">
            <RadioGroup v-model="goods.status_id">
                <Radio label="0">关闭</Radio>
                <Radio label="1">正常</Radio>
            </RadioGroup>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="formHandleSubmit('goods')">提交</Button>
            <Button style="margin-left: 8px">取消</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        goods: Object,
        goods_types: Array,
    },
    data() {
        return {
            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: "The name cannot be empty",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        message: "code必须是数字",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "Please select the city",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.$emit("handleSubmit", this.goods);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
