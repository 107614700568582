<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-05-07 13:29:22
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\supplierGoods\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>供应商商品管理</BreadcrumbItem>
            <BreadcrumbItem>编辑供应商商品</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <p>编辑供应商商品</p>
        <div v-if="loading" class="loading">Loading...</div>
        <div v-if="error" class="error">
            {{ error }}
        </div>
        <formPage :supplierGoods="supplierGoods" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from "./form.vue";
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            supplierGoods: {},
            loading: false,
            error: null,
        };
    },
    created () {
        console.log("created");
        this.fetchData();
        console.log(this.$route.params);
    },
    watch: {},
    methods: {
        handleSubmit (_goods) {
            console.log("here", _goods);
            this.axios
                .post("supplierGoods/update/" + _goods.id, _goods)
                .then((res) => {
                    console.log("here", res);
                    if (res.data.status == 200) {
                        this.$router.push("/pages/supplier_goods_list");
                    } else {
                        this.$Message.error("出错了");
                    }
                });
        },
        fetchData: function () {
            this.axios
                .get("supplierGoods/detail/" + this.$route.params.id)
                .then((res) => {
                    console.log("res=>", res.data);
                    this.supplierGoods = res.data.supplier_goods;
                    this.supplierGoods.status_id = String(
                        this.supplierGoods.status_id
                    );
                    this.supplierGoods.level = String(this.supplierGoods.level);
                });
        },
    },
};
</script>
