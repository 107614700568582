<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-09 10:08:17
 * @FilePath: \qyOut\src\pages\goods\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>基础商品</BreadcrumbItem>
            <BreadcrumbItem>添加基础商品</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :goods="goods" :goods_types="goods_types" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            goods: {},
            goods_types: [],
            loading: false,
            error: '',
        }
    },
    created () {
        console.log("created")
        this.fetchData()
        console.log(this.$route.params)
    },
    methods: {
        handleSubmit (goods) {
            // 验证通过
            console.log("handleSubmit 开始");
            this.loading = true; this.error = null;
            this.axios
                .post("goods/create", goods)
                .then((res) => {
                    console.log("修改返回res", res)
                    this.loading = false;
                    if (res.data.status === 200) {
                        this.$Message.success('添加成功');
                        setTimeout(() => {
                            this.$router.push("/pages/goods_list");
                        }, 2000);
                    }
                })
        },
        fetchData () {
            this.axios.get('goodsTypes/list').then(res => {
                this.goods_types = res.data.goods_types;
            })
        },
    },
};
</script>
