<template>
  <div>
    <loading :loading="loading" :error="error"></loading>
  </div>
</template>

<script>
import loading from "../loading.vue";
export default {
  name: "index",
  components: { loading },
  data () {
    return {
      loading: false,
      error: null,
    }
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchData",
  },
}
</script>

<style scoped>

</style>
