var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{staticClass:"layout-nav3",staticStyle:{"margin-left":"12px"},attrs:{"separator":"<b>></b>"}},[_c('BreadcrumbItem',{attrs:{"to":"/components/breadcrumb"}},[_vm._v("分销商/订单管理")]),_c('BreadcrumbItem',[_vm._v("订单列表")])],1),_c('Content',{style:({ minHeight: '200px', background: '#fff' })},[_c('div',{style:({
        background: '#f5f7f9 ',
        margin: '12px',
        padding: '14px',
      })},[_c('Row',{attrs:{"gutter":16}},[_c('Col',{attrs:{"span":"6"}},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"daterange","placement":"bottom-start","placeholder":"开始日期至结束日期"}})],1),_c('Col',{attrs:{"span":"6"}},[_c('div',[_c('Select',{staticStyle:{"width":"100%"},model:{value:(_vm.model1),callback:function ($$v) {_vm.model1=$$v},expression:"model1"}},_vm._l((_vm.cityList),function(item){return _c('Option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)]),_c('Col',{attrs:{"span":"6"}},[_c('div',[_c('Select',{staticStyle:{"width":"100%"},model:{value:(_vm.model1),callback:function ($$v) {_vm.model1=$$v},expression:"model1"}},_vm._l((_vm.cityList),function(item){return _c('Option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)]),_c('Col',{attrs:{"span":"6"}},[_c('Input',{attrs:{"placeholder":"商户订单号"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('Col',{style:({ margin: '10px 0 0 0' }),attrs:{"span":"6"}},[_c('Input',{attrs:{"placeholder":"充值账号"},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}})],1)],1),_c('Row',[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('Button',{staticStyle:{"float":"left"},attrs:{"type":"primary"}},[_c('Icon',{attrs:{"type":"md-download"}}),_vm._v(" 导出excel ")],1),_c('Button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"}},[_c('Icon',{attrs:{"type":"ios-search"}}),_vm._v(" 查询 ")],1)],1)])],1),_c('div',{staticStyle:{"margin":"0 12px"}},[_c('Table',{attrs:{"border":"","columns":_vm.columns1,"data":_vm.data1}})],1),_c('div',{style:({
        background: '#f5f7f9 ',
        margin: '12px',
        padding: '14px',
        height: '50px',
      })},[_c('Page',{staticStyle:{"float":"right"},attrs:{"total":100,"size":"small","show-total":"","show-sizer":""}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }